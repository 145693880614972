import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import AuthHeader from "components/AuthHeader";

export default function ForgetPassword() {
  const navigate = useNavigate();

  return (
    <>
      <AuthHeader mode={1} />
      <div className="w-full flex justify-center">
        <div className="text-center w-[360px] lg:w-[430px]">
          <div className="text-2xl text-white font-bold pt-11">
            I‘ve forgotten my password
          </div>
          <div className="text-sm text-[#979797] pt-3.5">
            If you‘re logged out and can‘t remember your password, we can send
            you an email with a link to reset it.
          </div>
          <div className="pt-4">
            <button
              id="reset_button"
              className="bg-[#2EBD85] hover:bg-[#71f1bf] w-[100%] text-black py-2 rounded-full text-black font-medium"
              onClick={() => navigate("/resetpassword")}
            >
              Reset password
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
