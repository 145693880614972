import { FC } from "react";

import logoIcon from "../assets/logo.svg";
import facebookIcon from "../assets/facebook.svg";
import twitterIcon from "../assets/twitter.svg";
import linkedinIcon from "../assets/linkedin.svg";
import instagramIcon from "../assets/instagram.svg";
import youtubeIcon from "../assets/youtube.svg";
import googleplayIcon from "../assets/googleplay.svg";
import applestoreIcon from "../assets/applestore.svg";

const Footer: FC = () => {
  return (
    <div className="p-6 bg-[#0B1620]">
      <div className="flex items-center py-10 border-b border-1 border-[#979797]">
        <div className="w-[3/12]">
          <img src={logoIcon} className="w-20 lg:w-40 aspect-[4/1]" />
        </div>
        <div className="ml-5 border-l border-l-4 border-white px-5 text-base lg:text-lg text-[#FFFFFF] font-light w-[9/12]">
          <p>
            Your all-in-one investment platform. AI-driven decisions, seamless
            valuations, limitless opportunities for trading and managing your
            portfolio.
          </p>
          <p>
            Experience the power of smart investing with Capiwise, where
            advanced technology meets effortless financial decision-making.
          </p>
        </div>
      </div>
      <div className="justify-between py-10 mb-10 border-b border-1 border-[#979797]">
        <div className="flex justify-between mb-10">
          <div className="">
            <div className="text-[#2EBD85] text-xl mb-4">Who we are</div>
            <div className="text-[#979797] text-lg hover:cursor-pointer">
              About us
            </div>
            <div className="text-[#979797] text-lg hover:cursor-pointer">
              Our values
            </div>
            <div className="text-[#979797] text-lg hover:cursor-pointer">
              Our vision
            </div>
          </div>
          <div className="">
            <div className="text-[#2EBD85] text-xl mb-4">Support</div>
            <div className="text-[#979797] text-lg hover:cursor-pointer">
              FAQ&apos;s
            </div>
            <div className="text-[#979797] text-lg hover:cursor-pointer">
              Help center
            </div>
            <div className="text-[#979797] text-lg hover:cursor-pointer">
              Contact us
            </div>
          </div>
          <div className="">
            <div className="text-[#2EBD85] text-xl mb-4">Instrument</div>
            <div className="text-[#979797] text-lg hover:cursor-pointer">
              Stocks
            </div>
            <div className="text-[#979797] text-lg hover:cursor-pointer">
              Watchlist
            </div>
            <div className="text-[#979797] text-lg hover:cursor-pointer">
              My portfolio
            </div>
            <div className="text-[#979797] text-lg hover:cursor-pointer">
              Plans
            </div>
          </div>
          <div className="">
            <div className="text-[#2EBD85] text-xl mb-4">
              Privacy and regulations
            </div>
            <div className="text-[#979797] text-lg hover:cursor-pointer">
              General risk disclosure
            </div>
            <div className="text-[#979797] text-lg hover:cursor-pointer">
              Legal notice
            </div>
            <div className="text-[#979797] text-lg hover:cursor-pointer">
              Financial services guide
            </div>
            <div className="text-[#979797] text-lg hover:cursor-pointer">
              Regulation & License
            </div>
          </div>
          <div className="">
            <div className="text-[#2EBD85] text-xl mb-4">Learn more</div>
            <div className="text-[#979797] text-lg hover:cursor-pointer">
              How it works
            </div>
            <div className="text-[#979797] text-lg hover:cursor-pointer">
              Financial freedom methods
            </div>
            <div className="text-[#979797] text-lg hover:cursor-pointer">
              Avoid scam
            </div>
            <div className="text-[#979797] text-lg hover:cursor-pointer">
              Buy and sell explained
            </div>
            <div className="text-[#979797] text-lg hover:cursor-pointer">
              Market research
            </div>
          </div>
        </div>
        <div className="justify-between flex ">
          <div className="">
            <div className="text-white font-bold text-xl mb-2">Follow us</div>
            <div className="flex items-center">
              <img src={facebookIcon} className="mr-4" />
              <img src={twitterIcon} className="mr-4" />
              <img src={linkedinIcon} className="mr-4" />
              <img src={instagramIcon} className="mr-4" />
              <img src={youtubeIcon} />
            </div>
          </div>
          <div className="flex">
            <img src={applestoreIcon} className="mr-5" />
            <img src={googleplayIcon} />
          </div>
        </div>
      </div>
      <div className="flex items-center justify-between">
        <div className="flex gap-8">
          <div className="text-[#979797] text-lg">Privacy and security</div>
          <div className="text-[#979797] text-lg">Terms and conditions</div>
          <div className="text-[#979797] text-lg">Cookies policy</div>
        </div>
        <div className="text-[#979797] text-lg">
          © CapiWise. All rights reserved.
        </div>
      </div>
    </div>
  );
};

export default Footer;
