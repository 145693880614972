import { FC } from "react";

interface ProgressLineInterface {
  mode: number;
}

const ProgressChangeLine: FC<ProgressLineInterface> = ({ mode }) => {
  return (
    <div className="relative w-[100%]">
      {mode == 0 ? (
        <div
          className="w-full bg-gray-300 rounded-full"
          style={{ height: "2px" }}
        >
          <div
            className="absolute w-2.5 h-2.5 z-20 rounded-full bg-green-500"
            style={{ top: "-4px" }}
          ></div>
          <div className="bg-red-600 h-2 rounded-full w-1/3"></div>
        </div>
      ) : mode == 1 ? (
        <div
          className="w-full bg-gray-500 rounded-full"
          style={{ height: "2px" }}
        >
          <div
            className="absolute w-2.5 h-2.5 z-20 rounded-full bg-green-500"
            style={{ left: "50%", top: "-4px" }}
          ></div>
          <div
            className="h-2 rounded-full w-1/3"
            style={{
              background: "linear-gradient(45deg, white, green",
              height: "2px",
              width: "50%",
            }}
          ></div>
        </div>
      ) : mode == 2 ? (
        <div
          className="w-full bg-gray-500 rounded-full"
          style={{ height: "2px" }}
        >
          <div
            className="absolute w-2.5 h-2.5 z-20 rounded-full bg-green-500"
            style={{ left: "100%", top: "-4px" }}
          ></div>
          <div
            className="h-2 rounded-full"
            style={{
              background: "linear-gradient(45deg, white, green",
              height: "2px",
              width: "100%",
            }}
          ></div>
        </div>
      ) : (
        <></>
      )}

      <div className="flex w-[100%] justify-between text-sm text-[#979797] pt-2">
        {mode == 0 ? (
          <>
            <span className="text-white">Email</span>
            <span>Enter Password</span>
            <span>Verify Email</span>
          </>
        ) : mode == 1 ? (
          <>
            <span>Email</span>
            <span className="text-white">Enter Password</span>
            <span>Verify Email</span>
          </>
        ) : mode == 2 ? (
          <>
            <span>Email</span>
            <span>Enter Password</span>
            <span className="text-white">Verify Email</span>
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default ProgressChangeLine;
