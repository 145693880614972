import React, { FC, useState, useContext, useEffect } from "react";

import { useNavigate } from "react-router-dom";

import { useValidEmail, useValidPassword } from "hooks/useAuthHooks";
import { Email, PasswordStrengh } from "components/authComponents";

import { AuthContext } from "contexts/authContext";
import backIcon from "assets/back_ico.svg";
import confirmIcon from "assets/confirm_ico.svg";
import Logo from "assets/logo.svg";
import ChangeHeader from "components/ProgressChangeHeader";
import { AttrToFind } from "subtools/AttrToFind";
import { AttrInfo } from "contexts/authContext";
import { S3 } from "aws-sdk";
import { LoadingSpinner } from "components/loadingSpinner";

const ChangeEmail: FC = () => {
  const [progress, setProgress] = useState(0);
  const { email, setEmail, emailIsValid } = useValidEmail("");
  const { password, setPassword, passwordIsValid } = useValidPassword("");
  const [loading, setLoading] = useState(false);

  const bucketname = process.env.REACT_APP_S3_BUCKET;
  const region = process.env.REACT_APP_AWS_REGION;
  const s3 = new S3({
    accessKeyId: process.env.REACT_APP_ACCESSKEYID,
    secretAccessKey: process.env.REACT_APP_SECRETACCESSKEY,
    region: region,
  });

  const navigate = useNavigate();
  const [code, setCode] = useState("");

  const [isShowPassword, setIsShowPassword] = useState(false);
  const [changeEmailError, setChangeEmailError] = useState("");

  useEffect(() => {
    const authHeader = document.getElementById("change-header");
    if (progress === 3) {
      if (authHeader) authHeader.style.display = "none";
    } else {
      if (authHeader) authHeader.style.display = "";
    }
  }, [progress]);

  const authContext = useContext(AuthContext);
  const currentEmail = AttrToFind(authContext.attrInfo, "email");
  const [emailCheck, setEmailCheck] = useState(false);

  const [userTBD, setUserTBD] = useState<string | undefined>();

  useEffect(() => {
    if (currentEmail && email === currentEmail) {
      setEmailCheck(true);
    } else {
      setEmailCheck(false);
    }
  }, [email]);

  const enterNewEmailHandle = () => {
    setProgress(1);
  };

  const NewEmailComponent = (
    <div className="text-center w-[360px] md:w-[45%] lg:w-[30%]">
      <div className="text-2xl text-white font-bold pt-11">
        Enter your new email address
      </div>
      <div className="text-sm text-[#979797] pt-3.5 font-normal">
        {`Before your change is confirmed, we'll ask you to enter your password
        and verify your new email address.`}
      </div>
      <div className="pt-10">
        <div className="border-[#979797] rounded-md h-9">
          <Email
            emailIsValid={emailIsValid}
            email={email}
            setEmail={setEmail}
          />
        </div>
      </div>
      {changeEmailError != "" ? (
        <div className="text-left text-red-500 py-2">{changeEmailError}</div>
      ) : (
        <></>
      )}
      {emailCheck ? (
        <div className="text-center text-[#979797] py-2">
          This is your current email address!
        </div>
      ) : (
        <></>
      )}
      <div className="pt-4">
        <button
          className="bg-[#2EBD85] hover:bg-[#71f1bf] w-[100%] py-2 rounded-full text-black disabled:bg-[#E2E7ED] disabled:cursor-not-allowed"
          disabled={emailCheck || !emailIsValid || email.length === 0}
          onClick={() => enterNewEmailHandle()}
        >
          Continue
        </button>
      </div>
    </div>
  );

  const cloneFile = async (sourceKey: string, destinationKey: string) => {
    try {
      // Get file metadata
      if (bucketname) {
        const copyParams = {
          Bucket: bucketname,
          CopySource: `${bucketname}/${sourceKey}`,
          Key: destinationKey,
        };
        const copyResponse = await s3.copyObject(copyParams).promise();

        if (copyResponse.CopyObjectResult) {
          // Delete the original file
          const deleteParams = {
            Bucket: bucketname,
            Key: sourceKey,
          };
          await s3.deleteObject(deleteParams).promise();

          console.log("File name changed successfully");
        }
      }
    } catch (error) {
      console.error("Error cloning file:", error);
    }
  };

  const enterPasswordHandle = async () => {
    setLoading(true);
    try {
      const username = authContext?.sessionInfo?.username;
      setUserTBD(username);

      const attrs: [AttrInfo] = await authContext.getAttributes();

      const indexemail = attrs.findIndex((obj) => obj.Name === "email");
      if (indexemail !== -1) {
        attrs.splice(indexemail, 1);
      }

      const indexStatus = attrs.findIndex(
        (obj) => obj.Name === "email_verified"
      );
      if (indexStatus !== -1) {
        attrs.splice(indexStatus, 1);
      }

      const indexPhoneStatus = attrs.findIndex(
        (obj) => obj.Name === "phone_number_verified"
      );
      if (indexPhoneStatus !== -1) {
        attrs.splice(indexPhoneStatus, 1);
      }

      const indexsub = attrs.findIndex((obj) => obj.Name === "sub");
      if (indexsub !== -1) {
        attrs.splice(indexsub, 1);
      }

      const newurl = `https://${bucketname}.s3.${region}.amazonaws.com/profile/${email}.png`;

      const indexPicture = attrs.findIndex((obj) => obj.Name === "picture");
      if (indexPicture !== -1) {
        attrs[indexPicture].Value = newurl;
      }

      await authContext.changeEmail(email, attrs, password);
      setProgress(2);
      setLoading(false);
    } catch (err: any) {
      // setChangeEmailError(err.message);
      if (err.code === "UsernameExistsException") {
        setChangeEmailError(
          "The user was already exists. Please check your email again or try with new email address. Thanks"
        );
        setTimeout(() => {
          setChangeEmailError("");
          setProgress(0);
        }, 1000);
      } else {
        setChangeEmailError(err.message);
      }
      setLoading(false);
    }
  };

  const PasswordComponent = (
    <div className="text-center w-[360px] md:w-[45%] lg:w-[30%]">
      {loading ? <LoadingSpinner /> : <></>}
      <div className="absolute left-[50px] lg:left-[70px] top-[100px] lg:top-[160px] flex items-center">
        <div>
          <img src={backIcon} width={14} height={14} />
        </div>
        <button
          className="text-white text-base border-b ml-2"
          onClick={() => setProgress(progress - 1)}
        >
          Back
        </button>
      </div>
      <div className="text-2xl text-white font-bold pt-11">
        Enter your password to confirm
      </div>
      <div className="text-sm text-[#979797] pt-3.5 font-normal">
        {`Before your change is confirmed, we'll ask you to enter your password
        and verify your new email address.`}
      </div>
      <div className="pt-10">
        <div className="border-[#979797] rounded-md h-9">
          <PasswordStrengh
            passwordIsValid={passwordIsValid}
            password={password}
            setPassword={setPassword}
            isShowPassword={isShowPassword}
            showStrength={false}
            setIsShowPassword={setIsShowPassword}
          />
        </div>
      </div>
      {changeEmailError != "" ? (
        <div className="text-left text-red-500 py-2">{changeEmailError}</div>
      ) : (
        <></>
      )}
      <div className="pt-4">
        <button
          className="bg-[#2EBD85] hover:bg-[#71f1bf] w-[100%] py-2 rounded-full text-black disabled:bg-[#E2E7ED] disabled:cursor-not-allowed"
          disabled={!passwordIsValid || password.length === 0}
          onClick={() => enterPasswordHandle()}
        >
          Continue
        </button>
      </div>
    </div>
  );

  const verifyCodeConfirmation = async () => {
    setLoading(true);
    try {
      const oldEmail = localStorage.getItem("autoSaved_email");
      const attrs: [AttrInfo] = await authContext.getAttributes();
      const indexPicture = attrs.findIndex((obj) => obj.Name === "picture");
      if (indexPicture !== -1) {
        await cloneFile(`profile/${oldEmail}.png`, `profile/${email}.png`);
      }
      await authContext.verifyCode(email, code);
      setProgress(3);
      setLoading(false);
    } catch (err: any) {
      setChangeEmailError(err.message);
      setLoading(false);
    }
  };

  const sendAgainHandle = async () => {
    await enterPasswordHandle();
  };

  const backClicked = () => {
    setTimeout(() => {
      setProgress(0);
    }, 1000);
  };

  // Enter 6-digit code
  const VerifyComponent = (
    <div className="text-center w-[360px] md:w-[45%] lg:w-[30%]">
      <div className="text-2xl text-white font-bold pt-11">
        Enter the 6-digit code
      </div>
      {loading ? <LoadingSpinner /> : <></>}
      <div className="absolute left-[50px] lg:left-[70px] top-[100px] lg:top-[160px] flex items-center">
        <div className="text-center">
          <img src={backIcon} width={14} height={14} />
        </div>
        <button
          className="text-white text-base border-b ml-2"
          onClick={() => backClicked()}
        >
          Back
        </button>
      </div>
      <div className="pt-8 text-[#979797] text-sm">
        We sent it to {email}.{" "}
        <span className="text-white border-b hover:cursor-pointer">change</span>
      </div>
      <div className="text-4">
        <div className="pt-8 text-left text-[#979797] text-sm">
          Your 6-digit code
        </div>
        <div className="pt-1">
          <input
            className={
              "bg-transparent border border-[#979797] text-white focus:outline-none rounded-md w-full h-full p-2"
            }
            type="text"
            onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
              setCode(evt.target.value);
            }}
          />
        </div>
      </div>
      <div
        className="pt-8 text-white text-sm hover:cursor-pointer"
        onClick={() => {
          sendAgainHandle();
        }}
      >
        <span className="border-b"> I didn‘t receive a code</span>
      </div>

      {changeEmailError != "" ? (
        <div className="text-left text-red-500 py-2">{changeEmailError}</div>
      ) : (
        <></>
      )}
      <div className="pt-4">
        <button
          className="bg-[#2EBD85] hover:bg-[#71f1bf] w-[100%] text-black py-2 rounded-full text-black"
          onClick={() => {
            verifyCodeConfirmation();
          }}
        >
          Submit
        </button>
      </div>
    </div>
  );

  const completeCreationProgress = async () => {
    setLoading(true);
    try {
      await authContext.closeAccount(userTBD, password);
      setLoading(false);
      authContext.signOut();
      navigate("/");
    } catch (err: any) {
      console.log(err.message);
      setLoading(false);
    }
  };

  const CompleteChangeComponent = (
    <div className="bg-[#23341B] w-full h-screen flex justify-center">
      {loading ? <LoadingSpinner /> : <></>}
      <div className="w-[100%] lg:w-9/12 p-2 lg:p-7">
        <div>
          <img src={Logo} />
        </div>
        <div className="flex flex-col text-center pt-12">
          <div className="flex justify-center">
            <img src={confirmIcon} />
          </div>
          <div className="text-white font-bold text-[40px] lg:text-[50px] leading-none pt-8">
            <p>EMAIL CHANGED!</p>
          </div>
          <div className="pt-2 text-sm text-[#A8CFBD]">
            <p>Your email has been changed successfully.</p>
          </div>
          <div className="pt-6">
            <button
              className="bg-[#2EBD85] hover:bg-[#71f1bf] w-[360px] md:w-[45%] lg:w-[30%] text-black py-2 rounded-full text-black"
              onClick={() => completeCreationProgress()}
            >
              Back to New Login
            </button>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="w-full h-screen">
      <ChangeHeader mode={progress} />
      <div className="w-full flex justify-center">
        {progress === 0
          ? NewEmailComponent
          : progress === 1
          ? PasswordComponent
          : progress === 2
          ? VerifyComponent
          : CompleteChangeComponent}
      </div>
    </div>
  );
};

export default ChangeEmail;
