import { FC, useState } from "react";
import enIcon from "assets/en_ico.svg";
import gridIcon from "assets/grid_ico.svg";
import Logo from "assets/logo.svg";
import { useNavigate } from "react-router-dom";
import Notificatoin from "assets/notification_ring.svg";
import MenuProfileSettings from "components/MenuProfileSettings";

const NavSearchAfterLogin: FC = () => {
  console.log(".....navafter...")
  const menuList = [
    "News",
    "Watchlist",
    "Portfolio",
    "Stock of today",
    "Stock comparison",
    "Social Investing",
    "Learning",
    "Plans",
  ];
  const [selectedItem, setSelectedItem] = useState(-1);
  const navigate = useNavigate();
  return (
    <div className="justify-between text-[white] bg-[#0B1620] text-base font-normal flex p-6">
      <div className="flex text-[white] bg-[#0B1620] text-xs lg:text-sm gap-5">
        <img src={Logo} />
        {menuList.map((_item: string, index: number) => (
          <div
            className={`border-b-4 hover:border-b-4 hover:cursor-pointer ${
              selectedItem === index
                ? "border-[#2EBD85]"
                : "border-[transparent]"
            } py-1`}
            key={index}
            onClick={() => setSelectedItem(index)}
          >
            {_item}
          </div>
        ))}
      </div>
      <div className="flex gap-5">
        <div className="flex items-center mx-2 hover:cursor-pointer">
          <img src={enIcon} />
          <div className="ml-1 text-sm">EN</div>
        </div>
        <div className="flex items-center ml-4 hover:cursor-pointer">
          <img src={gridIcon} />
          <div className="ml-1 text-sm">Company</div>
        </div>
        <div className="flex justify-center items-center">
          <img src={Notificatoin} />
        </div>
        <div className="flex justify-center items-center">
          <MenuProfileSettings />
        </div>
      </div>
    </div>
  );
};

export default NavSearchAfterLogin;
