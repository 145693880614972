import { FC } from "react";

import { useNavigate } from "react-router-dom";

import Logo from "assets/logo.svg";
import Close from "assets/close.svg";
import ProgressChangeLine from "subtools/ProgressChangeLine";

interface AuthHeaderInterface {
  mode: number;
}

const ChangeHeader: FC<AuthHeaderInterface> = ({ mode }) => {
  const navigate = useNavigate();

  return (
    <div className="w-full flex justify-center border-b-2" id="change-header">
      <div className="flex justify-center lg:justify-between w-full py-7 relative">
        <div className="hidden lg:inline w-[35%]">
          <div className="flex items-center justify-center">
            <img src={Logo} onClick={() => navigate("/")} />
          </div>
        </div>
        <div className="w-[360px] md:w-[45%] lg:w-[30%]">
          <ProgressChangeLine mode={mode} />
        </div>
        <div className="hidden lg:inline w-[35%]">
          <div className="flex items-center justify-center">
            <button onClick={() => navigate("/")}>
              <img src={Close} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangeHeader;
