import { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "contexts/authContext";
import ProfileManage from "./profileManage";
import { AttrToFind } from "subtools/AttrToFind";
import AWS from 'aws-sdk';

const MenuProfileSettings = () => {
  const navigatge = useNavigate();

  const [disable, setDisable] = useState(true);
  const [givenname, setGivenname] = useState();
  const [familyname, setFamilyname] = useState();
  const [picture, setPicture] = useState();
  

  const auth = useContext(AuthContext);

  AWS.config.update({
    accessKeyId: 'AKIAQDOWTFJNOHLKXHNG',
    secretAccessKey: '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',
    region: 'eu-central-1'
});

const cognito = new AWS.CognitoIdentityServiceProvider();

  useEffect(() => {
    if (auth) {
      setGivenname(AttrToFind(auth.attrInfo, "given_name"));
      setFamilyname(AttrToFind(auth.attrInfo, "family_name"));
      setPicture(AttrToFind(auth.attrInfo, "picture"));
    }
  }, [auth]);

  useEffect(() => {
    const lastAuthUser = localStorage.getItem('CognitoIdentityServiceProvider.709aci6kf266pekbf54u1b5icm.LastAuthUser');
    console.log('Last authenticated user:', lastAuthUser);

    // Fetch user attributes
    const params = {
        UserPoolId: 'eu-central-1_QukrjlcsL', // Specify your user pool ID
        Username: lastAuthUser // Specify the username of the last authenticated user
    };

    cognito.adminGetUser(params, (err, data) => {
        if (err) {
            console.error('Error fetching user attributes:', err);
        } else {
            console.log('User attributes:', data.UserAttributes);
            // Do something with the user attributes here
            let givenName = "";
            let familyName = "";
    
            // Iterate through the user attributes array to find 'given_name' and 'family_name'
            data.UserAttributes.forEach(attr => {
                if (attr.Name === 'given_name') {
                    givenName = attr.Value;
                } else if (attr.Name === 'family_name') {
                    familyName = attr.Value;
                }
            });

            console.log('Given Name:', givenName);
        console.log('Family Name:', familyName);
        setGivenname(givenName)

        setFamilyname(familyName)
        }
    });
}, []);


  return (
    <div className="flex items-center relative">
      <ProfileManage
        disable={disable}
        setDisable={setDisable}
        givenname={givenname}
        familyname={familyname}
      />
      <div
        className="flex items-center rounded-full bg-[#040B11] hover:cursor-pointer"
        onClick={() => setDisable(!disable)}
      >
        <div className="border border-green-400 rounded-full bg-green-500 w-8 h-8 flex items-center justify-center">
          {picture ? (
            <img
              src={picture}
              alt="Selected Image"
              className="w-8 h-8 rounded-full"
            />
          ) : (
            `${givenname ? givenname[0] : ""}${familyname ? familyname[0] : ""}`
          )}
        </div>
      </div>
    </div>
  );
};

export default MenuProfileSettings;
