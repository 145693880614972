import { FC, useState, useEffect } from "react";
import SearchStock from "components/SearchStock";
import Footer from "components/Footer";
import NavSearchBeforeLogin from "components/NavigationMenus/NavSearchBeforeLogin";
import NavSearchSticky from "components/NavigationMenus/NavSearchSticky";
import NavSearchAfterLogin from "components/NavigationMenus/NavSearchAfterLogin";

const Home: FC = () => {
  const [scrollTop, setScrollTop] = useState(0);

  useEffect(() => {
    const handleScroll = (event: any) => {
      setScrollTop(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="flex flex-col justify-between">
      {/* <NavSearchBeforeLogin /> */}
      <NavSearchAfterLogin/>
      {scrollTop > 50 ? (
        <div className="fixed z-10 w-[100%] p-2">
          {/* <NavSearchSticky /> */}
          <NavSearchAfterLogin/>
        </div>
      ) : (
        <></>
      )}

      <div className="h-screen flex items-center justify-center">
        <SearchStock disabled={true} />
      </div>
      <Footer />
    </div>
  );
};

export default Home;
