import React, { FC, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import AuthHeader from "components/AuthHeader";

import realConfLogo from "assets/realconfirm_ico.svg";
import backLogo from "assets/back_ico.svg";
import { AuthContext } from "contexts/authContext";

const RealConfirmClose: FC = () => {
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const username = authContext?.sessionInfo?.username;
  const password = localStorage.getItem("password");
  const [closeError, setCloseError] = useState("");

  console.log(username, password);

  const clickedBack = () => {
    navigate("/settings/account/confirmclose");
  };

  const clickedKeepOpen = () => {
    navigate("/settings");
  };

  const clickedContinue = async () => {
    authContext
      .closeAccount(username, password)
      .then(() => {
        navigate("/settings/account/successclose");
      })
      .catch((err: any) => {
        setCloseError(err.message);
      });
  };

  return (
    <div>
      <AuthHeader mode={2} />
      <div
        onClick={clickedBack}
        className="w-1/6 absolute left-[35px] lg:left-[57px] top-[75px] lg:top-[160px] flex hover:cursor-pointer"
      >
        <img className="mr-2" src={backLogo} /> Back
      </div>
      <div className="w-full pt-10 flex justify-center">
        <div className="w-[360px] md:w-[45%] lg:w-[30%] items-center justify-center">
          <div className="items-center flex text-center justify-center">
            <img src={realConfLogo} className="justify-center text-center" />
          </div>
          <div className="py-7">
            <div className="text-2xl text-center font-bold">
              You‘ll lose access to your activities and stock portfolio history
            </div>
            <div className="text-sm text-[#979797] py-2">
              By law, we‘re obliged to delete your data, but you‘ll no longer
              have access to your Capiwise activity and transaction history.
            </div>
          </div>
          {closeError != "" ? (
            <div className="text-left text-red-500 py-2">{closeError}</div>
          ) : (
            <></>
          )}
          <div
            onClick={clickedKeepOpen}
            className="text-center text-black w-full rounded-full bg-[#2EBD85] hover:bg-[#71f1bf] p-2 text-sm mb-6 hover:cursor-pointer font-medium"
          >
            Keep account open
          </div>
          <div
            onClick={clickedContinue}
            className="text-center text-[#ff0000] border border-[#ff0000] w-full rounded-full p-2 text-sm font-medium hover:cursor-pointer hover:bg-[#ff0000] hover:text-white"
          >
            Close account
          </div>
        </div>
      </div>
    </div>
  );
};

export default RealConfirmClose;
