import { FC } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "assets/logo.svg";
import Close from "assets/close.svg";
import MenuProfileSettings from "components/MenuProfileSettings";

interface AuthHeaderInterface {
  mode: number;
}

const AuthHeader: FC<AuthHeaderInterface> = ({ mode }) => {
  const navigate = useNavigate();

  return (
    <div className="w-full flex justify-center border-b-2" id="auth-header">
      <div className="flex justify-end lg:justify-between w-full py-7">
        <div
          className="hover:cursor-pointer hidden lg:inline ml-[11%]"
          onClick={() => navigate("/")}
        >
          <img src={Logo} className="w-[132px]" />
        </div>
        <div className="flex mr-[18%]">
          {mode == 1 ? (
            <>
              <div>
                <button
                  onClick={() => navigate("/")}
                  className="bg-transaprent hover:bg-[#979797] px-3 py-1 rounded-full text-sm font-medium"
                >
                  Log in
                </button>
              </div>
              <div className="pl-3">
                <button
                  onClick={() => navigate("/register")}
                  className="bg-[#2EBD85] hover:bg-[#71f1bf] px-3 py-1 rounded-full text-[#040B11] text-sm font-medium"
                >
                  Register
                </button>
              </div>
            </>
          ) : mode == 2 ? (
            <>
              <button onClick={() => navigate("/")}>
                <img src={Close} />
              </button>
            </>
          ) : mode == 3 ? (
            <>
              <button onClick={() => navigate("/register")}>
                <img src={Close} />
              </button>
            </>
          ) : mode == 4 ? (
            <>
              <MenuProfileSettings />
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default AuthHeader;
