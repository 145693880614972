
import React from "react";
import PropTypes from "prop-types";
import { Navigate, Outlet } from "react-router-dom";

 
const PublicRoute = ({logedIn}) => {

const logedIn1 =  localStorage.getItem("logedIn")
console.log("....logedIn...public...",logedIn1)
    if(logedIn1 == "true"){
     console.log("....coming ....")
        return  <Navigate to = {"/Home"} />
        
    }else{
       
        return <Outlet/>
    }

}
PublicRoute.propTypes = {
    logedIn: PropTypes.bool, // Add this line to define the prop type
  };

export default PublicRoute;
