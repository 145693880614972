import React, { FC, useState, useContext, useEffect } from "react";

import AuthHeader from "components/AuthHeader";
import homeLogo from "assets/home_ico.svg";
import emailLogo from "assets/email_ico.svg";
import downLogo from "assets/down_ico.svg";
import notifiLogo from "assets/notifi_ico.svg";
import connectLogo from "assets/connect_ico.svg";
import langLogo from "assets/lang_ico.svg";
import pwdLogo from "assets/pwd_ico.svg";
import verifyLogo from "assets/verify_ico.svg";
import logoutLogo from "assets/logout_round.svg";
import closeLogo from "assets/close_ico.svg";
import UpDownIcon from "subtools/up_down_icon";
import { AuthContext } from "contexts/authContext";
import { AttrToFind } from "subtools/AttrToFind";

import { Link, useNavigate } from "react-router-dom";
import DropdownBox from "subtools/DropdownBox";

interface AccountInfo {
  notifications?: string;
  language?: string;
  tfa?: string;
}

export const Settings = () => {
  const [selected, setSelected] = useState(0);
  const [settingError, setSettingError] = useState("");

  const [settingInfo, setSettingInfo] = useState<AccountInfo>({
    language: "English(US)",
  });

  const handleDataUpdate = (updatedData: string, updatedType: string) => {
    setSettingInfo((prevData: any) => ({
      ...prevData,
      [updatedType]: updatedData,
    }));
  };

  const clickNotificationContinue = () => {
    navigate("/settings/notification");
  };

  const clickedChangePwd = () => {
    navigate("/settings/password/changepassword");
  };

  const clickedCloseAccount = () => {
    navigate("/settings/account/closeaccount");
  };

  const languageSettingHandle = () => {
    console.log("languageSetting clicked");

    setSelected(0);
  };

  const clickedChangeEmail = () => {
    navigate("/settings/email/changeemail");
  };

  const logOutAllClicked = async () => {
    authContext
      .signOutAll()
      .then(() => {
        console.log("log out all clicked");
        navigate("/");
      })
      .catch((err: any) => {
        console.log("log out all clicked error");
        setSettingError(err.message);
      });
  };

  const authContext = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [emailStatus, setEmailStatus] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [phoneNumber1, setPhoneNumber1] = useState("");
  const [phoneNumber2, setPhoneNumber2] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    const userEmail = AttrToFind(authContext.attrInfo, "email");
    const authStatus = authContext.authStatus;

    if (userEmail) {
      setEmail(userEmail);
      setEmailStatus(authStatus === 1 ? true : false);
    }
  }, []);

  const [sendEmail, setSendEmail] = useState(false);

  const userRegisterHandle = async () => {
    setSendEmail(true);
    console.log(email);
  };

  const navigate = useNavigate();
  return (
    <div>
      <AuthHeader mode={4} />
      <div className="w-full flex justify-center">
        <div className="w-[100%] lg:w-9/12 p-7">
          <div className="flex justify-center items-center">
            <div className="w-[100%] md:w-3/4 flex-col">
              <div className="text-xl">Settings</div>
              <div>
                {selected == 0 ? (
                  <div>
                    <div
                      className="justify-between items-center flex w-full p-4 mt-10 hover:cursor-pointer"
                      onClick={() => setSelected(1)}
                    >
                      <img src={emailLogo} />
                      <div className="flex-col w-full ml-5">
                        <div className="text-white text-sm">Email settings</div>
                        <div className="text-[#979797] text-sm">
                          {emailStatus ? (
                            <div>
                              {`Your email ${email} has already been `}
                              <span className="text-[#2EBD85]">verified</span>
                            </div>
                          ) : (
                            `Your email ${email} is pending verification.
                          Please check your email to confirm it`
                          )}
                        </div>
                      </div>
                      <UpDownIcon clicked={false} />
                    </div>
                  </div>
                ) : selected == 1 ? (
                  <div className="bg-[#0B1620] rounded-md ">
                    <div
                      className="justify-between items-center flex w-full p-4 pb-2 mt-10 hover:cursor-pointer border-b border-b-black"
                      onClick={() => setSelected(0)}
                    >
                      <img src={emailLogo} />
                      <div className="flex-col w-full ml-5">
                        <div className="text-white text-sm">Email settings</div>
                        <div className="text-[#979797] text-sm">
                          {emailStatus ? (
                            <div>
                              {`Your email ${email} has already been `}
                              <span className="text-[#2EBD85]">verified</span>
                            </div>
                          ) : (
                            `Your email ${email} is pending verification.
                          Please check your email to confirm it`
                          )}
                        </div>
                      </div>
                      <UpDownIcon clicked={true} />
                    </div>
                    <div className="pb-1">
                      {!emailStatus ? (
                        <div
                          onClick={userRegisterHandle}
                          className="text-center text-black w-[120px] lg:w-[15%] rounded-full bg-[#2EBD85] hover:bg-[#71f1bf] p-2 text-xs m-3 ml-4 hover:cursor-pointer"
                        >
                          Send again
                        </div>
                      ) : (
                        <div
                          onClick={clickedChangeEmail}
                          className="text-center text-black w-[120px] lg:w-[15%] rounded-full bg-[#2EBD85] hover:bg-[#71f1bf] p-2 text-xs m-3 ml-4 hover:cursor-pointer"
                        >
                          Change Email
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  <div>
                    <div
                      className="justify-between items-center flex w-full p-4 mt-10 hover:cursor-pointer"
                      onClick={() => setSelected(1)}
                    >
                      <img src={emailLogo} />
                      <div className="flex-col w-full ml-5">
                        <div className="text-white text-sm">Email settings</div>
                        <div className="text-[#979797] text-sm">
                          Your email user@gmail.com is pending verification.
                          Please check your email to confirm it
                        </div>
                      </div>
                      <UpDownIcon clicked={false} />
                    </div>
                  </div>
                )}

                {selected == 0 ? (
                  <div>
                    <div
                      className="justify-between items-center flex w-full p-4 mt-10 hover:cursor-pointer"
                      onClick={() => setSelected(2)}
                    >
                      <img src={notifiLogo} />
                      <div className="flex-col w-full ml-5">
                        <div className="text-white text-sm">Notifications</div>
                        <div className="text-[#979797] text-sm">
                          Chose what we get in touch about
                        </div>
                      </div>
                      <UpDownIcon clicked={false} />
                    </div>
                  </div>
                ) : selected == 2 ? (
                  <div className="bg-[#0B1620] rounded-md ">
                    <div
                      className="border-b border-b-black items-center justify-between flex w-full p-4 pb-2 mt-10 hover:cursor-pointer"
                      onClick={() => setSelected(0)}
                    >
                      <img src={notifiLogo} />
                      <div className="flex-col w-full ml-5">
                        <div className="text-white text-sm">Notifications</div>
                        <div className="text-[#979797] text-sm">
                          Chose what we get in touch about
                        </div>
                      </div>
                      <UpDownIcon clicked={true} />
                    </div>
                    <div className="pb-1 ">
                      <div
                        className="text-center text-black w-[120px] lg:w-[15%] rounded-full bg-[#2EBD85] hover:bg-[#71f1bf] p-2 text-xs m-3 ml-4 hover:cursor-pointer"
                        onClick={clickNotificationContinue}
                      >
                        Continue
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div
                      className="justify-between items-center flex w-full p-4 mt-10 hover:cursor-pointer"
                      onClick={() => setSelected(2)}
                    >
                      <img src={notifiLogo} />
                      <div className="flex-col w-full ml-5">
                        <div className="text-white text-sm">Notifications</div>
                        <div className="text-[#979797] text-sm">
                          Chose what we get in touch about
                        </div>
                      </div>
                      <UpDownIcon clicked={false} />
                    </div>
                  </div>
                )}
                <div>
                  <div className="justify-between items-center flex w-full p-4 mt-10 hover:cursor-pointer">
                    <img src={connectLogo} />
                    <div className="flex-col w-full ml-5">
                      <div className="text-white text-sm">
                        Connected accounts
                      </div>
                      <div className="text-[#979797] text-sm">
                        Tools, platforms and banks connected to your Capiwise
                        account
                      </div>
                    </div>
                    <UpDownIcon clicked={false} />
                  </div>
                </div>
                {selected == 0 ? (
                  <div>
                    <div
                      className="justify-between items-center flex w-full p-4 mt-10 hover:cursor-pointer"
                      onClick={() => setSelected(3)}
                    >
                      <img src={langLogo} />
                      <div className="flex-col w-full ml-5">
                        <div className="text-white text-sm">
                          Language settings
                        </div>
                        <div className="text-[#979797] text-sm">
                          {settingInfo?.language}
                        </div>
                      </div>
                      <UpDownIcon clicked={false} />
                    </div>
                  </div>
                ) : selected == 3 ? (
                  <div className="bg-[#0B1620] rounded-md ">
                    <div
                      className="border-b items-center border-b-black justify-between flex w-full p-4 pb-2 mt-10 hover:cursor-pointer border-b border-b-[black]"
                      onClick={() => setSelected(0)}
                    >
                      <img src={langLogo} />
                      <div className="flex-col w-full ml-5">
                        <div className="text-white text-sm">
                          Language settings
                        </div>
                        <div className="text-[#979797] text-sm"></div>
                      </div>
                      <UpDownIcon clicked={true} />
                    </div>
                    <div className="pb-1 ">
                      <div className="w-1/2 ml-3">
                        <DropdownBox
                          dataArray={["English(US)", "German"]}
                          dataType={"language"}
                          onDataUpdate={handleDataUpdate}
                          defaultData={"English(US"}
                        />
                      </div>
                      <div
                        className="text-center text-black w-[120px] lg:w-[15%] rounded-full bg-[#2EBD85] hover:bg-[#71f1bf] p-2 text-xs m-3 ml-4 hover:cursor-pointer"
                        onClick={languageSettingHandle}
                      >
                        Save
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div
                      className="justify-between items-center flex w-full p-4 mt-10 hover:cursor-pointer"
                      onClick={() => setSelected(3)}
                    >
                      <img src={langLogo} />
                      <div className="flex-col w-full ml-5">
                        <div className="text-white text-sm">
                          Language settings
                        </div>
                        <div className="text-[#979797] text-sm">
                          {settingInfo?.language}
                        </div>
                      </div>
                      <UpDownIcon clicked={false} />
                    </div>
                  </div>
                )}
                {selected == 0 ? (
                  <div>
                    <div
                      className="justify-between items-center flex w-full p-4 mt-10 hover:cursor-pointer"
                      onClick={() => setSelected(4)}
                    >
                      <img src={pwdLogo} />
                      <div className="flex-col w-full ml-5">
                        <div className="text-white text-sm">
                          Change password
                        </div>
                        <div className="text-[#979797] text-sm">
                          We will send you a link to your email to make changing
                          your password more secure
                        </div>
                      </div>
                      <UpDownIcon clicked={false} />
                    </div>
                  </div>
                ) : selected == 4 ? (
                  <div className="bg-[#0B1620] rounded-md ">
                    <div
                      className="border-b items-center border-b-black justify-between flex w-full p-4 pb-2 mt-10 hover:cursor-pointer"
                      onClick={() => setSelected(0)}
                    >
                      <img src={pwdLogo} />
                      <div className="flex-col w-full ml-5">
                        <div className="text-white text-sm">
                          Change password
                        </div>
                        <div className="text-[#979797] text-sm">
                          We will send you a link to your email to make changing
                          your password more secure
                        </div>
                      </div>
                      <UpDownIcon clicked={true} />
                    </div>
                    <div className="pb-1 ">
                      <div
                        className="text-center text-black w-[120px] lg:w-[15%] rounded-full bg-[#2EBD85] hover:bg-[#71f1bf] p-2 text-xs m-3 ml-4 hover:cursor-pointer"
                        onClick={clickedChangePwd}
                      >
                        Send link
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div
                      className="justify-between items-center flex w-full p-4 mt-10 hover:cursor-pointer"
                      onClick={() => setSelected(4)}
                    >
                      <img src={pwdLogo} />
                      <div className="flex-col w-full ml-5">
                        <div className="text-white text-sm">
                          Change password
                        </div>
                        <div className="text-[#979797] text-sm">
                          We will send you a link to your email to make changing
                          your password more secure
                        </div>
                      </div>
                      <UpDownIcon clicked={false} />
                    </div>
                  </div>
                )}
                {selected == 0 ? (
                  <div>
                    <div
                      className="justify-between items-center flex w-full p-4 mt-10 hover:cursor-pointer"
                      onClick={() => setSelected(5)}
                    >
                      <img src={verifyLogo} />
                      <div className="flex-col w-full ml-5">
                        <div className="text-white text-sm">
                          2-step verification
                        </div>
                        <div className="text-[#979797] text-sm">
                          Manage your 2-step verification methods
                        </div>
                      </div>
                      <UpDownIcon clicked={false} />
                    </div>
                  </div>
                ) : selected == 5 ? (
                  <div className="bg-[#0B1620] rounded-md ">
                    <div
                      className="border-b items-center border-b-black justify-between flex w-full p-4 pb-2 mt-10 hover:cursor-pointer"
                      onClick={() => setSelected(0)}
                    >
                      <img src={verifyLogo} />
                      <div className="flex-col w-full ml-5">
                        <div className="text-white text-sm">
                          2-step verification
                        </div>
                        <div className="text-[#979797] text-sm">
                          Manage your 2-step verification methods
                        </div>
                      </div>
                      <UpDownIcon clicked={true} />
                    </div>
                    <div className="pb-1 ">
                      <div className="text-center text-black w-[265px] lg:w-[32%] rounded-full bg-[#2EBD85] hover:bg-[#71f1bf] p-2 text-xs m-3 ml-4 hover:cursor-pointer">
                        Change 2-step verification settings
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div
                      className="justify-between items-center flex w-full p-4 mt-10 hover:cursor-pointer"
                      onClick={() => setSelected(5)}
                    >
                      <img src={verifyLogo} />
                      <div className="flex-col w-full ml-5">
                        <div className="text-white text-sm">
                          2-step verification
                        </div>
                        <div className="text-[#979797] text-sm">
                          Manage your 2-step verification methods
                        </div>
                      </div>
                      <UpDownIcon clicked={false} />
                    </div>
                  </div>
                )}
                {selected == 0 ? (
                  <div>
                    <div
                      className="justify-between items-center flex w-full p-4 mt-10 hover:cursor-pointer"
                      onClick={() => setSelected(6)}
                    >
                      <img src={logoutLogo} />
                      <div className="flex-col w-full ml-5">
                        <div className="text-white text-sm">
                          Log out of all devices
                        </div>
                        <div className="text-[#979797] text-sm">
                          If you notice any suspicious activity, log out of
                          Capiwise across all devices and browsers
                        </div>
                      </div>
                      <UpDownIcon clicked={false} />
                    </div>
                  </div>
                ) : selected == 6 ? (
                  <div className="bg-[#0B1620] rounded-md ">
                    <div
                      className="border-b items-center border-b-black justify-between flex w-full p-4 pb-2 mt-10 hover:cursor-pointer"
                      onClick={() => setSelected(0)}
                    >
                      <img src={logoutLogo} />
                      <div className="flex-col w-full ml-5">
                        <div className="text-white text-sm">
                          Log out of all devices
                        </div>
                        <div className="text-[#979797] text-sm">
                          If you notice any suspicious activity, log out of
                          Capiwise across all devices and browsers
                        </div>
                      </div>
                      <UpDownIcon clicked={true} />
                    </div>
                    <div className="pb-1 ">
                      <div
                        className="text-center text-white w-[208px] lg:w-[26%] rounded-full bg-[#E2433B] p-2 text-xs m-3 ml-4 hover:cursor-pointer"
                        onClick={logOutAllClicked}
                      >
                        Log out from all devices
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div
                      className="justify-between items-center flex w-full p-4 mt-10 hover:cursor-pointer"
                      onClick={() => setSelected(6)}
                    >
                      <img src={logoutLogo} />
                      <div className="flex-col w-full ml-5">
                        <div className="text-white text-sm">
                          Log out of all devices
                        </div>
                        <div className="text-[#979797] text-sm">
                          If you notice any suspicious activity, log out of
                          Capiwise across all devices and browsers
                        </div>
                      </div>
                      <UpDownIcon clicked={false} />
                    </div>
                  </div>
                )}
                {selected == 0 ? (
                  <div>
                    <div
                      className="justify-between items-center flex w-full p-4 mt-10 hover:cursor-pointer"
                      onClick={() => setSelected(7)}
                    >
                      <img src={closeLogo} />
                      <div className="flex-col w-full ml-5">
                        <div className="text-white text-sm">
                          Close your account
                        </div>
                        <div className="text-[#979797] text-sm">
                          We‘re sorry to see you leave
                        </div>
                      </div>
                      <UpDownIcon clicked={false} />
                    </div>
                  </div>
                ) : selected == 7 ? (
                  <div className="bg-[#0B1620] rounded-md ">
                    <div
                      className="border-b items-center border-b-black justify-between flex w-full p-4 pb-2 mt-10 hover:cursor-pointer"
                      onClick={() => setSelected(0)}
                    >
                      <img src={closeLogo} />
                      <div className="flex-col w-full ml-5">
                        <div className="text-white text-sm">
                          Close your account
                        </div>
                        <div className="text-[#979797] text-sm">
                          We‘re sorry to see you leave
                        </div>
                      </div>
                      <UpDownIcon clicked={true} />
                    </div>
                    <div className="pb-1 ">
                      <div
                        onClick={clickedCloseAccount}
                        className="text-center text-black w-[120px] lg:w-[15%] rounded-full bg-[#2EBD85] hover:bg-[#71f1bf] p-2 ml-4 text-xs m-3 hover:cursor-pointer"
                      >
                        Continue
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div
                      className="justify-between items-center flex w-full p-4 mt-10 hover:cursor-pointer"
                      onClick={() => setSelected(7)}
                    >
                      <img src={closeLogo} />
                      <div className="flex-col w-full ml-5">
                        <div className="text-white text-sm">
                          Close your account
                        </div>
                        <div className="text-[#979797] text-sm">
                          We‘re sorry to see you leave
                        </div>
                      </div>
                      <UpDownIcon clicked={false} />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
