import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  useValidEmail,
  useValidFirstname,
  useValidLastname,
  useValidPassword,
} from "hooks/useAuthHooks";
import {
  Email,
  Firstname,
  Lastname,
  PasswordStrengh,
} from "components/authComponents";
import { AuthContext } from "contexts/authContext";
import backIcon from "assets/back_ico.svg";
import infoIcon from "assets/info_ico.svg";
import confirmIcon from "assets/confirm_ico.svg";
import Logo from "assets/logo.svg";
import facebookIcon from "assets/facebook_ico.svg";
import ProgressAuthHeader from "components/ProgressAuthHeader";
import GoogleSSO from "components/GoogleSSO";
import { Auth  } from 'aws-amplify';
// import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import Amplify from 'aws-amplify' ;
import googleIcon from "assets/google_ico.svg";


Amplify.configure({
  Auth:{

    //required only for federation Authetication -Amazon Cognito Identity Pool ID
    identityPoolId : "eu-central-1:2e4d9059-fb14-4c03-b64c-b9954e2367ee",
  
    // Required amazon cognito region
    region: "eu-central-1",


// optional - amazon cognito federated Identity Pool Region
// Required only if it's different from Amazon Cognito Region
    identityPoolRegion : 'eu-central-1',

    // Optional - amazon Cognito user Pool Id
    userPoolId : "eu-central-1_QukrjlcsL",

    // optional - Amazon Cognito Web CLient Id

    userPoolWebClientId : '709aci6kf266pekbf54u1b5icm',
    oauth : {
      domain : 'cw-test.auth.eu-central-1.amazoncognito.com',
       scope : ["email","phone","openid"],
      redirectSignIn : 'https://dashboard.dev.capiwise.com/Home',
      // redirectSignOut : 'http://localhost:3000/',
      responseType : 'token',
      // options: {
      //   // Specify additional scopes here if needed
      //   scopes: ["https://www.googleapis.com/auth/calendar"],
      // },
      
    }
  }
});

// const signin = ({email,password}, setUser) => {
//   Auth.signIn(email,password).then(user =>{
//     console.log('success',user)
//     setUser(user)
//   })
//   .catch(err => console.log(err));
// }

// const confirmCode = (values,user) => {
//   Auth.confirmSignIn(
//      user, // Retrun object from Auth.signIn()
//      values.code, // Confirmation code
//      "SMS_MFA" //MFA Type e.g. SMS_MFA, Software_Token_MFA
//   ).then(console.log)
//   .catch(console.error)
// }

export default function Register() {
  const [user,setUser] = useState(null)

  
  const init_pwd = process.env.REACT_APP_INIT_PWD;
  const [progress, setProgress] = useState(0);
  console.log(progress);
  const [verifyStatus, setVerifyStatus] = useState(0);

  const { email, setEmail, emailIsValid } = useValidEmail("");
  const { firstName, setFirstName, fisrtnameIsValid } = useValidFirstname("");
  const { lastName, setLastName, lastnameIsValid } = useValidLastname("");
  const { password, setPassword, passwordIsValid } = useValidPassword("");
  const {
    password: passwordConfirm,
    setPassword: setPasswordConfirm,
    passwordIsValid: passwordConfirmIsValid,
  } = useValidPassword("");

  const navigate = useNavigate();

  const [countryNameList, setCountryNameList] = useState([]);
  const [callingCodesList, setCallingCodesList] = useState([]);
  const [countryFlagsList, setCountryFlagsList] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [registerError, setRegisterError] = useState("");

  const [phoneNumber1, setPhoneNumber1] = useState("+49:84");
  const [phoneNumber2, setPhoneNumber2] = useState("");
  // const [password, setPassword] = useState("");

  const [code, setCode] = useState("");

  const [isShowPassword, setIsShowPassword] = useState(false);


  // console.log("..user...",user)
  // useEffect(() => {
  //   console.log("testing")
  //   Auth.currentAuthenticatedUser({
  //     bypassCache : false // Optional By default is false . If set to true ,this call will send a request to Cognito to get the latest user data
  //   }).then(user => console.log(user))
  //   .catch(err => console.log(err));
  // },[])

  const getCountryNames = async () => {
    try {
        // Make a GET request to the REST Countries API
        const response = await fetch("https://restcountries.com/v2/all");

        // Parse the response data as JSON
        const data = await response.json();

        // Extract the country names from the response data
        const countryNames = data.map(country => country.name);
        const callingCodes = data.map(country => "+" + country.callingCodes);
        const countryFlags = data.map(country => country.flag);

        // Assuming these are state setter functions or variables to hold the data
        setCountryNameList(countryNames);
        setCallingCodesList(callingCodes);
        setCountryFlagsList(countryFlags);
    } catch (error) {
        // Handle the error
        throw new Error("Failed to get country names");
    }
};


  useEffect(() => {
    getCountryNames();
  }, []);

  useEffect(() => {
    const authHeader = document.getElementById("auth-header");
    if (progress == 5) {
      if (authHeader) authHeader.style.display = "none";
    } else {
      if (authHeader) authHeader.style.display = "";
    }
  }, [progress]);

  const authContext = useContext(AuthContext);

  console.log(phoneNumber1);

  const handleGoogleSignIn = async () => {

    try {
      // Initiate Google federated sign-in
     let response =  await Auth.federatedSignIn({ provider: 'Google' });
    
     console.log("response..",response);
            localStorage.setItem("logedIn", "true");
      // navigate('/Home')
      // Redirect to a specific page after successful sign-in
      // navigate("/dashboard"); // Replace "/dashboard" with the path to your desired destination
    } catch (error) {
      // Handle error if Google sign-in fails
      console.error("Google sign-in error:", error);
    }
  };

  const sendRegistrationCode = async () => {
    try {
      await authContext.signUpWithEmail(email, firstName, lastName, init_pwd);
      // await authContext.handleGoogleLogin(email, init_pwd);
      setProgress(1);
    } catch (err) {
      setRegisterError(err.message);
  
      setTimeout(() => {
        navigate("/");
      }, 5000);
    }
  };
  
  // Create Account Progress 1
  const CreateAccountUserComponent = (
    <div className="text-center w-[360px] md:w-[45%] lg:w-[30%]">
      {/* User Account Input Component */}
      <div className="text-2xl text-white font-bold pt-11">
        Create your Capiwise account
      </div>
      <div className="text-sm text-[#979797] pt-3.5">
        Already have an account?{" "}
        <span
          className="text-white border-b hover:cursor-pointer"
          onClick={() => navigate("/")}
        >
          Log in
        </span>
      </div>
      <div className="pt-10">
        <div className="text-sm text-left text-[#979797] mb-1">First name</div>
        <div className="border-[#979797] rounded-md h-9">
          <Firstname
            firstnameIsValid={fisrtnameIsValid}
            setFirstname={setFirstName}
          />
        </div>
        <div className="text-sm text-left text-[#979797] mt-5 mb-1">
          Last name
        </div>
        <div className="border-[#979797] rounded-md h-9">
          <Lastname
            lastnameIsValid={lastnameIsValid}
            setLastname={setLastName}
          />
        </div>
        <div className="text-sm text-left text-[#979797] mt-5 mb-1">
          Enter your email address
        </div>
        <div className="border-[#979797] rounded-md h-9">
          <Email
            emailIsValid={emailIsValid}
            email={email}
            setEmail={setEmail}
          />
        </div>
      </div>
      {registerError != "" ? (
        <div className="text-left text-red-500 py-2">{registerError}</div>
      ) : (
        <></>
      )}
      <div className="pt-4">
        <button
          className="bg-[#2EBD85] hover:bg-[#71f1bf] w-[100%] py-2 rounded-full text-black disabled:bg-[#E2E7ED] disabled:cursor-not-allowed"
          disabled={!emailIsValid || email.length === 0}
          onClick={() => sendRegistrationCode()}
        >
          Next
        </button>
      </div>
      {/* Social Sign in Components */}
      <div className="pt-6 lg:pt-20">
        <div className="text-sm text-left text-[#979797]">Or log in with</div>
        <div className="flex item-start pt-4">
          <div className="w-1/2 px-1">
            {/* <GoogleSSO setError={setRegisterError} />
             */}
            {/* <button onClick={handleGoogleSignIn}>Google Sign In</button>
             */}
             <div
      className="rounded-full bg-transparent hover:bg-[#0053AA33] hover:cursor-pointer border-[#979797] border flex justify-center py-2"
      onClick={() => handleGoogleSignIn()}
    >
      <img src={googleIcon} className="w-5 h-5" alt="Google Icon" />
    </div>
          </div>
          <div className="w-1/2 px-1">
            <div className="rounded-full bg-transparent border-[#979797] border py-2 flex justify-center">
              <img src={facebookIcon} />
            </div>
          </div>
        </div>
      </div>
      {/* Terms and Policy */}
      <div className="pt-4 lg:pt-10 text-[#979797] text-sm">
        By registering, you accept our{" "}
        <span
          className="text-white border-b cursor-pointer"
          onClick={() => navigate("/termsOfUse")}
        >
          Terms of use
        </span>{" "}
        and <br />
        <span
          className="text-white border-b cursor-pointer"
          onClick={() => navigate("/privacyPolicy")}
        >
          Privacy Policy
        </span>
      </div>
    </div>
  );

  // Enter 6-digit code
  const VerifyComponent5 = (
    <>
      <div className="text-2xl text-white font-bold pt-11">
        Enter the 6-digit code
      </div>
      <div className="absolute left-[35px] lg:left-[57px] top-[75px] lg:top-[160px] flex items-center">
        <div className="text-center">
          <img src={backIcon} width={14} height={14} />
        </div>
        <button
          className="text-white text-base border-b ml-2"
          onClick={() => setVerifyStatus(0)}
        >
          Back
        </button>
      </div>
      <div className="pt-8 text-[#979797] text-sm">
        We sent it to {email}.{" "}
        <span className="text-white border-b hover:cursor-pointer">change</span>
      </div>
      <div className="text-4">
        <div className="pt-8 text-left text-[#979797] text-sm">
          Your 6-digit code
        </div>
        <div className="pt-1">
          <input
            className={
              "bg-transparent border border-[#979797] text-white focus:outline-none rounded-md w-full h-full p-2"
            }
            type="text"
            onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
              setCode(evt.target.value);
            }}
          />
        </div>
      </div>
      <div
        className="pt-8 text-white text-sm hover:cursor-pointer"
        onClick={() => {
          verifyCodeConfirmation();
        }}
      >
        <span className="border-b"> I didn‘t receive a code</span>
      </div>

      {registerError != "" ? (
        <div className="text-left text-red-500 py-2">{registerError}</div>
      ) : (
        <></>
      )}

      <div className="pt-4">
        <button
          className="bg-[#2EBD85] hover:bg-[#71f1bf] w-[100%] text-black py-2 rounded-full text-black"
          onClick={() => {
            verifyCodeConfirmation();
          }}
        >
          Submit
        </button>
      </div>
    </>
  );

  const VerifyComponent = (
    <div className="text-center w-[360px] md:w-[45%] lg:w-[30%]">
      {VerifyComponent5}
    </div>
  );

  const setCountryAttr = async () => {
    try {
      const pwd = init_pwd;
      await authContext.setSpecificAttribute(email, pwd, {
        Name: "address",
        Value: selectedCountry,
      });
    } catch (err) {
      setRegisterError(err.message);
    }
  };
  
  // Create Account Progress 2
  const PrimaryResidenceComponent = React.createElement(
    "div",
    { className: "text-center w-[360px] md:w-[45%] lg:w-[30%]" },
    React.createElement(
      "div",
      { className: "absolute left-[35px] lg:left-[57px] top-[75px] lg:top-[160px] flex items-center" },
      React.createElement("div", {}, React.createElement("img", { src: backIcon, width: 14, height: 14 })),
      React.createElement("button", {
        className: "text-white text-base border-b ml-2",
        onClick: () => setProgress(progress - 1)
      }, "Back")
    ),
    React.createElement("div", { className: "text-2xl text-white font-bold pt-11 pb-8" }, "Your country of primary residence"),
    // Country Select Component
    React.createElement(
      "div",
      { className: "text-base relative" },
      React.createElement("select", {
        id: "country-select",
        className: "bg-[#040B11] py-3 px-4 border border-white rounded-xl w-[100%] appearance-none",
        onChange: (ev) => setSelectedCountry(ev.target.value)
      },
      countryNameList.map((country) => React.createElement("option", { key: country, value: country }, country))),
      React.createElement(
        "div",
        { className: "absolute inset-y-0 right-0 flex items-center pr-4 pointer-events-none" },
        React.createElement("svg", {
          xmlns: "http://www.w3.org/2000/svg",
          className: "w-5 h-5 text-gray-400",
          viewBox: "0 0 20 20",
          fill: "none",
          stroke: "currentColor"
        },
        React.createElement("path", {
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "2",
          d: "M7 10l5 5 5-5"
        }))
      )
    ),
    // Continue Next Step Button
    registerError !== "" ?
      React.createElement("div", { className: "text-left text-red-500 py-2" }, registerError)
      : React.createElement(React.Fragment, {}),
    React.createElement(
      "div",
      { className: "pt-4" },
      React.createElement("button", {
        className: "bg-[#2EBD85] hover:bg-[#71f1bf] w-[100%] py-2 rounded-full text-black",
        onClick: () => {
          setCountryAttr();
          setProgress(3);
        }
      }, "Continue")
    )
  );
  
  // Create Account Progress 3

  const verifyCodeConfirmation = async () => {
    try {
      await authContext.verifyCode(email, code);
      setProgress(2);
    } catch (err) {
      setRegisterError(err.message);
    }
  };
  

  const [existPhone, setExistPhone] = useState("");
  const setPhoneNumberAttr = async () => {
    try {
      const pwd = init_pwd;
      const ph = `${phoneNumber1.split(":")[0]}${phoneNumber2}`;
      const phoneExisting = await authContext.checkPhoneNumber(ph);
      setExistPhone(phoneExisting);
      if (!phoneExisting) {
        await authContext.setSpecificAttribute(email, pwd, {
          Name: "phone_number",
          Value: ph,
        });
        await authContext.setSpecificAttribute(email, pwd, {
          Name: "locale",
          Value: phoneNumber1,
        });
        setProgress(4);
      } else {
        setVerifyStatus(1);
      }
    } catch (err) {
      setRegisterError(err.message);
    }
  };
  

  function getHiddenmail(email) {
    if (email) {
      const parts = email.split("@");
      const username = parts[0];
      const domain = parts[1];
      return `${username[0]}******${username[username.length - 1]}@${domain}`;
    } else {
      return "";
    }
  }
  

  const VerifyComponent1 = React.createElement(
    React.Fragment,
    {},
    React.createElement("div", { className: "absolute left-[35px] lg:left-[57px] top-[75px] lg:top-[160px] flex items-center" },
      React.createElement("div", {},
        React.createElement("img", { src: backIcon, width: 14, height: 14 })
      ),
      React.createElement("button", {
        className: "text-white text-base border-b ml-2",
        onClick: () => setProgress(progress - 1)
      }, "Back")
    ),
    React.createElement("div", { className: "text-2xl text-white font-bold pt-11" }, "Enter your phone number"),
    React.createElement("div", { className: "flex flex-row mt-1 h-[43px]" },
      React.createElement("div", { className: "relative w-1/5 border border-[#979797] rounded-lg" },
        React.createElement("select", {
          id: "callingcode-select",
          className: "bg-transparent w-full py-3 px-5 focus:outline-none rounded-lg",
          onChange: (ev) => setPhoneNumber1(ev.target.value)
        },
        callingCodesList.map((code, idx) => React.createElement("option", {
          key: "ccl" + code + idx,
          value: `${code}:${idx}`,
          className: "bg-black"
        }, `${countryNameList[idx]} ${code}`)),
        React.createElement("img", {
          src: countryFlagsList[parseInt(phoneNumber1.split(":")[1])],
          className: "relative top-[-39px] left-[3px] w-[75%] h-[33px]"
        })
        )
      ),
      React.createElement("div", { className: "w-4/5 ml-3 border border-[#979797] rounded-lg" },
        React.createElement("input", {
          className: "w-full bg-transparent focus:outline-none rounded-lg p-2",
          onChange: (ev) => setPhoneNumber2(ev.target.value)
        })
      )
    ),
    registerError !== "" ? 
      React.createElement("div", { className: "text-left text-red-500 py-2" }, registerError) 
      : React.createElement(React.Fragment, {}),
    React.createElement("div", { className: "pt-4" },
      phoneNumber2 === "" ?
        React.createElement("button", { className: "bg-[#E2E7ED] hover:cursor-not-allowed w-[100%] text-[#464F56] py-2 rounded-full text-black" }, "Continue")
        : React.createElement("button", {
          className: "bg-[#2EBD85] hover:bg-[#71f1bf] hover:cursor-pointer w-[100%] text-[#464F56] py-2 rounded-full text-black",
          onClick: () => setPhoneNumberAttr()
        }, "Continue")
    )
  );
  
  const VerifyComponent2 = (
    <>
      <div className="absolute left-[35px] lg:left-[57px] top-[75px] lg:top-[160px] flex items-center">
        <div>
          <img src={backIcon} width={14} height={14} />
        </div>
        <button
          className="text-white text-base border-b ml-2"
          onClick={() => setProgress(0)}
        >
          Back
        </button>
      </div>
      <div className="pt-4 flex justify-center">
        <img src={infoIcon} />
      </div>
      <div className="text-xl text-white font-bold">
        This phone number is already in use
      </div>
      <div className="pt-4 text-[#979797] text-sm">
        It‘s linked to an account with the email {getHiddenmail(existPhone)}
      </div>
      <div className="pt-4 text-[#979797] text-sm">
        Do you recognise this email address?
      </div>
      <div className="pt-4">
        <button
          className="bg-[#2EBD85] hover:bg-[#71f1bf] w-[100%] text-black py-2 rounded-full text-black"
          onClick={() => setVerifyStatus(2)}
        >
          Yes, I recognise this email
        </button>
      </div>
      <div className="pt-4">
        <button
          className="bg-transparent hover:bg-[#0B1620] w-[100%] text-black py-2 rounded-full text-white border border-white"
          onClick={() => setVerifyStatus(0)}
        >
          No, I don‘t recognise this email
        </button>
      </div>
    </>
  );
  const VerifyComponent3 = (
    <>
      <div className="absolute  left-[50px] lg:left-[70px] top-[100px] lg:top-[160px] flex items-center">
        <div className="text-center">
          <img src={backIcon} width={14} height={14} />
        </div>
        <button
          className="text-white text-base border-b ml-2"
          onClick={() => setVerifyStatus(verifyStatus - 1)}
        >
          Back
        </button>
      </div>
      <div className="pt-4 flex justify-center">
        <img src={infoIcon} />
      </div>
      <div className="text-xl text-white font-bold">
        Do you still have access to this email address?
      </div>
      <div className="pt-4 text-[#979797] text-sm">{getHiddenmail(email)}</div>
      <div className="pt-4">
        <button
          className="bg-[#2EBD85] hover:bg-[#71f1bf] w-[100%] text-black py-2 rounded-full text-black"
          onClick={() => setVerifyStatus(3)}
        >
          Yes, I still have access to the email
        </button>
      </div>
      <div className="pt-4">
        <button
          className="bg-transparent hover:bg-[#0B1620] w-[100%] text-black py-2 rounded-full text-white border border-white"
          onClick={() => setVerifyStatus(1)}
        >
          No, I don‘t recognise this email
        </button>
      </div>
    </>
  );
  const VerifyComponent4 = (
    <>
      <div className="absolute left-[50px] lg:left-[70px] top-[100px] lg:top-[160px] flex items-center">
        <div className="text-center">
          <img src={backIcon} width={14} height={14} />
        </div>
        <button
          className="text-white text-base border-b ml-2"
          onClick={() => setVerifyStatus(verifyStatus - 1)}
        >
          Back
        </button>
      </div>
      <div className="pt-4 flex justify-center">
        <img src={infoIcon} />
      </div>
      <div className="text-xl text-white font-bold">
        Do you remember the password to this account?
      </div>
      <div className="pt-4 text-[#979797] text-sm">{getHiddenmail(email)}</div>
      <div className="pt-4">
        <button
          className="bg-[#2EBD85] hover:bg-[#71f1bf] w-[100%] text-black py-2 rounded-full text-black"
          onClick={() => {
            // setVerifyStatus(4);
            setVerifyStatus(1);
            navigate("/");
          }}
        >
          Yes, Let me log in
        </button>
      </div>
      <div className="pt-4">
        <button
          className="bg-transparent hover:bg-[#0B1620] w-[100%] text-black py-2 rounded-full text-white border border-white"
          onClick={() => navigate("/forgetpassword")}
        >
          No, I don‘t remember my password
        </button>
      </div>
    </>
  );

  const PhoneComponent = (
    <div className="text-center w-[360px] md:w-[45%] lg:w-[30%]">
      {verifyStatus == 0
        ? VerifyComponent1
        : verifyStatus == 1
        ? VerifyComponent2
        : verifyStatus == 2
        ? VerifyComponent3
        : VerifyComponent4}
    </div>
  );

  const updatePasswordHandle = async () => {
    try {
      await authContext.updatePassword(email, init_pwd, password);
      setProgress(5);
      setTimeout(() => {
        navigate("/");
      }, 5000);
    } catch (err) {
      // User creating occurs an error
      setRegisterError(err.message);
    }
  };
  

  const CreatePasswordComponent = (
    <div className="text-center w-[360px] md:w-[45%] lg:w-[30%]">
      <div className="absolute left-[35px] lg:left-[57px] top-[75px] lg:top-[160px] flex items-center">
        <div>
          <img src={backIcon} width={14} height={14} />
        </div>
        <button
          className="text-white text-base border-b ml-2"
          onClick={() => setProgress(progress - 1)}
        >
          Back
        </button>
      </div>
      <div className="text-2xl text-white font-bold pt-12">
        Create your password
      </div>
      <PasswordStrengh
        label={"Your password"}
        password={password}
        isShowPassword={isShowPassword}
        passwordIsValid={passwordIsValid}
        showStrength={true}
        setPassword={setPassword}
        setIsShowPassword={setIsShowPassword}
      />
      <div className="pt-4 text-[#979797] text-sm text-left">
        To have a strong password it must contain a letter, a number and be a
        minimum of 9 characters.
      </div>
      <PasswordStrengh
        label={"Confirm password"}
        password={passwordConfirm}
        isShowPassword={isShowPassword}
        passwordIsValid={passwordConfirmIsValid}
        showStrength={false}
        setPassword={setPasswordConfirm}
        setIsShowPassword={setIsShowPassword}
      />
      {registerError != "" ? (
        <div className="text-left text-red-500 py-2">{registerError}</div>
      ) : (
        <></>
      )}
      <div className="pt-4">
        <button
          id="continue_button"
          className="bg-[#2EBD85] hover:bg-[#71f1bf] w-[100%] text-black py-2 rounded-full text-black disabled:bg-[#E2E7ED] disabled:cursor-not-allowed"
          onClick={() => updatePasswordHandle()}
          disabled={password !== passwordConfirm || !passwordIsValid}
        >
          Continue
        </button>
      </div>
    </div>
  );

  const completeCreationProgress = () => {
    navigate("/");
  };

  // Complete user account creating
  const CompleteCreatingComponent = (
    <div className="bg-[#23341B] w-full h-screen flex justify-center">
      <div className="w-[100%] lg:w-9/12 p-2 lg:p-7">
        <div>
          <img src={Logo} />
        </div>
        <div className="flex flex-col text-center pt-12">
          <div className="flex justify-center">
            <img src={confirmIcon} />
          </div>
          <div className="text-white font-bold text-[40px] lg:text-[50px] leading-none">
            <div>You‘re registered</div>
            <div>with capiwise</div>
          </div>
          <div className="pt-2 text-sm text-[#A8CFBD]">
            <div>You can use these details to log back into Capiwise. </div>
            <div className="pt-2">
              We will redirect you automatically in 5 seconds.
            </div>
          </div>
          <div className="pt-6">
            <button
              className="bg-[#2EBD85] hover:bg-[#71f1bf] w-[360px] md:w-[45%] lg:w-[30%] text-black py-2 rounded-full text-black"
              onClick={() => completeCreationProgress()}
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );

  console.log("progress", progress);
  return (
    <div className="w-full h-screen">
      <ProgressAuthHeader mode={progress} />
      <div className="w-full flex justify-center">
        {progress === 0
          ? CreateAccountUserComponent
          : progress === 1
          ? VerifyComponent
          : progress === 2
          ? PrimaryResidenceComponent
          : progress === 3
          ? PhoneComponent
          : progress === 4
          ? CreatePasswordComponent
          : CompleteCreatingComponent}
      </div>
    </div>
  );
}
