import React, { useState, useEffect, useContext, FC } from "react";
import { useNavigate } from "react-router-dom";
import backImage from "assets/background.png";
import logo from "assets/logo.svg";
import facebookIcon from "assets/facebook_ico.svg";
import infoIcon from "assets/qus_ico.svg";
import { useValidPassword, useValidEmail } from "hooks/useAuthHooks";
import { Password, Email } from "components/authComponents";
import { AuthContext } from "contexts/authContext";
import GoogleSSO from "components/GoogleSSO";
import { Auth  } from 'aws-amplify';
import Amplify from 'aws-amplify' ;
import googleIcon from "assets/google_ico.svg";


Amplify.configure({
  Auth:{

    //required only for federation Authetication -Amazon Cognito Identity Pool ID
    identityPoolId : "eu-central-1:2e4d9059-fb14-4c03-b64c-b9954e2367ee",
  
    // Required amazon cognito region
    region: "eu-central-1",


// optional - amazon cognito federated Identity Pool Region
// Required only if it's different from Amazon Cognito Region
    identityPoolRegion : 'eu-central-1',

    // Optional - amazon Cognito user Pool Id
    userPoolId : "eu-central-1_QukrjlcsL",

    // optional - Amazon Cognito Web CLient Id

    userPoolWebClientId : '709aci6kf266pekbf54u1b5icm',
    oauth : {
      domain : 'cw-test.auth.eu-central-1.amazoncognito.com',
       scope : ["email","phone","openid"],
      redirectSignIn : 'https://dashboard.dev.capiwise.com/Home',
      // redirectSignOut : 'http://localhost:3000/',
      responseType : 'token',
      // options: {
      //   // Specify additional scopes here if needed
      //   scopes: ["https://www.googleapis.com/auth/calendar"],
      // },
      
    }
  }
});

export const LogIn = () => {
  const { email, setEmail, emailIsValid } = useValidEmail("");
  const { password, setPassword, passwordIsValid } = useValidPassword("");
  const [error, setError] = useState("");
  const [rememberMe, setRememberMe] = useState(false);

  const isValid =
    !emailIsValid ||
    email.length === 0 ||
    !passwordIsValid ||
    password.length === 0;

  const navigate = useNavigate();
  const authContext = useContext(AuthContext);

  useEffect(() => {
    const storedUseremail = localStorage.getItem("useremail");
    const storedPassword = localStorage.getItem("password");
    const storedRememberMe = localStorage.getItem("rememberme");
    const logOutStatus = sessionStorage.getItem("logOut");

    if (storedRememberMe && storedUseremail && storedPassword) {
        setEmail(storedUseremail);
        setPassword(storedPassword);
        setRememberMe(true);
    }
    if (
        logOutStatus !== "true" &&
        storedRememberMe &&
        storedUseremail &&
        storedPassword
    ) {
        authContext.signInWithEmail(storedUseremail, storedPassword, {
            mfaSetup: (challengeName, challengeParameters) => {
                // Handle MFA setup here
                console.log("Handle MFA setup here!");
            },
        });

        navigate("/");
    }
}, []);


  // const rememberMeClicked = () => {
  //   const storedRememberMe = localStorage.getItem("rememberme");
  //   if (storedRememberMe) {
  //     setRememberMe(false);
  //     localStorage.removeItem("rememberme");
  //     localStorage.removeItem("useremail");
  //   } else {
  //     setRememberMe(true);
     
  //     localStorage.setItem("rememberme", "true");
  //     localStorage.setItem("useremail", email);
  //     localStorage.setItem("password", password);
  //   }
  // };

  const rememberMeClicked = () => {
    const storedRememberMe = localStorage.getItem("rememberme");
    
    if (storedRememberMe) {
      setRememberMe(false);
      localStorage.removeItem("rememberme");
      localStorage.removeItem("userToken"); // Remove the user identifier
    } else {
      setRememberMe(true);
  
      // Generate a unique identifier or token for the user
      const userToken = generateUserToken(); // You need to implement this function
       console.log("....userToken....",userToken)
      localStorage.setItem("rememberme", "true");
      localStorage.setItem("userToken", userToken);
    }
  };
  
  // Function to generate a unique user token
  const generateUserToken = () => {
    // You can use a library like uuid or generate a unique identifier based on your requirements
    // For simplicity, let's use a random string as a token
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
  };
  
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
        signInClicked();
    }
};


  // const signInClicked = async () => {
  //   console.log("fffffffff");
  //   authContext
  //     .signInWithEmail(email, password)
  //     .then((res: any) => {
  //       navigate("/Home");
  //       localStorage.setItem("logedIn", "true");
  //       localStorage.setItem("autoSaved_email", email);
  //       localStorage.setItem("password", password);
  //       // Save to localStorage
  //       if (rememberMe) {
  //         localStorage.setItem("useremail", email);
  //         localStorage.setItem("rememberme", "true");
  //       } else {
  //         localStorage.removeItem("useremail");
  //         localStorage.removeItem("rememberme");
  //       }
  //     })
  //     .catch((err: any) => {
  //       if (err.code === "UserNotConfirmedException") {
  //         setError("You must verify your account with your email!");
  //       } else {
  //         setError(err.message);
  //       }
  //       navigate("/login");
  //     });
  // };


  
// const signInClicked = async () => {
//   console.log("fffffffff");
//   authContext
//     .signInWithEmail(email, password)
//     .then((res: any) => {
//       navigate("/Home");
//       localStorage.setItem("logedIn", "true");
//       localStorage.setItem("autoSaved_email", email);
//       // Save user token to localStorage
//       if (rememberMe) {
//         const userToken = localStorage.getItem("userToken");
//         if (userToken) {
//           localStorage.setItem("rememberme", "true");
//           localStorage.setItem("userToken", userToken);
//         }
//       } else {
//         localStorage.removeItem("rememberme");
//         localStorage.removeItem("userToken");
//       }
//     })
//     .catch((err: any) => {
//       if (err.code === "UserNotConfirmedException") {
//         setError("You must verify your account with your email!");
//       } else {
//         setError(err.message);
//       }
//       navigate("/login");
//     });
// };

const signInClicked = async () => {
  console.log("fffffffff");

  // Check if Remember Me is selected
  if (rememberMe) {
      const storedUserToken = localStorage.getItem("userToken");

      if (storedUserToken) {
          // Set the user token to fill the inputs
          setEmail(storedUserToken); // Change this line to the appropriate function to set the email
          setPassword(""); // Assuming you want to clear the password, update accordingly;

          // Navigate to the desired route
          navigate("/Home");

          // Update local storage
          localStorage.setItem("logedIn", "true");
          localStorage.setItem("autoSaved_email", storedUserToken);
          localStorage.setItem("rememberme", "true");

          return; // Exit the function to prevent further execution
      }
  }

  // If Remember Me is not selected or user token is not available, proceed with regular login
  authContext
      .signInWithEmail(email, password)
      .then((res) => {
          console.log("User successfully logged inn:", res);
          navigate("/Home");
          localStorage.setItem("logedIn", "true");
          localStorage.setItem("autoSaved_email", email);
          localStorage.setItem("idToken", res.idToken.jwtToken);
          // Save to localStorage
          if (rememberMe) {
              localStorage.setItem("rememberme", "true");
              localStorage.setItem("userToken", email);
          } else {
              localStorage.removeItem("rememberme");
              localStorage.removeItem("userToken");
          }
      })
      .catch((err) => {
          if (err.code === "UserNotConfirmedException") {
              setError("You must verify your account with your email!");
          } else {
              setError(err.message);
          }
          navigate("/");
      });
};


const handleLoginWithGoogle = async () => {
  // console.log("...hyyy2")
  try {
      // Initiate Google federated sign-in
     let response =  await Auth.federatedSignIn({ provider: 'Google' });
     console.log("response..",response);
            localStorage.setItem("logedIn", "true");
      // navigate('/Home')
      // Redirect to a specific page after successful sign-in
      // navigate("/dashboard"); // Replace "/dashboard" with the path to your desired destination
    } catch (error) {
      // Handle error if Google sign-in fails
      console.error("Google sign-in error:", error);
    }
  
}


  const passwordResetClicked = async () => {
    navigate(`/forgetpassword`, { state: { email: email } });
  };
  useEffect(() => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('autoSaved_email');
    localStorage.removeItem('password');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('socialAuthStatus');
    // localStorage.removeItem('userToken');
    // localStorage.removeItem('useremail')
     
    sessionStorage.clear();
  }, []);
  return (
    <div className="w-full h-screen flex">
      {/* Back screen */}
      <div className="relative w-0 lg:w-1/3 hidden lg:inline">
        <div className="relative">
          <div>
            <img src={backImage} className="w-full h-screen" />
          </div>
          <div className="absolute w-full h-screen top-0 mask1 z-10 opacity-80"></div>
        </div>
        <div className="absolute bottom-[64px] ml-6 z-20">
          <div className="font-black text-[42px]">INVEST WITH</div>
          <div className="font-black text-[42px]">CONFIDENCE</div>
          <div className="text-base">
            The tool you need to make your money work harder
          </div>
        </div>
      </div>
      {/* Main Config */}
      <div
        className="w-full lg:w-2/3 flex flex-col justify-between"
        onKeyDown={handleKeyPress}
      >
        <div className="flex justify-center">
          <div className="w-10/12 lg:w-2/5 text-center flex flex-col items-center">
            <div className="text-center py-14">
              <img src={logo} />
            </div>
            {/*  */}
            <div>
              <div className="font-bold text-2xl">Welcome back.</div>
              <div className="text-sm flex py-2">
                <div className="text-[#979797]">New to Capiwise? </div>
                <div className="mx-1 border-b">
                  {/* Sign Up */}
                  <p
                    className="text-center cursor-pointer"
                    onClick={() => navigate("/register")}
                  >
                    Sign up
                  </p>
                </div>
              </div>
            </div>
            {/*  */}
            <div className="w-full">
              <div className="text-left">
                <div className="text-sm text-[#979797]">Your email address</div>
                <div className="border-[#979797] rounded-md h-9 mt-1">
                  <Email
                    emailIsValid={emailIsValid}
                    email={email}
                    setEmail={setEmail}
                  />
                </div>
              </div>
              <div className="text-left mt-6">
                <div className="text-sm text-[#979797]">Your password</div>
                <div className="border-[#979797] rounded-md h-9 mt-1">
                  <Password
                    label="Password"
                    passwordIsValid={passwordIsValid}
                    password={password}
                    setPassword={setPassword}
                  />
                </div>
              </div>
              {error != "" ? (
                <div className="pt-3 text-left text-red-500">{error}</div>
              ) : (
                <></>
              )}
              <div className="rounded-full h-9 mt-4">
                <button
                  className="w-full bg-[#2EBD85] hover:bg-[#71f1bf] rounded-full p-2 text-[#040B11] text-sm font-medium"
                  disabled={isValid}
                  onClick={() => signInClicked()}
                >
                  Sign In
                </button>
              </div>
            </div>
            {/*  */}
            <div className="w-full flex justify-between mt-8 items-center">
              <div className="flex items-center">
                <div
                  className="w-4 h-4 border-[1px] border-[#979797] bg-transparent flex flex-col rounded-[2px]"
                  onClick={rememberMeClicked}
                >
                  {rememberMe ? (
                    <div className="bg-[#0053AA] w-full h-full"></div>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="flex items-center">
                  <div className="text-sm text-[#979797] px-2">Remember me</div>
                  <div className="pt-1">
                    <img src={infoIcon} />
                  </div>
                </div>
              </div>
              <div
                className="flex justify-start items-center"
                onClick={passwordResetClicked}
              >
                <span className="text-[#979797] text-sm cursor-pointer hover:underline">
                  Forgot Password?
                </span>
              </div>
            </div>
            {/*  */}
            <div className="w-full mt-6">
              <div className="text-left text-sm text-[#979797]">
                Or log in with
              </div>
              <div className="flex item-start py-6">
                <div className="w-1/2 px-1">
                  {/* <GoogleSSO setError={setError} /> */}
                  {/* <button onClick={handleLoginWithGoogle}>Log in with google</button> */}
                  <div
      className="rounded-full bg-transparent hover:bg-[#0053AA33] hover:cursor-pointer border-[#979797] border flex justify-center py-2"
      onClick={() => handleLoginWithGoogle()}
    >
      <img src={googleIcon} className="w-5 h-5" alt="Google Icon" />
    </div>
                </div>
                <div className="w-1/2 px-1">
                  <div
                    className="rounded-full bg-transparent hover:bg-[#0053AA33] hover:cursor-pointer border-[#979797] border flex justify-center py-2"
                    // onClick={() => googleLoginHandle()}
                  >
                    <img src={facebookIcon} className="w-5.5 h-5.5" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="text-center bg-[#0B1620] w-full py-2">
          <div className="text-sm">© Capiwise 2023</div>
        </div>
      </div>
    </div>
  );
};
