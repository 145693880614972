import { FC } from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

type SearchBoxProps = {
  disabled: boolean;
};

const SearchBox: FC<SearchBoxProps> = ({ disabled }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [searchBoxFlag, setSearchBoxFlag] = useState(false);

  const navigate = useNavigate();

  const baseURI = process.env.REACT_APP_API_BASEURI;
  // localStorage.setItem("idToken",res.idToken.jwtToken
  // )
  const idToken  =  localStorage.getItem('idToken')
  console.log("...idToken...",idToken)

  useEffect(() => {
    if (searchTerm === "") setShowDropdown(false);
    else startSearch();
  }, [searchTerm]);

  const handleSelect = (option: any) => {
    setSearchTerm("");
    setShowDropdown(false);

    if (option.instrument_type == "Common Stock")
      navigate("/stocksummary", {
        state: {
          item: option,
        },
      });
    else
      navigate("/etfsummary", {
        state: {
          item: option,
        },
      });
    window.location.reload();
  };

  const startSearch = async () => {
    const fetchURL = `${baseURI}stockSearch?ticker=${searchTerm}&limit=3`;

    fetch(fetchURL,{
      headers: {
        Authorization: `Bearer ${idToken}`,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.length > 0) {
          setFilteredOptions(data);
          setShowDropdown(true);
        } else {
          setFilteredOptions([]);
          // setShowDropdown(false);
        }
      })
      .catch((error) => console.log(error));
  };

  const handleSearchFocus = () => {
    if (searchTerm != "" && filteredOptions.length > 0) {
      setShowDropdown(true);
      document.getElementById("search_box")?.classList.remove("bg-transparent");
      if (
        !document
          .getElementById("search_box")
          ?.classList.contains("bg-[#0B1620]")
      ) {
        document.getElementById("search_box")?.classList.add("bg-[#0B1620]");
      }
    }
  };

  const handleSearchBlur = (e: any) => {
    setShowDropdown(false);
    document.getElementById("search_box")?.classList.add("bg-transparent");
    document.getElementById("search_box")?.classList.remove("bg-[#0B1620]");
  };

  const clearSearchBox = () => {
    setSearchTerm("");
    setShowDropdown(false);
  };

  const handleKeyDown = (e: any) => {
    if (e.key == "Enter" && searchTerm != "") startSearch();
  };

  return (
    <div className="grow flex items-center justify-center">
      <div
        className={
          "relative rounded-full w-11/12 md:w-9/12 lg:w-7/12 " +
          (searchTerm == "" ? "border-2 border-[#979797] " : "")
        }
      >
        <input
          className={
            "py-3 text-base text-white focus:outline-none rounded-full border-[#979797] w-full pl-5 pr-20 " +
            (searchTerm != "" ? "bg-[#0B1620]" : "bg-transparent")
          }
          style={{
            borderRadius:
              searchTerm == "" || filteredOptions.length == 0
                ? ""
                : "12px 12px 0px 0px",
          }}
          id="search_box"
          placeholder="Search Symbols or Company Name"
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value);
          }}
          // disabled={disabled}
          onFocus={() => handleSearchFocus()}
          // onBlur={(e) => handleSearchBlur(e)}
          onKeyDown={(e) => {
            handleKeyDown(e);
          }}
        />
        <span className="absolute inset-y-0 right-0 flex items-center pl-2">
          {searchTerm !== "" ? (
            <button
              className="text-white hover:text-[#F35530]"
              onClick={() => clearSearchBox()}
            >
              X
            </button>
          ) : (
            <></>
          )}

          <button
            className="p-1 ml-2 pr-5 focus:outline-none focus:shadow-outline"
            onClick={(e) => startSearch()}
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.2124 14.5735L11.4044 10.6129C12.3835 9.44901 12.92 7.98455 12.92 6.45999C12.92 2.89801 10.022 0 6.45999 0C2.89801 0 0 2.89801 0 6.45999C0 10.022 2.89801 12.92 6.45999 12.92C7.79721 12.92 9.07152 12.5167 10.161 11.751L13.998 15.7416C14.1583 15.9082 14.374 16 14.6052 16C14.824 16 15.0316 15.9166 15.1891 15.7649C15.5239 15.4428 15.5346 14.9085 15.2124 14.5735ZM6.45999 1.68522C9.09286 1.68522 11.2348 3.82713 11.2348 6.45999C11.2348 9.09286 9.09286 11.2348 6.45999 11.2348C3.82712 11.2348 1.68522 9.09286 1.68522 6.45999C1.68522 3.82713 3.82712 1.68522 6.45999 1.68522Z"
                fill="white"
              />
            </svg>
          </button>
        </span>

        {showDropdown && (
          <div
            className={
              "absolute z-10 w-full px-1 border-t-2 border-t-[#252A2D] bg-[#0B1620] rounded-md shadow-lg overflow-auto scrollbar-hide " +
              (filteredOptions.length >= 3
                ? "h-48"
                : filteredOptions.length == 2
                ? "h-36"
                : "h-24")
            }
            id="symbol-list"
          >
            <div className="text-xl text-left px-3 py-2">Symbols</div>
            <ul>
              {filteredOptions.length > 0 ? (
                filteredOptions.map((option: any) => (
                  <li
                    key={
                      option.Code + option.Name + option.Type + option.Exchange
                    }
                    onClick={() => handleSelect(option)}
                    className="p-3 cursor-pointer hover:bg-[#040B11]"
                  >
                    <div className="flex text-sm w-full items-center">
                      <div className="w-3/12 text-left font-bold">
                        {option.symbol}
                      </div>
                      <div className="w-7/12 text-left text-xs font-bold">
                        {option.instrument_name}
                      </div>
                      <div className="w-2/12 text-right text-xs">
                        {(option.instrument_type === "Common Stock"
                          ? "Equity"
                          : option.instrument_type) +
                          " - " +
                          option.mic_code}
                      </div>
                    </div>
                  </li>
                ))
              ) : (
                <div className="text-sm w-full p-3 text-left">No results!</div>
              )}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchBox;
