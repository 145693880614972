import { FC } from "react";
import SearchBox from "components/SearchBox";
type SearchStockProps = {
  disabled: boolean;
};

const SearchStock: FC<SearchStockProps> = ({ disabled }) => {
  return (
    <div className="text-center w-[100%]">
      <p className="text-5xl font-bold mt-[50px] lg:mt-0 font-roboto">
        Capiwise
      </p>
      <p className="text-2xl mt-6 mb-11 mx-3 font-roboto">
        Search over 150k stocks in 71+ markets
      </p>
      <SearchBox disabled={disabled} />
    </div>
  );
};

export default SearchStock;
