import { FC, useState, useEffect, useContext } from "react";

import AuthHeader from "components/AuthHeader";
import { AuthContext } from "contexts/authContext";
import { useNavigate } from "react-router-dom";
import backLogo from "assets/back_ico.svg";
import { useValidPassword } from "hooks/useAuthHooks";
import { PasswordStrengh } from "components/authComponents";

const ChangePwd: FC = () => {
  const {
    password: oldPassword,
    setPassword: setOldPassword,
    passwordIsValid: oldPassowrdIsValid,
  } = useValidPassword("");
  const {
    password: newPassword,
    setPassword: setNewPassword,
    passwordIsValid: newPassowrdIsValid,
  } = useValidPassword("");
  const {
    password: passwordConfirm,
    setPassword: setPasswordConfirm,
    passwordIsValid: passwordConfirmIsValid,
  } = useValidPassword("");
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [changeError, setChangeError] = useState("");

  const [passwordSet, setPasswordSet] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const auth = useContext(AuthContext);
  const navigate = useNavigate();
  const passwordChanged = async () => {
    try {
      await auth.changePassword(oldPassword, newPassword);
      navigate("/settings/password/successpasswordchange");
    } catch (err: any) {
      setChangeError(err.message);
    }
  };

  const clickedBack = () => {
    navigate("/settings");
  };

  return (
    <div>
      <AuthHeader mode={4} />
      <div
        onClick={clickedBack}
        className="w-1/6 absolute left-[35px] lg:left-[57px] top-[75px] lg:top-[160px] flex hover:cursor-pointer"
      >
        <img className="mr-2" src={backLogo} /> Back
      </div>
      <div className="w-full flex justify-center">
        <div className="w-11/12 lg:w-4/12 p-7">
          <div className="flex justify-center items-center">
            <div className="w-full flex-col mt-10">
              <div className="text-xl">Change Password</div>
              <div className="mt-10">
                <div className="text-sm text-[#979797]">Current password</div>
                <PasswordStrengh
                  password={oldPassword}
                  isShowPassword={isShowPassword}
                  passwordIsValid={oldPassowrdIsValid}
                  showStrength={false}
                  setPassword={setOldPassword}
                  setIsShowPassword={setIsShowPassword}
                />
              </div>
              <div className="mt-10">
                <div className="text-sm text-[#2EBD85]">New Password</div>
                <PasswordStrengh
                  password={newPassword}
                  isShowPassword={isShowPassword}
                  passwordIsValid={newPassowrdIsValid}
                  showStrength={true}
                  setPassword={setNewPassword}
                  setIsShowPassword={setIsShowPassword}
                />
              </div>
              <div className="mt-10">
                <div className="text-sm text-[#2EBD85]">
                  Confirm new Password
                </div>
                <PasswordStrengh
                  password={passwordConfirm}
                  isShowPassword={isShowPassword}
                  passwordIsValid={passwordConfirmIsValid}
                  showStrength={false}
                  setPassword={setPasswordConfirm}
                  setIsShowPassword={setIsShowPassword}
                />
              </div>
              {changeError != "" ? (
                <div className="text-left text-red-500 py-2">{changeError}</div>
              ) : (
                <></>
              )}
              {passwordSet.newPassword == passwordSet.confirmPassword ? (
                <div
                  onClick={passwordChanged}
                  className="text-center mt-10 text-black w-[120px] lg:w-[22%] rounded-full bg-[#2EBD85] hover:bg-[#71f1bf] p-2 text-xs m-3 hover:cursor-pointer"
                >
                  Save
                </div>
              ) : (
                <div className="text-center mt-10 text-black w-[120px] lg:w-[22%] rounded-full bg-[#E2E7ED] p-2 text-xs m-3 hover:cursor-not-allowed">
                  Save
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePwd;
