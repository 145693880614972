import React, { FC, useEffect, useState, useContext } from "react";

import { Link, useNavigate } from "react-router-dom";
import accountLogo from "../assets/account_ico.svg";
import settingsLogo from "../assets/settings_ico.svg";
import helpLogo from "../assets/help_ico.svg";
import logoutLogo from "../assets/logout_ico.svg";
import { AuthContext } from "contexts/authContext";

interface ProfileManageInterface {
  disable: boolean;
  setDisable: (_: boolean) => void;
  givenname: string | undefined;
  familyname: string | undefined;
}

const profileManage: FC<ProfileManageInterface> = ({
  disable,
  setDisable,
  givenname,
  familyname,
}) => {
  // const [name, setName] = useState("");
  // const [email, setEmail] = useState("");

  const navigatge = useNavigate();
  const auth = useContext(AuthContext);

  // useEffect(() => {
  //   const storedEmail: any = localStorage.getItem("autoSaved_email")
  //     ? localStorage.getItem("autoSaved_email")
  //     : localStorage.getItem("login_with_google")
  //     ? localStorage.getItem("login_with_google")
  //     : " ";
  //   setEmail(storedEmail);
  //   const name = storedEmail.split("@", 1);
  //   setName(name[0]);
  // }, []);

  async function logOutClicked() {
    // await auth.signOut();
    // window.localStorage.setItem("socialAuthStatus", "false");
    // sessionStorage.setItem("logOut", "true");
     localStorage.setItem('logedIn',"false")
    console.log("....it should be false ")
    navigatge("/");
    // window.location.reload();
  }

  return (
    <div>
      {disable == false ? (
        <div className="w-64 h-72 text-sm font-medium bg-[#0B1620] rounded-lg absolute top-0 right-0 my-16 z-50">
          <div className="block w-full px-4 py-2 border-b border-[#000000]">
            <span className="block w-full px-4 py-2 text-white text-lg">
              {`${givenname} ${familyname}`}
            </span>
            <span className="block w-full px-4 py-2 text-[#979797] text-sm">
              Account number CP9442286
             
            </span>
          </div>
          <Link
            to="/manageaccount"
            className="block w-full px-4 py-2 cursor-pointer hover:bg-[#040B11] focus:outline-none focus:ring-2 focus:ring-blue-700 text-[#979797] "
          >
            <div className="flex items-center" onClick={() => setDisable(true)}>
              <img src={accountLogo} className="p-2" />
              <p>Account</p>
            </div>
          </Link>
          <Link
            to="/settings"
            className="block w-full px-4 py-2 cursor-pointer hover:bg-[#040B11] focus:outline-none focus:ring-2 focus:ring-blue-700 text-[#979797] "
          >
            <div className="flex items-center" onClick={() => setDisable(true)}>
              <img src={settingsLogo} className="p-2" />
              <p>Settings</p>
            </div>
          </Link>
          <Link
            to="/"
            className="block w-full px-4 py-2 cursor-pointer hover:bg-[#040B11] focus:outline-none focus:ring-2 focus:ring-blue-700 text-[#979797] "
          >
            <div className="flex items-center" onClick={() => setDisable(true)}>
              <img src={helpLogo} className="p-2" />
              <p>Help Centre</p>
            </div>
          </Link>
          <Link
            to="/"
            className="block w-full px-4 py-2 cursor-pointer hover:bg-[#040B11] focus:outline-none focus:ring-2 focus:ring-blue-700 text-[#979797] "
          >
            <div className="flex items-center" onClick={logOutClicked}>
              <img src={logoutLogo} className="p-2" />
              <p>Log Out</p>
            </div>
          </Link>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default profileManage;
