import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  useValidEmail,
  useValidPassword,
  useValidCode,
} from "hooks/useAuthHooks";
import { Email, PasswordStrengh, Code } from "components/authComponents";
import { AuthContext } from "contexts/authContext";
import AuthHeader from "components/AuthHeader";
import Key from "assets/key.svg";
import backIcon from "assets/back_ico.svg";
import confirmIcon from "assets/confirm_ico.svg";
import Logo from "assets/logo.svg";

export default function ResetPassword() {
  const [progress, setProgress] = useState(0);
  const { password, setPassword, passwordIsValid } = useValidPassword("");
  const {
    password: passwordConfirm,
    setPassword: setPasswordConfirm,
    passwordIsValid: passwordConfirmIsValid,
  } = useValidPassword("");
  const [isShowPassword, setIsShowPassword] = useState(false);

  const { email, setEmail, emailIsValid } = useValidEmail("");
  const [resetError, setResetError] = useState("");
  const { code, setCode, codeIsValid } = useValidCode("");

  const navigate = useNavigate();

  const authContext = useContext(AuthContext);

  const sendPasswordResetCode = async () => {
    try {
      await authContext.sendCode(email);
      setProgress(1);
    } catch (err: any) {
      setResetError(err.message);
    }
  };

  const ResetPasswordComponent = (
    <div className="text-center w-[360px] lg:w-[430px]">
      <div className="flex justify-center pt-6">
        <img src={Key} />
      </div>
      <div className="text-2xl text-white font-bold pt-11">Reset password</div>
      <div className="text-sm text-[#979797] pt-3.5">
        Just enter the email address you registered with and we‘ll send you a
        verification code to reset your password.
      </div>
      <div className="pt-4">
        <div className="text-sm text-left text-[#979797]">
          Enter your email address
        </div>
        <div className="border-[#979797] rounded-md h-9 mt-1">
          <Email
            emailIsValid={emailIsValid}
            email={email}
            setEmail={setEmail}
          />
        </div>
      </div>
      {resetError != "" ? (
        <div className="text-left text-red-500 py-2">{resetError}</div>
      ) : (
        <></>
      )}
      <div className="pt-4">
        <button
          className="bg-[#2EBD85] hover:bg-[#71f1bf] w-[100%] text-black py-2 rounded-full text-black disabled:bg-[#E2E7ED] disabled:cursor-not-allowed font-medium"
          disabled={!emailIsValid || email.length === 0}
          onClick={() => sendPasswordResetCode()}
        >
          Send password reset code
        </button>
      </div>
    </div>
  );

  const updatePasswordHandle = async () => {
    try {
      await authContext.forgotPassword(email, code, password);
      setProgress(2);
      setTimeout(() => {
        navigate("/");
      }, 5000);
    } catch (err: any) {
      setResetError(err.message);
    }
  };

  const CreatePasswordComponent = (
    <div className="text-center w-[360px] lg:w-[430px]">
      <div className="absolute left-[35px] lg:left-[57px] top-[75px] lg:top-[160px] flex items-center">
        <div>
          <img src={backIcon} width={14} height={14} />
        </div>
        <button
          className="text-white text-base border-b ml-2"
          onClick={() => setProgress(progress - 1)}
        >
          Back
        </button>
      </div>
      <div className="text-2xl text-white font-bold pt-12">
        Reset Your Password
      </div>
      <Code
        label="Verification code"
        codeIsValid={codeIsValid}
        setCode={setCode}
      />
      <PasswordStrengh
        label={"Your password"}
        password={password}
        isShowPassword={isShowPassword}
        passwordIsValid={passwordIsValid}
        showStrength={true}
        setPassword={setPassword}
        setIsShowPassword={setIsShowPassword}
      />
      <div className="pt-4 text-[#979797] text-sm text-left">
        To have a strong password it must contain a letter, a number and be a
        minimum of 9 characters.
      </div>
      <PasswordStrengh
        label={"Confirm password"}
        password={passwordConfirm}
        isShowPassword={isShowPassword}
        passwordIsValid={passwordConfirmIsValid}
        showStrength={false}
        setPassword={setPasswordConfirm}
        setIsShowPassword={setIsShowPassword}
      />
      {resetError != "" ? (
        <div className="text-left text-red-500 py-2">{resetError}</div>
      ) : (
        <></>
      )}
      <div className="pt-4">
        <button
          id="continue_button"
          className="bg-[#2EBD85] hover:bg-[#71f1bf] w-[100%] text-black py-2 rounded-full text-black disabled:bg-[#E2E7ED] disabled:cursor-not-allowed font-medium"
          onClick={() => updatePasswordHandle()}
          disabled={password !== passwordConfirm || !passwordIsValid}
        >
          Continue
        </button>
      </div>
    </div>
  );
  const completeCreationProgress = () => {
    navigate("/");
  };

  // Complete user account creating
  const CompleteCreatingComponent = (
    <div className="bg-[#23341B] w-full h-screen flex justify-center">
      <div className="w-[100%] lg:w-9/12 p-2 lg:p-7">
        <div>
          <img src={Logo} />
        </div>
        <div className="flex flex-col text-center pt-12">
          <div className="flex justify-center">
            <img src={confirmIcon} />
          </div>
          <div className="text-white font-bold text-[40px] lg:text-[50px] leading-none">
            <div>You reset password</div>
            <div>with capiwise</div>
          </div>
          <div className="pt-2 text-sm text-[#A8CFBD]">
            <div>You can use these details to log back into Capiwise. </div>
            <div className="pt-2">
              We will redirect you automatically in 5 seconds.
            </div>
          </div>
          <div className="pt-6">
            <button
              className="bg-[#2EBD85] hover:bg-[#71f1bf] w-[100%] text-black py-2 rounded-full text-black"
              onClick={() => completeCreationProgress()}
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <AuthHeader mode={2} />
      <div className="w-full flex justify-center">
        {progress === 0
          ? ResetPasswordComponent
          : progress === 1
          ? CreatePasswordComponent
          : CompleteCreatingComponent}
      </div>
    </>
  );
}
