import React, { useState, useEffect, useContext } from "react";
import * as cognito from "../libs/cognito";

export enum AuthStatus {
  Loading,
  SignedIn,
  SignedOut,
}

export interface AttrInfo {
  Name: string;
  Value: string;
}

export interface IAuth {
  sessionInfo?: {
    username?: string;
    email?: string;
    sub?: string;
    accessToken?: string;
    refreshToken?: string;
  };
  attrInfo?: any;
  setAttrInfo?: any;
  authStatus?: AuthStatus;
  signInWithEmail?: any;
  signUpWithEmail?: any;
  signOut?: any;
  signOutAll?: any;
  verifyCode?: any;
  getSession?: any;
  sendCode?: any;
  forgotPassword?: any;
  changePassword?: any;
  getAttributes?: any;
  setAttribute?: any;
  setAttributes?: any;
  checkPhoneNumber?: any;
  setSpecificAttribute?: any;
  updatePassword?: any;
  closeAccount?: any;
  changeEmail?: any;
  handleGoogleLogin?: any;
  setAuthStatus?: any;
}

const defaultState: IAuth = {
  sessionInfo: {},
  authStatus: AuthStatus.Loading,
};

type Props = {
  children?: React.ReactNode;
};

/*eslint no-useless-catch: "error"*/

export const AuthContext = React.createContext(defaultState);

export const AuthIsSignedIn = ({ children }: Props) => {
  const { authStatus }: IAuth = useContext(AuthContext);

  return <>{authStatus === AuthStatus.SignedIn ? children : null}</>;
};

export const AuthIsNotSignedIn = ({ children }: Props) => {
  const { authStatus }: IAuth = useContext(AuthContext);

  return <>{authStatus === AuthStatus.SignedOut ? children : null}</>;
};

const AuthProvider = ({ children }: Props) => {
  const [authStatus, setAuthStatus] = useState(AuthStatus.Loading);
  const [sessionInfo, setSessionInfo] = useState({});
  const [attrInfo, setAttrInfo] = useState([]);

  // useEffect(() => {
  //   async function getSessionInfo() {
  //     try {
  //       const session: any = await getSession();
  //       const current = Date.now();
  //       if (
  //         Math.floor(current / 1000) <=
  //         session?.idToken?.payload?.exp - 300
  //       ) {
  //         setSessionInfo({
  //           accessToken: session.accessToken.jwtToken,
  //           refreshToken: session.refreshToken.token,
  //           idToken: session.idToken.jwtToken,
  //           exp: session.idToken.payload.exp,
  //           username: session.idToken.payload["cognito:username"],
  //           email: session?.idToken?.payload?.email,
  //         });
  //         window.localStorage.setItem(
  //           "idToken",
  //           `${session.idToken.jwtToken}`
  //         );
  //         window.localStorage.setItem(
  //           "accessToken",
  //           `${session.accessToken.jwtToken}`
  //         );
  //         window.localStorage.setItem(
  //           "refreshToken",
  //           `${session.refreshToken.token}`
  //         );
  //         await setAttribute({
  //           Name: "website",
  //           Value: "https://capiwise.com",
  //         });
  //         const attr: any = await getAttributes();
  //         setAttrInfo(attr);
  //         setAuthStatus(AuthStatus.SignedIn);
  //       } else {
  //         cognito.signOut();
  //         setAuthStatus(AuthStatus.SignedOut);
  //       }
  //     } catch (err) {
  //       setAuthStatus(AuthStatus.SignedOut);
  //     }
  //   }
  //   getSessionInfo();
  // }, [setAuthStatus, authStatus]);


  useEffect(() => {
    async function getSessionInfo() {
      try {
        const session: any = await getSession();
        const current = Date.now();
        if (
          Math.floor(current / 1000) <=
          session?.idToken?.payload?.exp - 300
        ) {
          setSessionInfo({
            accessToken: session.accessToken.jwtToken,
            refreshToken: session.refreshToken.token,
            idToken: session.idToken.jwtToken,
            exp: session.idToken.payload.exp,
            username: session.idToken.payload["cognito:username"],
            email: session?.idToken?.payload?.email,
          });
          window.localStorage.setItem("idToken", `${session.idToken.jwtToken}`);
          window.localStorage.setItem("accessToken", `${session.accessToken.jwtToken}`);
          window.localStorage.setItem("refreshToken", `${session.refreshToken.token}`);
          await setAttribute({
            Name: "website",
            Value: "https://capiwise.com",
          });
          const attr: any = await getAttributes();
          setAttrInfo(attr);
          setAuthStatus(AuthStatus.SignedIn);
        } else {
          cognito.signOut();
          setAuthStatus(AuthStatus.SignedOut);
        }
      } catch (err) {
        setAuthStatus(AuthStatus.SignedOut);
      }
    }
  
    getSessionInfo();
  }, [setAuthStatus, authStatus]);
  
  if (authStatus === AuthStatus.Loading) {
    return null;
  }

  async function signInWithEmail(email: string, password: string) {
    return new Promise(function (resolve, reject) {
      cognito
        .signInWithEmail(email, password)
        .then((res) => {
          setAuthStatus(AuthStatus.SignedIn);
          resolve(res);
        })
        .catch((err) => {
          setAuthStatus(AuthStatus.SignedOut);
          reject(err);
        });
    });
  }

  async function signUpWithEmail(
    email: string,
    firstname: string,
    lastname: string,
    password: string
  ) {
    await cognito.signUpUserWithEmail(email, firstname, lastname, password);
  }

  async function changeEmail(
    email: string,
    attrs: [AttrInfo],
    password: string
  ) {
    await cognito.signUpwithNewEmail(email, attrs, password);
  }

  function signOut() {
    cognito.signOut();
    setAuthStatus(AuthStatus.SignedOut);
  }

  async function signOutAll() {
    return new Promise(function (resolve, reject) {
      cognito
        .globalSignout()
        .then((res) => {
          setAuthStatus(AuthStatus.SignedOut);
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function closeAccount(username: string, password: string) {
    return new Promise(function (resolve, reject) {
      cognito
        .deleteUser(username, password)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function verifyCode(email: string, code: string) {
    await cognito.verifyCode(email, code);
  }

  async function checkPhoneNumber(phonenumber: string) {
    const phoneExisting = await cognito.checkPhoneNumber(phonenumber);
    return phoneExisting;
  }

  async function getSession() {
    const session = await cognito.getSession();
    return session;
  }

  async function getAttributes() {
    const attr = await cognito.getAttributes();
    return attr;
  }

  async function setAttribute(attr: any) {
    const res = await cognito.setAttribute(attr);
    return res;
  }

  async function setAttributes(attrs: [AttrInfo]) {
    const res = await cognito.setAttributes(attrs);
    return res;
  }

  async function setSpecificAttribute(
    email: string,
    password: string,
    attr: any
  ) {
    const res = await cognito.setSpecificAttribute(email, password, attr);
    return res;
  }

  async function sendCode(email: string) {
    await cognito.sendCode(email);
  }

  async function forgotPassword(email: string, code: string, password: string) {
    await cognito.forgotPassword(email, code, password);
  }

  async function changePassword(oldPassword: string, newPassword: string) {
    await cognito.changePassword(oldPassword, newPassword);
  }

  async function updatePassword(
    email: string,
    oldPassword: string,
    newPassword: string
  ) {
    await cognito.updatePassword(email, oldPassword, newPassword);
  }

  async function handleGoogleLogin(
    email: string,
    password: string,
    attributes: [AttrInfo]
  ) {
    return new Promise(function (resolve, reject) {
      cognito
        .addUser(email, password, attributes)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  const state: IAuth = {
    authStatus,
    sessionInfo,
    attrInfo,
    setAuthStatus,
    setAttrInfo,
    signUpWithEmail,
    signInWithEmail,
    signOut,
    signOutAll,
    verifyCode,
    getSession,
    sendCode,
    forgotPassword,
    changePassword,
    getAttributes,
    setAttribute,
    checkPhoneNumber,
    setSpecificAttribute,
    updatePassword,
    setAttributes,
    closeAccount,
    changeEmail,
    handleGoogleLogin,
  };

  return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
