
import React from "react";
import PropTypes from "prop-types";
import { Navigate, Outlet } from "react-router-dom";

 
const ProtectedRoute = ({logedIn}) => {

    const logedIn1 =  localStorage.getItem("logedIn")
    console.log("....logedIn...protected...",logedIn1)
    if(logedIn1 == "true"){
     
        return <Outlet/>
    }else{
     
      return  <Navigate to = {"/"} />
    }

}
ProtectedRoute.propTypes = {
  logedIn: PropTypes.bool, // Add this line to define the prop type
};

export default ProtectedRoute;

