import { useContext, useState, useEffect } from "react";
import AuthHeader from "components/AuthHeader";
import DropdownBox from "subtools/DropdownBox";
import { AuthContext } from "contexts/authContext";
import { AttrToFind } from "subtools/AttrToFind";
import { useNavigate } from "react-router-dom";
import { UpdateAttrInfo } from "subtools/UpdateAttrInfo";
import { LoadingSpinner } from "components/loadingSpinner";

interface AccountInfo {
  givenname?: string;
  familyname?: string;
  dob?: string;
  callingindex?: string;
  phoneNumber?: string;
  country?: string;
  city?: string;
  address?: string;
  postalCode?: string;
  accountNumber?: string;
  birthdate?: string;
  birthmonth?: string;
  birthyear?: string;
  picture?: string;
}

export const ManageAccount = () => {
  const auth = useContext(AuthContext);
  const navigatge = useNavigate();
  const [editMode, setEditMode] = useState(false);
  const [accountInfo, setAccountInfo] = useState<AccountInfo>({
    birthmonth: "Jan",
  });
  const [countryFlagsList, setCountryFlagsList] = useState([]);
  const [loading, setLoading] = useState(false);

  const [countryNameList, setCountryNameList] = useState([]);
  const [callingCodesList, setCallingCodesList] = useState([]);

  // const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const birthmonthArr = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const handleDataUpdate = (updatedData: string, updatedType: string) => {
    setAccountInfo((prevData: any) => ({
      ...prevData,
      [updatedType]: updatedData,
    }));
  };

  // const handleDatePicker = (date: Date | null) => {
  //   setSelectedDate(date);
  //   setAccountInfo({
  //     ...accountInfo,
  //     dob: date?.toLocaleDateString("en-GB"),
  //   });
  // };

  const onChange = (e: any) => {
    setAccountInfo((prevdata) => ({
      ...prevdata,
      [e.target.name]: e.target.value,
    }));
  };

  const saveUpdateData = async () => {
    setLoading(true);
    setAccountInfo(accountInfo);
    const bthMonth = accountInfo?.birthmonth
      ? birthmonthArr.indexOf(accountInfo?.birthmonth) + 1
      : -1;
    const finalUpdate = [
      {
        Name: "given_name",
        Value: accountInfo?.givenname,
      },
      {
        Name: "family_name",
        Value: accountInfo?.familyname,
      },
      {
        Name: "locale",
        Value: accountInfo?.callingindex,
      },
      {
        Name: "phone_number",
        Value: `${accountInfo?.callingindex?.split(
          ":"
        )[0]}${accountInfo?.phoneNumber}`,
      },
      {
        Name: "address",
        Value: `${accountInfo?.country}${
          accountInfo?.city ? ", " + accountInfo?.city : ""
        }${accountInfo?.address ? ", " + accountInfo?.address : ""}${
          accountInfo?.postalCode ? ", " + accountInfo?.postalCode : ""
        }`,
      },
      {
        Name: "birthdate",
        Value:
          accountInfo?.birthdate &&
          accountInfo?.birthmonth &&
          accountInfo?.birthyear
            ? `${
                parseInt(accountInfo?.birthdate) > 9
                  ? accountInfo?.birthdate
                  : "0" + accountInfo?.birthdate
              }-${
                bthMonth > 9 ? bthMonth.toString() : "0" + bthMonth.toString()
              }-${accountInfo?.birthyear}`
            : undefined,
      },
    ];

    try {
      await auth.setAttributes(finalUpdate);
      if (accountInfo?.givenname) {
        await UpdateAttrInfo(auth, "given_name", accountInfo?.givenname);
      }

      if (accountInfo?.familyname) {
        await UpdateAttrInfo(auth, "family_name", accountInfo?.familyname);
      }
      setEditMode(false);
      setLoading(false);
    } catch (err: any) {
      console.log(err.message);
      setLoading(false);
    }
  };

  const getCountryNames = async () => {
    try {
      // Make a GET request to the REST Countries API
      const response = await fetch("https://restcountries.com/v2/all");

      // Parse the response data as JSON
      const data = await response.json();

      // Extract the country names from the response data
      const countryNames = data.map((country: any) => country.name);
      const callingCodes = data.map(
        (country: any) => "+" + country.callingCodes
      );
      const countryFlags = data.map((country: any) => country.flag);
      setCountryNameList(countryNames);
      setCallingCodesList(callingCodes);
      setCountryFlagsList(countryFlags);
    } catch (error) {
      throw new Error("Failed to get country names");
    }
  };

  const editPhotoHandle = () => {
    navigatge("editphoto");
  };

  const getAccountInfo = () => {
    const calling_index = AttrToFind(auth.attrInfo, "locale");
    const callingcode = calling_index?.split(":")[0];
    const phNumber = AttrToFind(auth.attrInfo, "phone_number")?.slice(
      callingcode?.length
    );

    const tmpAddr = AttrToFind(auth.attrInfo, "address")?.split(", ");
    const tmpDOB = AttrToFind(auth.attrInfo, "birthdate");
    const pictureInfo = AttrToFind(auth.attrInfo, "picture");

    setAccountInfo({
      givenname: AttrToFind(auth.attrInfo, "given_name"),
      familyname: AttrToFind(auth.attrInfo, "family_name"),
      callingindex: calling_index,
      phoneNumber: phNumber,
      accountNumber: "CP9442286",
      country: tmpAddr && tmpAddr.length > 0 ? tmpAddr[0] : "",
      city: tmpAddr && tmpAddr.length > 1 ? tmpAddr[1] : "",
      address: tmpAddr && tmpAddr.length > 2 ? tmpAddr[2] : "",
      postalCode: tmpAddr && tmpAddr.length > 3 ? tmpAddr[3] : "",
      dob: tmpDOB,
      birthdate: tmpDOB
        ? parseInt(tmpDOB?.split("-")[0]).toString()
        : undefined,
      birthmonth: tmpDOB
        ? birthmonthArr[parseInt(tmpDOB?.split("-")[1]) - 1]
        : "Jan",
      birthyear: tmpDOB?.split("-")[2],
      picture: pictureInfo,
    });
  };

  useEffect(() => {
    getCountryNames();
    // const storedEmail: any = localStorage.getItem("autoSaved_email")
    //   ? localStorage.getItem("autoSaved_email")
    //   : localStorage.getItem("login_with_google");

    // const name = storedEmail.split("@", 1);
    // setName(name[0]);
    // setName("Lucy Test");
  }, []);

  useEffect(() => {
    getAccountInfo();
  }, [auth]);

  return (
    <div>
      <AuthHeader mode={4} />
      <div className="w-full flex justify-center">
        <div className="w-full lg:w-9/12 p-5">
          <div className="flex justify-center items-center">
            <div className="w-full md:w-3/4 flex-col">
              <div className="text-lg md:text-xl">Account</div>
              <div className="flex">
                <div
                  className="p-2.5 rounded-circle bg-[#040B11] hover:cursor-pointer"
                  onClick={editPhotoHandle}
                >
                  {/* <img src={avatar} /> */}
                  <div className="border border-green-400 rounded-full bg-green-500 w-[100px] h-[100px] flex items-center justify-center text-3xl">
                    {accountInfo?.picture ? (
                      <img
                        src={accountInfo?.picture}
                        alt="Selected Image"
                        className="w-[100px] h-[100px] rounded-full"
                      />
                    ) : (
                      `${
                        accountInfo?.givenname ? accountInfo?.givenname[0] : ""
                      }${
                        accountInfo?.familyname
                          ? accountInfo?.familyname[0]
                          : ""
                      }`
                    )}
                  </div>
                </div>
                <div className="flex-col justify-between">
                  <div className="text-lg md:text-2xl my-4">
                    {`${accountInfo?.givenname} ${accountInfo?.familyname}`}
                  </div>
                  <div className="text-sm text-[#979797]">
                    {`Account number ${accountInfo?.accountNumber}`}
                  </div>
                  <div className="text-xs text-[#979797]">
                    {`${accountInfo?.city} ${accountInfo?.country}`}
                  </div>
                </div>
              </div>
              {editMode === false ? (
                <div>
                  <div
                    className="text-white rounded-full hover:bg-[#0B1620] border p-2 text-xs m-3 border-slate-50 hover:cursor-pointer float-right"
                    onClick={() => setEditMode(true)}
                  >
                    Edit details
                  </div>
                </div>
              ) : (
                <div className="w-2/3 float-right flex">
                  <button
                    type="submit"
                    className="text-center w-1/3 rounded-full bg-[#2EBD85] hover:bg-[#71f1bf] p-2 text-xs m-3 hover:cursor-pointer"
                    onClick={saveUpdateData}
                  >
                    Save
                  </button>
                  <div
                    className="text-center w-1/3 rounded-full hover:bg-[#0B1620] border p-2 text-xs m-3 border-slate-50 hover:cursor-pointer"
                    onClick={() => {
                      setEditMode(false);
                      getAccountInfo();
                    }}
                  >
                    Cancel
                  </div>
                </div>
              )}

              {editMode == false ? (
                <div>
                  <div className="w-full mt-20 drop-shadow-md bg-[#0B1620] rounded-lg shadow p-4 md:p-8">
                    <div className="text-lg text-[##FFFFFF] mb-5">
                      Personal information
                    </div>
                    <div className="flex mb-10">
                      <div className="flex-col w-1/2">
                        <div className="text-sm text-[#979797] mb-1">
                          Full legal first name
                        </div>
                        <div className="text-sm text-[#FFFFFF]">
                          {accountInfo?.givenname}
                        </div>
                      </div>
                      <div className="flex-col w-1/2">
                        <div className="text-sm text-[#979797] mb-1">
                          Full legal Last name(s)
                        </div>
                        <div className="text-sm text-[#FFFFFF]">
                          {accountInfo?.familyname}
                        </div>
                      </div>
                    </div>
                    <div className="flex">
                      <div className="flex-col w-1/2">
                        <div className="text-sm text-[#979797] mb-2">
                          Date of birth
                        </div>
                        <div className="text-sm text-[#FFFFFF]">
                          {
                            <div className="text-sm text-[#ffffff]">
                              {accountInfo?.dob}
                            </div>
                          }
                        </div>
                      </div>
                      <div className="flex-col w-1/2">
                        <div className="text-sm text-[#979797] mb-2">Phone</div>
                        <div className="text-sm text-[#FFFFFF]">
                          {`${
                            accountInfo?.callingindex &&
                            accountInfo?.phoneNumber
                              ? accountInfo?.callingindex.split(":")[0] +
                                accountInfo?.phoneNumber
                              : ""
                          }`}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-full mt-20 drop-shadow-md bg-[#0B1620] rounded-lg shadow p-4 md:p-8">
                    <div className="text-lg text-[##FFFFFF] mb-5">
                      Personal address
                    </div>
                    <div className="flex mb-10">
                      <div className="flex-col w-1/2">
                        <div className="text-sm text-[#979797] mb-1">
                          Country
                        </div>
                        <div className="text-sm text-[#FFFFFF]">
                          {accountInfo?.country}
                        </div>
                      </div>
                      <div className="flex-col w-1/2">
                        <div className="text-sm text-[#979797] mb-1">City</div>
                        <div className="text-sm text-[#FFFFFF]">
                          {accountInfo?.city}
                        </div>
                      </div>
                    </div>
                    <div className="flex">
                      <div className="flex-col w-1/2">
                        <div className="text-sm text-[#979797] mb-2">
                          Address
                        </div>
                        <div className="text-sm text-[#FFFFFF]">
                          {accountInfo?.address}
                        </div>
                      </div>
                      <div className="flex-col w-1/2">
                        <div className="text-sm text-[#979797] mb-2">
                          Postal code
                        </div>
                        <div className="text-sm text-[#FFFFFF]">
                          {accountInfo?.postalCode}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-full mt-20 drop-shadow-md bg-[#0B1620] rounded-lg shadow p-4 md:p-8">
                    <div className="flex">
                      <div className="flex-col w-1/2">
                        <div className="text-sm text-[#FFFFFF] mb-1">
                          Account
                        </div>
                        <div className="text-sm text-[#979797]">
                          {accountInfo?.accountNumber}
                        </div>
                      </div>
                      <div className="flex w-1/2 items-center">
                        <div className="flex-col w-1/2">
                          <div className="text-sm text-[#FFFFFF] mb-1">
                            Plan
                          </div>
                          <div className="text-sm text-[#979797]">Free</div>
                        </div>
                        <div className="rounded-full bg-[#2EBD85] hover:bg-green-600 w-[145px] h-[43px] text-[14px] hover:cursor-pointer flex items-center justify-center">
                          Upgrade Plan
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <form>
                  <div className="w-full mt-20 drop-shadow-md bg-[#0B1620] rounded-lg shadow p-4 md:p-8">
                    {loading ? <LoadingSpinner /> : <></>}
                    <div className="text-lg text-[##FFFFFF] mb-5">
                      Personal information
                    </div>
                    <div className="inline lg:flex mb-10 gap-6">
                      <div className="flex-col w-[100%] lg:w-full">
                        <div className="text-sm text-[#979797] my-2">
                          Full legal first name
                        </div>
                        <input
                          type="text"
                          name="givenname"
                          value={accountInfo?.givenname}
                          className="h-9 bg-[#0B1620] border border-gray-500 text-white text-lg rounded-lg w-full p-2.5"
                          onChange={onChange}
                        />
                      </div>
                      <div className="flex-col w-[100%] lg:w-full">
                        <div className="text-sm text-[#979797] my-2">
                          Full legal Last name(s)
                        </div>
                        <input
                          type="text"
                          value={accountInfo?.familyname}
                          name="familyname"
                          className="h-9 bg-[#0B1620] border border-gray-500 text-white text-lg rounded-lg w-full p-2.5 "
                          onChange={onChange}
                        />
                      </div>
                    </div>
                    <div className="inline lg:flex gap-6">
                      <div className="flex-col w-[100%] lg:w-full">
                        <div className="text-sm text-[#979797] my-2">
                          Date of birth
                        </div>
                        <div className="text-sm text-[#FFFFFF] flex">
                          <input
                            type="text"
                            name="birthdate"
                            value={
                              accountInfo?.birthdate != undefined
                                ? accountInfo?.birthdate
                                : ""
                            }
                            placeholder="Date"
                            className="w-1/3 bg-[#0B1620] h-9 m-1 border border-gray-500 text-white text-sm rounded-lg float-left p-2.5"
                            onChange={onChange}
                          />
                          <div className="w-1/3 mr-1">
                            <DropdownBox
                              dataType={"birthmonth"}
                              onDataUpdate={handleDataUpdate}
                              dataArray={birthmonthArr}
                              defaultData={
                                accountInfo?.birthmonth !== undefined
                                  ? accountInfo?.birthmonth
                                  : ""
                              }
                            />
                          </div>
                          <input
                            type="text"
                            name="birthyear"
                            value={
                              accountInfo?.birthyear != undefined
                                ? accountInfo?.birthyear
                                : ""
                            }
                            placeholder="Year"
                            className="w-1/3 float-right bg-[#0B1620] h-9 ml-1 my-1 border border-gray-500 text-white text-sm rounded-lg p-2.5"
                            onChange={onChange}
                          />
                        </div>
                      </div>
                      <div className="flex-col w-[100%] lg:w-full">
                        <div className="text-sm text-[#979797] my-2">Phone</div>
                        <div className="text-sm text-[#FFFFFF] flex h-[45px]">
                          <div className="relative w-1/4 border border-[#979797] rounded-lg m-1">
                            <select
                              id="callingcode-select"
                              name="callingindex"
                              className="bg-transparent w-full p-3 focus:outline-none rounded-lg"
                              onChange={onChange}
                            >
                              {callingCodesList.map(
                                (code: string, idx: number) => (
                                  <option
                                    key={"ccl" + code + idx}
                                    value={`${code}:${idx}`}
                                    className="bg-black"
                                  >
                                    {`${countryNameList[idx]} ${code}`}
                                  </option>
                                )
                              )}
                            </select>
                            <img
                              src={
                                countryFlagsList[
                                  accountInfo?.callingindex
                                    ? parseInt(
                                        accountInfo?.callingindex.split(":")[1]
                                      )
                                    : -1
                                ]
                              }
                              className="relative top-[-38px] left-[3px] w-[65%] h-[27px]"
                            />
                          </div>
                          <input
                            name="phoneNumber"
                            value={
                              accountInfo?.phoneNumber != null
                                ? accountInfo?.phoneNumber
                                : ""
                            }
                            placeholder="PhoneNumber"
                            className="bg-[#0B1620] h-9 m-1 border border-gray-500 text-white text-sm rounded-lg w-full p-2.5"
                            onChange={onChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-full mt-20 drop-shadow-md bg-[#0B1620] rounded-lg shadow p-4 md:p-8">
                    <div className="text-lg text-[##FFFFFF] mb-5">
                      Personal Address
                    </div>
                    <div className="inline lg:flex mb-10 gap-6">
                      <div className="flex-col w-[100%] lg:w-full">
                        <div className="text-sm text-[#979797] my-2">
                          Country
                        </div>
                        <DropdownBox
                          dataType={"country"}
                          onDataUpdate={handleDataUpdate}
                          dataArray={countryNameList}
                          defaultData={accountInfo?.country}
                        />
                      </div>
                      <div className="flex-col w-[100%] lg:w-full pr-2">
                        <div className="text-sm text-[#979797] my-2">City</div>
                        <input
                          type="text"
                          name="city"
                          value={
                            accountInfo?.city != null ? accountInfo?.city : ""
                          }
                          placeholder="Type..."
                          className="bg-[#0B1620] h-9 m-1 border border-gray-500 text-white text-sm rounded-lg w-full p-2.5"
                          onChange={onChange}
                        />
                      </div>
                    </div>
                    <div className="inline lg:flex gap-6">
                      <div className="flex-col w-[100%] lg:w-full">
                        <div className="text-sm text-[#979797] my-2">
                          Address
                        </div>
                        <div className="text-sm text-[#FFFFFF] flex">
                          <input
                            type="text"
                            name="address"
                            value={
                              accountInfo?.address != null
                                ? accountInfo?.address
                                : ""
                            }
                            placeholder="Type..."
                            className="bg-[#0B1620] h-9 m-1 border border-gray-500 text-white text-sm rounded-lg w-full p-2.5"
                            onChange={onChange}
                          />
                        </div>
                      </div>
                      <div className="flex-col w-[100%] lg:w-full">
                        <div className="text-sm text-[#979797] my-2">
                          Postal Code
                        </div>
                        <div className="text-sm text-[#FFFFFF] flex">
                          <input
                            type="text"
                            name="postalCode"
                            value={
                              accountInfo?.postalCode != null
                                ? accountInfo?.postalCode
                                : ""
                            }
                            placeholder="Type..."
                            className="bg-[#0B1620] h-9 m-1 border border-gray-500 text-white text-sm rounded-lg w-full p-2.5"
                            onChange={onChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
