import React from "react";
// import { MainRoute, SignInRoute } from "routes";
import AuthProvider, {
  AuthIsSignedIn,
  AuthIsNotSignedIn,
} from "./contexts/authContext";
import View from "routes";

const App: React.FunctionComponent = () => (
  <AuthProvider>
    {/* <AuthIsSignedIn>
      <MainRoute />
    </AuthIsSignedIn>
    <AuthIsNotSignedIn>
      <SignInRoute />
    </AuthIsNotSignedIn> */}
    <View/>
  </AuthProvider>
);

export default App;
