import { FC } from "react";
import enIcon from '../assets/en_ico.svg';
import gridIcon from '../assets/grid_ico.svg';


const MenuBar: FC = () => {
  return (
    <div className="justify-between text-[white] bg-[#0B1620] text-base font-bold flex ">
      <div className="flex text-[white] bg-[#0B1620] text-base font-bold px-6 py-2">
        <div className="border-b-4 hover:border-b-4 hover:cursor-pointer border-[#2EBD85] py-3 mr-3">
          News
        </div>
        <div className="hover:border-b-4 hover:cursor-pointer border-[#2EBD85] py-3 mx-3">
          Watchlist
        </div>
        <div className="hover:border-b-4 hover:cursor-pointer border-[#2EBD85] py-3 mx-3">
          Portfolio
        </div>
        <div className="hover:border-b-4 hover:cursor-pointer border-[#2EBD85] py-3 mx-3">
          Stock of today
        </div>
        <div className="hover:border-b-4 hover:cursor-pointer border-[#2EBD85] py-3 mx-3">
          Stock comparison
        </div>
        <div className="hover:border-b-4 hover:cursor-pointer border-[#2EBD85] py-3 mx-3">
          Social Investing
        </div>
        <div className="hover:border-b-4 hover:cursor-pointer border-[#2EBD85] py-3 mx-3">
          Learning
        </div>
      </div>
      <div className="flex px-6">
        <div className="flex items-center mx-2 hover:cursor-pointer">
          <img src={enIcon} />
          <div className="ml-1">EN</div>
        </div>
        <div className="flex items-center ml-4 hover:cursor-pointer">
          <img src={gridIcon} />
          <div className="ml-1">Company</div>
        </div>
      </div>
    </div>
  );
};

export default MenuBar;
