import { FC, useEffect } from "react";

import Logo from "assets/logo.svg";
import confirmIcon from "assets/confirm_ico.svg";
import { useNavigate } from "react-router-dom";

const SuccessPwdChange: FC = () => {
  const comletedChangePwd = () => {
    navigate("/settings");
  };

  useEffect(() => {
    setTimeout(() => {
      navigate("/settings");
    }, 5000);
  }, []);

  const navigate = useNavigate();
  return (
    <div className="bg-[#23341B] w-full h-screen flex justify-center">
      <div className="w-[100%] lg:w-9/12 p-7">
        <div>
          <img src={Logo} />
        </div>
        <div className="flex flex-col text-center pt-12">
          <div className="flex justify-center">
            <img src={confirmIcon} />
          </div>
          <div className="text-white font-bold text-[40px] lg:text-[50px] leading-none">
            <div>Password</div>
            <div>Changed!</div>
          </div>
          <div className="pt-2 text-sm text-[#A8CFBD]">
            <div>Your password has been changed successfully. </div>
          </div>
          <div className="pt-6">
            <button
              className="bg-[#2EBD85] hover:bg-[#71f1bf] w-[100%] md:w-[50%] lg:w-[45%] text-black py-2 rounded-full text-black font-medium"
              onClick={comletedChangePwd}
            >
              Back to settings
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessPwdChange;
