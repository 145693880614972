import React, { FC, useState } from "react";

import AuthHeader from "components/AuthHeader";
import Toggle from "subtools/Toggle";

import homeLogo from "assets/home_ico.svg";
import on_off_Logo from "assets/on_off_ico.svg";

import { Link, useNavigate } from "react-router-dom";

const Notification: FC = () => {
  const [news, setNews] = useState(true);
  const [portfolio, setPortfolio] = useState(true);
  const [social, setSocial] = useState(true);
  const [stock, setStock] = useState(true);
  return (
    <div>
      <AuthHeader mode={4} />
      <div className="w-full flex justify-center">
        <div className="w-[100%] lg:w-9/12 p-7">
          <div className="flex justify-center items-center">
            <div className="w-[100%] lg:w-3/4 flex-col">
              <div className="text-xl">Notifications</div>
              <div className="mt-10 flex justify-between items-center">
                <div>
                  <div className="my-1 text-sm text-white">
                    News notifications
                  </div>
                  <div className="my-1 text-sm text-[#979797]">
                    Notify me about relevant news according to my preferences.
                  </div>
                </div>
                <div className="w-1/6">
                  <Toggle />
                </div>
              </div>
              <div className="mt-10 flex justify-between items-center">
                <div>
                  <div className="my-1 text-sm text-white">
                    Notifications about my portfolio
                  </div>
                  <div className="my-1 text-sm text-[#979797]">
                    Notify me about relevant positive and negative movements in
                    my portfolio.
                  </div>
                </div>
                <div className="w-1/6">
                  <Toggle />
                </div>
              </div>
              <div className="mt-10 flex justify-between items-center">
                <div>
                  <div className="my-1 text-sm text-white">
                    Notifications about social investing
                  </div>
                  <div className="my-1 text-sm text-[#979797]">
                    Notify me about relevant user posts on social investing.
                  </div>
                </div>
                <div className="w-1/6">
                  <Toggle />
                </div>
              </div>
              <div className="mt-10 flex justify-between items-center">
                <div>
                  <div className="my-1 text-sm text-white">
                    Notifications about stock of the day
                  </div>
                  <div className="my-1 text-sm text-[#979797]">
                    Notify me about stock recommendations that may represent a
                    good opportunity.
                  </div>
                </div>
                <div className="w-1/6">
                  <Toggle />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notification;
