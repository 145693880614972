import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserPool,
} from "amazon-cognito-identity-js";
const AWS = require("aws-sdk");
import { AttrInfo } from "contexts/authContext";

import { CognitoIdentityServiceProvider, Credentials } from 'aws-sdk';

// const userPoolId = process.env.REACT_APP_USERPOOL_ID;
  const userPoolId = "eu-central-1_QukrjlcsL"
// const clientId = process.env.REACT_APP_CLIENT_ID;
const clientId = "709aci6kf266pekbf54u1b5icm"
// const region = process.env.REACT_APP_AWS_REGION;
const region = "eu-central-1"
// const identityPool = process.env.REACT_APP_AWS_IDENTITY_POOL;
const  identityPool = "eu-central-1:2e4d9059-fb14-4c03-b64c-b9954e2367ee"
AWS.config.update({
  // accessKeyId: process.env.REACT_APP_ACCESSKEYID,
  accessKeyId : "AKIAQDOWTFJNFZ7UFKVZ",
  // secretAccessKey: process.env.REACT_APP_SECRETACCESSKEY,
  secretAccessKey : "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",
  // region: region,
region : "eu-central-1",
});

AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: identityPool,
});

const poolData = {
  UserPoolId: `${userPoolId}`,
  ClientId: `${clientId}`,
};

const userPool: CognitoUserPool = new CognitoUserPool(poolData);

let currentUser: any = userPool.getCurrentUser();

export function getCurrentUser() {
  return currentUser;
}

function getCognitoUser(username: string) {
  const userData = {
    Username: username,
    Pool: userPool,
  };
  const cognitoUser = new CognitoUser(userData);
console.log("....ognitoUser...",cognitoUser)
  return cognitoUser;
}

export async function getSession() {
  if (!currentUser) {
    currentUser = userPool.getCurrentUser();
  }

  return new Promise(function (resolve, reject) {
    currentUser.getSession(function (err: any, session: any) {
      if (err) {
        reject(err);
      } else {
        resolve(session);
      }
    });
  }).catch((err) => {
    throw err;
  });
}

export async function signUpUserWithEmail(
  email: string,
  firstname: string,
  lastname: string,
  password: string
) {
  return new Promise(function (resolve, reject) {
    const attributeList = [];

    // Add custom attributes
    attributeList.push(
      new CognitoUserAttribute({
        Name: "email",
        Value: email,
      })
    );
    attributeList.push(
      new CognitoUserAttribute({
        Name: "given_name",
        Value: firstname,
      })
    );
    attributeList.push(
      new CognitoUserAttribute({
        Name: "family_name",
        Value: lastname,
      })
    );

    userPool.signUp(email, password, attributeList, [], function (err, res) {
      if (err) {
        console.log("err", err);
        reject(err);
      } else {
        resolve(res);
      }
    });
  }).catch((err) => {
    throw err;
  });
}

export async function signUpwithNewEmail(
  email: string,
  attrs: [AttrInfo],
  password: string
) {
  return new Promise(function (resolve, reject) {
    // Add custom attributes
    const attributeList = attrs.map(
      (attr: AttrInfo) => new CognitoUserAttribute(attr)
    );

    userPool.signUp(email, password, attributeList, [], function (err, res) {
      if (err) {
        console.log("err", err);
        reject(err);
      } else {
        resolve(res);
      }
    });
  }).catch((err) => {
    throw err;
  });
}

export async function verifyCode(username: string, code: string) {
  return new Promise(function (resolve, reject) {
    const cognitoUser = getCognitoUser(username);

    cognitoUser.confirmRegistration(code, true, function (err, result) {
      if (err) {
        reject(err);
      } else {
        resolve(result);
      }
    });
  }).catch((err) => {
    throw err;
  });
}

export async function signInWithEmail(username: string, password: string) {
  return new Promise(function (resolve, reject) {
    const authenticationData = {
      Username: username,
      Password: password,
    };
    const authenticationDetails = new AuthenticationDetails(authenticationData);

    currentUser = getCognitoUser(username);

    currentUser.authenticateUser(authenticationDetails, {
      onSuccess: function (res: any) {
        console.log("User successfully logged in:", res);
        resolve(res);
      },
      onFailure: function (err: any) {
        reject(err);
      },
    });
  }).catch((err) => {
    throw err;
  });
}

export function signOut() {
  if (currentUser) {
    currentUser.signOut();
  }
}

export async function getAttributes() {
  return new Promise(function (resolve, reject) {
    currentUser.getUserAttributes(function (err: any, attributes: any) {
      if (err) {
        reject(err);
      } else {
        resolve(attributes);
      }
    });
  }).catch((err) => {
    throw err;
  });
}

export async function setAttribute(attribute: any) {
  return new Promise(function (resolve, reject) {
    const attributeList = [];
    const res = new CognitoUserAttribute(attribute);
    attributeList.push(res);

    currentUser.updateAttributes(attributeList, (err: any, res: any) => {
      if (err) {
        reject(err);
      } else {
        resolve(res);
      }
    });
  }).catch((err) => {
    throw err;
  });
}

export async function setAttributes(attributes: [AttrInfo]) {
  return new Promise(function (resolve, reject) {
    const attributeList = attributes.map(
      (attr: AttrInfo) => new CognitoUserAttribute(attr)
    );

    currentUser.updateAttributes(attributeList, (err: any, res: any) => {
      if (err) {
        reject(err);
      } else {
        resolve(res);
      }
    });
  }).catch((err) => {
    throw err;
  });
}

export async function setSpecificAttribute(
  username: string,
  password: string,
  attribute: any
) {
  return new Promise(function (resolve, reject) {
    const cognitoUser = getCognitoUser(username);

    const authenticationData = {
      Username: username,
      Password: password,
    };
    const authenticationDetails = new AuthenticationDetails(authenticationData);

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: function (res: any) {
        const attributeList = [];
        attributeList.push(new CognitoUserAttribute(attribute));
        cognitoUser.updateAttributes(attributeList, (err: any, res: any) => {
          if (err) {
            reject(err);
          } else {
            resolve(res);
          }
        });
      },
      onFailure: function (err: any) {
        reject(err);
      },
    });
  }).catch((err) => {
    throw err;
  });
}

export async function sendCode(username: string) {
  return new Promise(function (resolve, reject) {
    const cognitoUser = getCognitoUser(username);

    if (!cognitoUser) {
      reject(`could not find ${username}`);
      return;
    }

    cognitoUser.forgotPassword({
      onSuccess: function (res) {
        resolve(res);
      },
      onFailure: function (err) {
        reject(err);
      },
    });
  }).catch((err) => {
    throw err;
  });
}

export async function checkPhoneNumber(phoneNumber: string) {
  const params = {
    UserPoolId: userPoolId,
    Filter: `phone_number = "${phoneNumber}"`,
  };

  try {
    const cognitoIdentityServiceProvider =
      new AWS.CognitoIdentityServiceProvider();
    const userList = await cognitoIdentityServiceProvider
      .listUsers(params)
      .promise();

    interface Attr {
      Name: string;
      Value: string;
    }
    if (userList.Users.length > 0) {
      const attrs: Attr[] = userList.Users[0].Attributes;
      const attrToFind: Attr | undefined = attrs.find(
        (attr) => attr.Name === "email"
      );
      console.log(attrToFind);
      return attrToFind?.Value;
    } else {
      return;
    }
  } catch (err) {
    console.error(err);
  }
}

export async function forgotPassword(
  username: string,
  code: string,
  password: string
) {
  return new Promise(function (resolve, reject) {
    const cognitoUser = getCognitoUser(username);

    if (!cognitoUser) {
      reject(`could not find ${username}`);
      return;
    }

    cognitoUser.confirmPassword(code, password, {
      onSuccess: function () {
        resolve("password updated");
      },
      onFailure: function (err) {
        reject(err);
      },
    });
  });
}

export async function globalSignout() {
  return new Promise((resolve, reject) => {
    currentUser.globalSignOut({
      onSuccess: (res: any) => {
        console.log("Successfully logged out all devices");
        resolve(res);
      },
      onFailure: (err: any) => {
        console.error(err);
        reject(err);
      },
    });
  });
}

export async function deleteUser(username: string, password: string) {
  const userPool = new CognitoUserPool({
    UserPoolId: `${userPoolId}`,
    ClientId: `${clientId}`,
  });

  const authenticationData = {
    Username: username,
    Password: password,
  };
  const authenticationDetails = new AuthenticationDetails(authenticationData);

  const userData = {
    Username: username,
    Pool: userPool,
  };

  const cognitoUser = new CognitoUser(userData);

  return new Promise((resolve, reject) => {
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (result) => {
        // User is authenticated, proceed with the delete operation
        cognitoUser.deleteUser((err, result) => {
          if (err) {
            reject(err);
          } else {
            resolve(result);
          }
        });
      },
      onFailure: (err) => {
        reject(err);
      },
    });
  });
}

export async function changePassword(oldPassword: string, newPassword: string) {
  return new Promise(function (resolve, reject) {
    currentUser.changePassword(
      oldPassword,
      newPassword,
      function (err: any, res: any) {
        if (err) {
          reject(err);
        } else {
          resolve(res);
        }
      }
    );
  });
}

export async function updatePassword(
  username: string,
  oldPassword: string,
  newPassword: string
) {
  return new Promise(function (resolve, reject) {
    const cognitoUser = getCognitoUser(username);

    const authenticationData = {
      Username: username,
      Password: oldPassword,
    };
    const authenticationDetails = new AuthenticationDetails(authenticationData);

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: function (res: any) {
        cognitoUser.changePassword(
          oldPassword,
          newPassword,
          function (err: any, res: any) {
            if (err) {
              reject(err);
            } else {
              resolve(res);
            }
          }
        );
      },
      onFailure: function (err: any) {
        reject(err);
      },
    });
  }).catch((err) => {
    throw err;
  });
}

// export async function addUser(
//   email: string,
//   password: string,
//   attributes: [AttrInfo]
// ) {
//   return new Promise(function (resolve, reject) {
//     const params = {
//       UserPoolId: userPoolId,
//       Username: email,
//       TemporaryPassword: "QWE!@#asd123",
//       UserAttributes: attributes,
//       DesiredDeliveryMediums: ["EMAIL"],
//       MessageAction: "SUPPRESS",
//     };

//     const credentials = new AWS.Credentials({
//       // accessKeyId: process.env.REACT_APP_ACCESSKEYID,
//       // secretAccessKey: process.env.REACT_APP_SECRETACCESSKEY,
//       // region: region,
//         // accessKeyId: process.env.REACT_APP_ACCESSKEYID,
//   accessKeyId : "AKIAQDOWTFJNFZ7UFKVZ",
//   // secretAccessKey: process.env.REACT_APP_SECRETACCESSKEY,
//   secretAccessKey : "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",
//   // region: region,
// region : "eu-central-1",
//     });

//     AWS.config.credentials = credentials;

//     const cognito = new AWS.CognitoIdentityServiceProvider();
    
   
//     cognito.adminCreateUser(params, (err: any, data: any) => {
//       if (err) {
//         console.log("Error creating userr:", err.code);
//         reject(err);
//       } else {
//         console.log("User created successfully:", data);
//         const resetParams = {
//           UserPoolId: userPoolId,
//           Username: email,
//           Password: password,
//           Permanent: true,
//         };

//         cognito.adminSetUserPassword(resetParams, (err: any, data: any) => {
//           if (err) {
//             console.log("Error resetting password:", err);
//             reject(err);
//           } else {
//             console.log("Password reset successfully:", data);
//             resolve(data);
//           }
//         });
//       }
//     });
//   }).catch((err) => {
//     throw err;
//   });
// }


export async function addUser(
  email: string,
  password: string,
  attributes: [AttrInfo]
  ) {
  try {
    const params = {
      UserPoolId: userPoolId,
      Username: email,
      TemporaryPassword: 'QWE!@#asd123',
      UserAttributes: attributes,
      DesiredDeliveryMediums: ['EMAIL'],
      MessageAction: 'SUPPRESS',
    };

    // Assuming you're using environment variables for AWS credentials
    const credentials = new AWS.Credentials({
            // accessKeyId: process.env.REACT_APP_ACCESSKEYID,
            // secretAccessKey: process.env.REACT_APP_SECRETACCESSKEY,
            // region: region,
              // accessKeyId: process.env.REACT_APP_ACCESSKEYID,
        accessKeyId : "AKIAQDOWTFJNFZ7UFKVZ",
        // secretAccessKey: process.env.REACT_APP_SECRETACCESSKEY,
        secretAccessKey : "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",
        // region: region,
      region : "eu-central-1",
          });

    // Using AWS SDK's CognitoIdentityServiceProvider
    const cognito = new CognitoIdentityServiceProvider({ credentials });

    // Admin create user
    const createUserResult = await cognito.adminCreateUser(params).promise();

    // Admin set user password
    const setPasswordResult = await cognito.adminSetUserPassword({
      UserPoolId: userPoolId,
      Username: email,
      Password: password,
      Permanent: true,
    }).promise();

    console.log('User created successfully:', createUserResult);
    console.log('Password set successfully:', setPasswordResult);

    return { createUserResult, setPasswordResult };
  } catch (error) {
    console.error('Error creating user:', error);
    throw error;
  }
}

