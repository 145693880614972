import { FC, useState } from "react";

interface DropdownBoxInterface {
  dataArray: any;
  onDataUpdate: any;
  dataType: any;
  defaultData: any;
}

const DropdownBox: FC<DropdownBoxInterface> = ({
  dataArray,
  onDataUpdate,
  dataType,
  defaultData,
}) => {
  const [data, setData] = useState(defaultData);

  const handleInputChange = (event: any) => {
    setData(event.target.value);
    onDataUpdate(event.target.value, dataType);
  };

  return (
    <div className="mr-1">
      <select
        className="bg-[#0B1620] m-1 border border-gray-500  text-white text-sm hover:cursor-pointer rounded-lg w-full py-2"
        onChange={handleInputChange}
        value={data != null ? data : ""}
      >
        {dataArray.map((item: any, key: any) => (
          <option className="my-3" key={key}>
            {item}
          </option>
        ))}
      </select>
    </div>
  );
};

export default DropdownBox;
