import { FC } from "react";
import upIcon from "../assets/up_icon.svg";
import downIcon from "../assets/down_icon.svg";

interface UpDownInterface {
    clicked: boolean;
}

const UpDownIcon: FC<UpDownInterface> = ({ clicked }) => {

    return (
        <div>
    {clicked == true ? (
        <img src={upIcon} />
    ) : (
        <img src={downIcon} />
    )} </div>
    );
}

export default UpDownIcon;