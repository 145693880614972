import AuthHeader from "components/AuthHeader";

export const PrivacyPolicy = () => {
  return (
    <div>
      <AuthHeader mode={3} />
      <div className="w-full flex justify-center">
        <div className="w-10/12 lg:w-9/12 p-7">
          <div className="font-bold text-2xl flex justify-between w-10/12 lg:w-9/12 py-7">
            Capiwise Privacy Policy
          </div>
          <div className="py-5">
            Capiwise GmbH (&quot;us&quot;, &quot;we&quot;, or &quot;our&quot;)
            operates the website capiwisie.com (the “Capiwise Site” or the
            &quot;Service&quot;). This page informs you of our policies
            regarding the collection, use, and disclosure of personal data when
            you use our Service and the choices you have associated with that
            data.
          </div>
          <div className="py-5">
            <div className="text-[#2EBD85] text-xl flex w-full py-2 font-sans">
              Cookies
            </div>
            <div>
              Cookies are small pieces of data stored on a User’s device.
            </div>
          </div>
          <div className="py-5">
            <div className="text-[#2EBD85] text-xl flex w-full py-2 font-sans">
              Data Controller
            </div>
            <div>
              Data Controller means a person who (either alone or jointly or in
              common with other persons) determines the purposes for which and
              the manner in which any personal data are, or are to be,
              processed. For the purpose of this Privacy Policy, we are a Data
              Controller of your data.
            </div>
          </div>
          <div className="py-5">
            <div className="text-[#2EBD85] text-xl flex w-full py-2 font-sans">
              Data Processor (or Service Providers)
            </div>
            <div>
              Data Processor (or Service Provider) means any person (other than
              an employee of the Data Controller) who processes the data on
              behalf of the Data Controller. We may use the services of various
              Service Providers in order to process your data more effectively.
            </div>
          </div>
          <div className="py-5">
            <div className="text-[#2EBD85] text-xl flex w-full py-2 font-sans">
              Data Subject
            </div>
            <div>
              Data Subject is any living individual who is the subject of
              Personal Data.
            </div>
          </div>
          <div className="py-5">
            <div className="text-[#2EBD85] text-xl flex w-full py-2 font-sans">
              Personal Data
            </div>
            <div>
              Personal Data means data about a living individual who can be
              identified from those data (or from those and other information
              either in our possession or likely to come into our possession).
            </div>
          </div>
          <div className="py-5">
            <div className="text-[#2EBD85] text-xl flex w-full py-2 font-sans">
              The User
            </div>
            <div>
              The User is the individual using our Service. The User corresponds
              to the Data Subject, who is the subject of Personal Data.
            </div>
          </div>
          <div className="py-5">
            <div className="text-[#2EBD85] text-xl flex w-full py-2 font-sans">
              Usage Data
            </div>
            <div>
              Usage Data is data collected automatically either generated by the
              use of the Service or from the Service infrastructure itself (for
              example, the duration of a page visit).
            </div>
          </div>
          <div className="py-5">
            <div className="text-[#2EBD85] text-xl flex w-full py-2 font-sans">
              Information Collection And Use
            </div>
            <div>
              We collect several different types of information for various
              purposes to provide and improve our Service to you. The types of
              data we collect are listed below.
            </div>
          </div>
          <div className="py-5">
            <div className="text-[#2EBD85] text-xl flex w-full py-2 font-sans">
              Personal Data
            </div>
            <div>
              While using our Service, we may ask you to provide us with certain
              personally identifiable information that can be used to contact or
              identify you (&quot;Personal Data&quot;). Personally identifiable
              information may include, but is not limited to.
            </div>
          </div>
          <div className="py-5">
            <div className="text-[#2EBD85] text-xl flex w-full py-2 font-sans">
              Email address
            </div>
            <div>First name and last name.</div>
          </div>
          <div className="py-5">
            <div className="text-[#2EBD85] text-xl flex w-full py-2 font-sans">
              Usage Data
            </div>
            <div>
              We may also collect information how the Service is accessed and
              used (&quot;Usage Data&quot;). This Usage Data may include
              information such as your computer&apos;s Internet Protocol address
              (e.g. IP address), browser type, browser version, the pages of our
              Service that you visit, the time and date of your visit, the time
              spent on those pages, unique device identifiers and other
              diagnostic data.
            </div>
          </div>
          <div className="py-5">
            <div className="text-[#2EBD85] text-xl flex w-full py-2 font-sans">
              Location Data
            </div>
            <div>
              We may use information about your location if you give us
              permission to do so (“Location Data”). We use this data to provide
              features of our Service, to improve and customize our Service. You
              can enable or disable location services when you use our Service
              at any time, through your device settings.
            </div>
          </div>
          <div className="py-5">
            <div className="text-[#2EBD85] text-xl flex w-full py-2 font-sans">
              Data Retention
            </div>
            <div>
              We will retain your Personal Data only for as long as is necessary
              for the purposes set out in this Privacy Policy. We will retain
              and use your Personal Data to the extent necessary to comply with
              our legal obligations (for example, if we are required to retain
              your data to comply with applicable laws), resolve disputes, and
              enforce our legal agreements and policies.
            </div>
          </div>
          <div className="py-5">
            <div className="text-[#2EBD85] text-xl flex w-full py-2 font-sans">
              Security
            </div>
            <div>
              The security of your data is important to us but remember that no
              method of transmission over the Internet or method of electronic
              storage is 100% secure. While we strive to use commercially
              acceptable means to protect your Personal Data, we cannot
              guarantee its absolute security.
            </div>
          </div>
          <div className="py-5">
            <div className="text-[#2EBD85] text-xl flex w-full py-2 font-sans">
              &quot;Do Not Track&quot; Signals
            </div>
            <div>
              We do not support Do Not Track (&quot;DNT&quot;). Do Not Track is
              a preference you can set in your web browser to inform websites
              that you do not want to be tracked. You can enable or disable Do
              Not Track by visiting the Preferences or Settings page of your web
              browser.
            </div>
          </div>

          <div className="py-5">
            <div className="text-[#2EBD85] text-xl flex w-full py-2 font-sans">
              Your Rights
            </div>
            <div>
              <div>
                Capiwise aims to take reasonable steps to allow you to correct,
                amend, delete, or limit the use of your Personal Data. Whenever
                made possible, you can update your Personal Data directly within
                your account settings section.
              </div>
              <div>
                If you are unable to change your Personal Data, please contact
                us to make the required changes.
              </div>
              <div>
                If you wish to be informed what Personal Data we hold about you
                and if you want it to be removed from our systems, please
                contact us.
              </div>
              <br></br>
              <div>In certain circumstances, you have the right:</div>
              <br></br>
              <div>
                <ul className="list-disc px-4">
                  <li>
                    To access and receive a copy of the Personal Data we hold
                    about you;
                  </li>
                  <li>
                    To rectify any Personal Data held about you that is
                    inaccurate;
                  </li>
                  <li>
                    To request the deletion of Personal Data held about you.
                  </li>
                  <li>
                    You have the right to data portability for the information
                    you provide to Capiwise.
                  </li>
                  <li>
                    You can request to obtain a copy of your Personal Data in a
                    commonly used electronic format so that you can manage and
                    move it.
                  </li>
                </ul>
              </div>
              <br></br>
              <div>
                Please note that we may ask you to verify your identity before
                responding to such requests.
              </div>
            </div>
          </div>
          <div className="py-5">
            <div className="text-[#2EBD85] text-xl flex w-full py-2 font-sans">
              Service Providers
            </div>
            <div>
              We may employ third-party companies and individuals to facilitate
              our Service (&quot;Service Providers&quot;), to provide the
              Service on our behalf, to perform Service-related services, or to
              assist us in analyzing how our Service is used. These third
              parties have access to your Personal Data only to perform these
              tasks on our behalf and are obligated not to disclose or use it
              for any other purpose.
            </div>
          </div>
          <div className="py-5">
            <div className="text-[#2EBD85] text-xl flex w-full py-2 font-sans">
              Analytics
            </div>
            <div>
              We may use third-party Service Providers to monitor and analyze
              the use of our Service. These include but are not limited to:
            </div>
          </div>
          <div className="py-5">
            <div className="text-[#2EBD85] text-xl flex w-full py-2 font-sans">
              Google Analytics
            </div>
            <div>
              Capiwise does not use remarketing services to advertise on
              third-party websites to you after you have visited our Service.
            </div>
          </div>
          <div className="py-5">
            <div className="text-[#2EBD85] text-xl flex w-full py-2 font-sans">
              Payments
            </div>
            <div>
              We may provide paid products and/or services within the Service.
              In that case, we use third-party services for payment processing
              (e.g., payment processors). We will not store or collect your
              payment card details. That information is provided directly to our
              third-party payment processors whose use of your personal
              information is governed by their Privacy.
            </div>
          </div>
          <div className="py-5">
            <div className="text-[#2EBD85] text-xl flex w-full py-2 font-sans">
              Links To Other Sites
            </div>
            <div>
              Capiwise may contain links to other websites that are not operated
              by us. If you click on a third-party link, you will be directed to
              that third-party&apos;s site. We strongly advise you to review the
              Privacy Policy of every site you visit. We have no control over
              and assume no responsibility for the content, privacy policies or
              practices of any third-party sites or services.
            </div>
          </div>
          <div className="py-5">
            <div className="text-[#2EBD85] text-xl flex w-full py-2 font-sans">
              Children&apos;s Privacy
            </div>
            <div>
              Protecting the privacy of young children is especially important.
              Capiwise does not knowingly collect or maintain personally
              identifiable information from persons under 13 years of age
              (&quot;Children&quot;). No part of the Capiwise service is
              directed to persons under 13. If you are under 13 years of age,
              then please do not use or access the Capiwise service at any time
              or in any manner. Any person who provides their personal
              information to Capiwise represents that they are 13 years of age
              or older. If Capiwise learns that personally identifiable
              information of persons less than 13 years of age has been
              collected without verifiable parental consent, then Capiwise will
              take the appropriate steps to delete this information from our
              servers.
            </div>
          </div>
          <div className="py-5">
            <div className="text-[#2EBD85] text-xl flex w-full py-2 font-sans">
              In the Event of Merger, Sale or Bankruptcy
            </div>
            <div>
              In the event that Capiwise should ever be acquired by or merged
              with a third-party entity, we reserve the right, in any of these
              circumstances, to transfer or assign the information that we have
              collected from Users as part of such merger, acquisition, sale, or
              other change of control. In the event of Capiwise&apos;s
              bankruptcy, insolvency, or assignment for the benefit of
              creditors, we may not be able to control how information that we
              have collected from Users is transferred or used.
            </div>
          </div>
          <div className="py-5">
            <div className="text-[#2EBD85] text-xl flex w-full py-2 font-sans">
              Jurisdiction and Governing Law
            </div>
            <div>
              Your use of these Platforms and these Terms are governed by the
              law of Germany and you submit to the non-exclusive jurisdiction of
              the courts exercising jurisdiction in Germany.
            </div>
          </div>
          <div className="py-5">
            <div className="text-[#2EBD85] text-xl flex w-full py-2 font-roboto">
              European Union
            </div>
            <div>
              Capiwise is a data controller and processor for the purposes of
              the GDPR and by your consenting to this Privacy Policy, Capiwise
              is able to process your Personal Data in accordance with this
              Privacy Policy.
            </div>
            <div className="py-5">
              <div className="text-[#2EBD85] text-xl flex w-full py-2 font-sans">
                Changes To This Privacy Policy
              </div>
              <div>
                We may update our Privacy Policy from time to time. We will
                notify you of any changes by posting the new Privacy Policy on
                this page.
              </div>
              <div>
                We may let you know via email and/or a prominent notice on our
                Service, prior to the change becoming effective and update the
                &quot;effective date&quot; at the top of this Privacy Policy.
                You are advised to review this Privacy Policy periodically for
                any changes. Changes to this Privacy Policy are effective when
                they are posted on this page.
              </div>
            </div>
            <div className="py-5">
              <div className="text-[#2EBD85] text-xl flex w-full py-2 font-sans">
                Feedback and Complaints
              </div>
              <div>
                If you have any complaints about our handling of your Personal
                Information, including any breaches by us of any applicable data
                privacy laws or any questions regarding this Privacy Policy, you
                can submit that complaint or query by contacting us using the
                methods detailed in the “Contact Us” paragraph below.
              </div>
              <br></br>
              <div>
                Any complaints received by us will be referred to our compliance
                team for prompt investigation, and a written response will be
                provided to you as soon as possible.
              </div>
              <br></br>
              <div>
                Should you not be satisfied with the resolution of any
                complaints made, you may seek further redress through the
                relevant data protection authority.
              </div>
            </div>
            <div className="py-5">
              <div className="text-[#2EBD85] text-xl flex w-full py-2 font-sans">
                Contact Us
              </div>
              <div>
                If you have any questions about this Privacy Policy, please
                contact us by email: privacy@capiwise.com or post Mittelheide
                14, 12555 Berlin.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
