import React from "react";
import showPasswordIcon from "assets/showpassword_ico.svg";

export const Email: React.FunctionComponent<{
  emailIsValid: boolean;
  email: string;
  setEmail: (_: string) => void;
}> = ({ emailIsValid, email, setEmail }) => {
  return (
    <input
      className={`bg-transparent border border-[#979797] text-white focus:outline-none rounded-md w-full h-full px-2 ${
        !emailIsValid ? "border-red-500" : ""
      }`}
      type="email"
      value={email}
      placeholder={emailIsValid ? "" : "Email is invalid!"}
      onChange={(evt) => setEmail(evt.target.value)}
    />
  );
};

export const Password: React.FunctionComponent<{
  label: string;
  passwordIsValid: boolean;
  password: string;
  setPassword: (_: string) => void;
}> = ({ label, passwordIsValid, password, setPassword }) => {
  return (
    <input
      className={`bg-transparent border border-[#979797] text-white focus:outline-none rounded-md w-full h-full px-2 ${
        !passwordIsValid ? "border-red-500" : ""
      }`}
      type="password"
      value={password}
      // placeholder={passwordIsValid ? label : "Minimum 8 characters"}
      onChange={(evt) => setPassword(evt.target.value)}
    />
  );
};

const requirements = [
  { re: /[0-9]/, label: "" },
  { re: /[a-z]/, label: "" },
  { re: /[A-Z]/, label: "" },
  { re: /[$&+,:;=?@#|'<>.^*()%!-]/, label: "" },
];

function getStrength(password: string) {
  let multiplier = password.length >= 8 ? 0 : 1;

  requirements.forEach((requirement) => {
    if (!requirement.re.test(password)) {
      multiplier += 1;
    }
  });

  return Math.max(100 - (100 / (requirements.length + 1)) * multiplier, 0);
}

export const PasswordStrengh: React.FunctionComponent<{
  label?: string;
  password: string;
  isShowPassword: boolean;
  passwordIsValid: boolean;
  showStrength: boolean;
  setPassword: (_: string) => void;
  setIsShowPassword: (_: boolean) => void;
}> = ({
  label,
  password,
  isShowPassword,
  passwordIsValid,
  showStrength,
  setPassword,
  setIsShowPassword,
}) => {
  const strength = getStrength(password);
  const bars = Array(3)
    .fill(0)
    .map((_, index) => (
      <div
        className="w-full bg-gray-200 rounded-full h-1.5 dark:bg-gray-700"
        key={index}
      >
        <div
          className={`${
            strength > 80
              ? "bg-[#2EBD85]"
              : strength > 50
              ? "bg-[#FFA412]"
              : "bg-[#E2433B]"
          } h-1.5 rounded-full ${
            strength >= ((index + 1) / 3) * 100 ? "w-full" : "w-0"
          }`}
        ></div>
      </div>
    ));

  return (
    <div className="text-4">
      {label ? (
        <div className="pt-8 text-left text-[#979797] text-sm">{label}</div>
      ) : (
        <></>
      )}
      <div className="relative pt-1">
        <input
          className={`bg-transparent border border-[#979797] text-white focus:outline-none rounded-md w-full h-full p-2 pr-8 ${
            !passwordIsValid ? "border-red-500" : ""
          }`}
          type={isShowPassword ? "text" : "password"}
          id={`${label}`}
          placeholder="Password"
          value={password}
          onChange={(ev) => setPassword(ev.target.value)}
        />
        <div
          className="absolute right-3 top-5 hover:cursor-pointer"
          onClick={() => setIsShowPassword(!isShowPassword)}
        >
          <img src={showPasswordIcon} />
        </div>
      </div>
      {showStrength ? (
        <div className="flex gap-[5px] items-center mt-2">{bars}</div>
      ) : (
        <></>
      )}
    </div>
  );
};

export const Username: React.FunctionComponent<{
  usernameIsValid: boolean;
  setUsername: (_: string) => void;
}> = ({ usernameIsValid, setUsername }) => {
  return (
    <input
      className={`bg-transparent border border-[#979797] text-white focus:outline-none rounded-md w-full h-full px-2 ${
        !usernameIsValid ? "border-red-500" : ""
      }`}
      type="text"
      // placeholder={usernameIsValid ? "Username" : "Minimum 8 characters"}
      onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
        setUsername(evt.target.value);
      }}
    />
  );
};

export const Firstname: React.FunctionComponent<{
  firstnameIsValid: boolean;
  setFirstname: (_: string) => void;
}> = ({ firstnameIsValid, setFirstname }) => {
  return (
    <input
      className={`bg-transparent border border-[#979797] text-white focus:outline-none rounded-md w-full h-full px-2 ${
        !firstnameIsValid ? "border-red-500" : ""
      }`}
      type="text"
      // placeholder={usernameIsValid ? "Username" : "Minimum 8 characters"}
      onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
        setFirstname(evt.target.value);
      }}
    />
  );
};

export const Lastname: React.FunctionComponent<{
  lastnameIsValid: boolean;
  setLastname: (_: string) => void;
}> = ({ lastnameIsValid, setLastname }) => {
  return (
    <input
      className={`bg-transparent border border-[#979797] text-white focus:outline-none rounded-md w-full h-full px-2 ${
        !lastnameIsValid ? "border-red-500" : ""
      }`}
      type="text"
      // placeholder={usernameIsValid ? "Username" : "Minimum 8 characters"}
      onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
        setLastname(evt.target.value);
      }}
    />
  );
};

export const Code: React.FunctionComponent<{
  label?: string;
  codeIsValid: boolean;
  setCode: (_: string) => void;
}> = ({ label, codeIsValid, setCode }) => {
  return (
    // <input
    //   className={`bg-transparent border border-[#979797] text-white focus:outline-none rounded-md w-full h-full px-2 ${
    //     !codeIsValid ? "border-red-500" : ""
    //   }`}
    //   type="text"
    //   // placeholder={usernameIsValid ? "Username" : "Minimum 8 characters"}
    //   onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
    //     setCode(evt.target.value);
    //   }}
    // />
    <div className="text-4">
      <div className="pt-8 text-left text-[#979797] text-sm">{label}</div>
      <div className="relative pt-1">
        <input
          className={`bg-transparent border border-[#979797] text-white focus:outline-none rounded-md w-full h-full p-2 pr-8 ${
            !codeIsValid ? "border-red-500" : ""
          }`}
          type="text"
          id={`${label}`}
          placeholder="123456"
          onChange={(ev) => setCode(ev.target.value)}
        />
      </div>
    </div>
  );
};
