import { FC, useState } from "react";

import { useNavigate } from "react-router-dom";
import AuthHeader from "components/AuthHeader";
import backLogo from "assets/back_ico.svg";

const CloseAccount: FC = () => {
  const [reasons, setReasons] = useState("");
  const reasonsList = [
    "Problems with my account",
    `Couldn‘t find the stocks I wanted`,
    "I want to change my account details",
    "I‘m not using my account anymore",
    "Other reasons",
  ];
  const navigate = useNavigate();
  const clickedBack = () => {
    navigate("/settings");
  };

  const clickedClose = () => {
    navigate("/settings/account/beforeyougo");
  };

  return (
    <div className="w-full h-screen">
      <AuthHeader mode={2} />
      <div
        onClick={clickedBack}
        className="w-1/6 absolute left-[35px] lg:left-[57px] top-[75px] lg:top-[160px] flex hover:cursor-pointer"
      >
        <img className="mr-2" src={backLogo} /> Back
      </div>
      <div className="w-full flex justify-center">
        <div className="w-[360px] md:w-[45%] lg:w-[30%] py-10">
          <div className="text-center py-7">
            <div className="text-2xl font-bold">
              Before you go, tell us why you‘re leaving
            </div>
            <div className="text-sm text-[#979797] py-2 font-normal">
              Choose one of the options
            </div>
          </div>
          <div className="justify-between">
            {reasonsList.map((_item: string, index: number) => (
              <div
                className="justify-between flex items-center mb-4 w-full"
                key={_item}
              >
                <label className="ml-2 text-sm font-medium text-[#979797]">
                  {_item}
                </label>
                <input
                  id="default-radio-1"
                  type="radio"
                  value=""
                  name="default-radio"
                  className="hover:cursor-pointer w-4 h-4 text-[#040B11] bg-[#040B11] rounded-full border-gray-300 "
                  onClick={() => setReasons(_item)}
                />
              </div>
            ))}
          </div>
          <div className="flex items-center justify-center pt-4">
            <button
              className="bg-[#2EBD85] hover:bg-[#71f1bf] w-[100%] py-2 rounded-full text-black disabled:bg-[#E2E7ED] disabled:cursor-not-allowed font-medium"
              disabled={reasons === ""}
              onClick={clickedClose}
            >
              Close Account
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CloseAccount;
