import { IAuth } from "contexts/authContext";
import { AttrInfo } from "contexts/authContext";
export const UpdateAttrInfo = async (
  auth: IAuth,
  attrname: string,
  attrInfo: string
) => {
  const attrs: [AttrInfo] = await auth.getAttributes();

  // Find the index of the object you want to update (e.g., based on ID)
  const index = attrs.findIndex((obj) => obj.Name === attrname);

  // If the object is found
  if (index !== -1) {
    // Update the desired attribute or property of the object
    if (attrInfo !== "") {
      attrs[index].Value = attrInfo;
    } else {
      attrs.splice(index, 1);
    }
  } else {
    if (attrInfo !== "") {
      attrs.push({
        Name: attrname,
        Value: attrInfo,
      });
    }
  }
  await auth.setAttrInfo(attrs);

  await auth.setAttribute({
    Name: attrname,
    Value: attrInfo,
  });
};
