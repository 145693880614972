import React, { useContext, useState, useEffect, useRef } from "react";
import AuthHeader from "components/AuthHeader";
import { AuthContext } from "contexts/authContext";
import { AttrToFind } from "subtools/AttrToFind";
import EditIcon from "assets/edit_ico.svg";
import AddPhotoIcon from "assets/takephoto_ico.svg";
import PhotoFrameIcon from "assets/photoframe_ico.svg";
import DelIcon from "assets/delete_ico.svg";
import { UpdateAttrInfo } from "subtools/UpdateAttrInfo";
import { S3 } from "aws-sdk";
import { LoadingSpinner } from "components/loadingSpinner";

interface AccountInfo {
  givenname?: string;
  familyname?: string;
  picture?: string;
}

export const EditPhoto = () => {
  const auth = useContext(AuthContext);
  const [accountInfo, setAccountInfo] = useState<AccountInfo>();
  const visLists = ["Anyone", "Nobody", "Only contacts"];
  const [vis, setVis] = useState(visLists[0]);
  const [selectedImage, setSelectedImage] = useState("");
  const s3 = new S3({
    accessKeyId: process.env.REACT_APP_ACCESSKEYID,
    secretAccessKey: process.env.REACT_APP_SECRETACCESSKEY,
    region: process.env.REACT_APP_AWS_REGION,
  });

  const bucketname = process.env.REACT_APP_S3_BUCKET;

  const visHandle = (idx: number) => {
    setVis(visLists[idx]);
    setDisable(true);
  };

  const getAccountInfo = () => {
    setAccountInfo({
      givenname: AttrToFind(auth.attrInfo, "given_name"),
      familyname: AttrToFind(auth.attrInfo, "family_name"),
      picture: AttrToFind(auth.attrInfo, "picture"),
    });
  };

  const tempmail = localStorage.getItem("autoSaved_email");

  const [disable, setDisable] = useState(true);
  const [delPopShow, setDelPopShow] = useState(false);
  const [savePopShow, setSavePopShow] = useState(false);
  const [loading, setLoading] = useState(false);

  const deleteClicked = () => {
    if (!savePopShow && !loading) {
      setDelPopShow(true);
    }
  };

  const cancleDelHandle = () => {
    setDelPopShow(false);
  };

  useEffect(() => {
    getAccountInfo();
  }, [auth]);

  const inputFileRef = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    // Trigger the file input click event
    if (inputFileRef.current && !loading) {
      inputFileRef.current.click();
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const imgData = e.target?.result as string;
        setSelectedImage(imgData);
      };

      reader.readAsDataURL(file);
    }
    setSavePopShow(true);
  };

  const cancleSaveHandle = () => {
    setSavePopShow(false);
  };

  const uploadFileToS3 = (
    file: File,
    bucketName: string,
    folderName: string,
    fileName: string
  ): Promise<string> => {
    const params = {
      Bucket: bucketName,
      Key: `${folderName}/${fileName}`,
      Body: file,
      ACL: "public-read", // Set the file ACL to public read access
    };

    return new Promise((resolve, reject) => {
      s3.upload(params, (err: any, data: any) => {
        if (err) {
          reject(err);
        }
        resolve(data.Location); // Return the public URL of the uploaded file
      });
    });
  };

  const handleFileUpload = async () => {
    console.log("Button clicked!");

    setSavePopShow(false);
    setLoading(true);
    if (
      inputFileRef.current &&
      inputFileRef.current.files &&
      inputFileRef.current.files[0] &&
      bucketname
    ) {
      const selectedFile = inputFileRef.current.files[0];
      try {
        await UpdateAttrInfo(auth, "picture", "");
        const publicUrl = await uploadFileToS3(
          selectedFile,
          bucketname,
          "profile",
          `${tempmail}.png`
        );

        setLoading(false);
        setAccountInfo({ picture: publicUrl });
        console.log("File uploaded successfully:", publicUrl);

        await UpdateAttrInfo(auth, "picture", publicUrl);
      } catch (error) {
        console.error("Error uploading file:", error);
        setLoading(false);
      }
    }
  };

  async function deleteFile(
    bucketName: string,
    fileKey: string
  ): Promise<void> {
    const deleteParams = {
      Bucket: bucketName,
      Key: fileKey,
    };
    setLoading(true);

    try {
      await s3.deleteObject(deleteParams).promise();
      console.log("File deleted successfully");
      setLoading(false);
    } catch (err) {
      console.error("Error deleting file:", err);
      setLoading(false);
    }
  }

  const delHandle = async () => {
    console.log("del btn clicked");
    setDelPopShow(false);
    setSelectedImage("");
    await deleteFile(bucketname ? bucketname : "", `profile/${tempmail}.png`);

    await UpdateAttrInfo(auth, "picture", "");

    await auth.setAttribute({
      Name: "picture",
      Value: "",
    });

    setAccountInfo({
      givenname: AttrToFind(auth.attrInfo, "given_name"),
      familyname: AttrToFind(auth.attrInfo, "family_name"),
      picture: "",
    });
  };

  const deletePopup = (
    <div className="w-[320px] lg:w-[360px] aspect-[2/1] bg-[#F4F5F7] rounded-md p-6">
      <p className="text-[#040B11] font-medium text-2xl">
        Are you sure about <br />
        deleting your photo?
      </p>
      <p className="text-[#979797] text-base font-medium">
        This action can’t be undone
      </p>
      <div className="flex items-center justify-end pt-4">
        <div className="w-2/5 flex justify-end">
          <button
            type="submit"
            className="text-center text-black rounded-full bg-[#F4F5F7] hover:bg-white text-xs hover:cursor-pointer font-normal border border-black py-2 px-8"
            onClick={cancleDelHandle}
          >
            Cancel
          </button>
        </div>
        <div className="w-2/5 flex justify-end">
          <button
            type="submit"
            className="text-center rounded-full bg-[#E2433B] hover:bg-red-500 py-2 px-8 text-xs hover:cursor-pointer font-normal"
            onClick={delHandle}
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  );

  const savePopup = (
    <div className="w-[320px] lg:w-[360px] aspect-[5/1] bg-[#F4F5F7] rounded-md p-6">
      <div className="flex items-center justify-center">
        <div className="w-1/2 flex justify-center">
          <button
            type="submit"
            className="text-center text-black rounded-full bg-[#F4F5F7] hover:bg-white text-xs hover:cursor-pointer font-normal border border-black py-2 px-8"
            onClick={cancleSaveHandle}
          >
            Cancel
          </button>
        </div>
        <div className="w-1/2 flex justify-center">
          <button
            type="submit"
            className="text-center rounded-full bg-[#E2433B] hover:bg-red-500 py-2 px-8 text-xs hover:cursor-pointer font-normal"
            onClick={handleFileUpload}
          >
            Save Photo
          </button>
        </div>
      </div>
    </div>
  );

  return (
    <div>
      <AuthHeader mode={4} />
      <div className="w-full flex justify-center">
        <div className="w-full lg:w-9/12 p-5">
          <div className="flex justify-center items-center">
            <div className="w-full md:w-3/4 flex-col">
              <div className="text-xl md:text-2xl">Profile photo</div>
              <div className="relative flex justify-center items-center mt-6">
                <div className="p-2.5 rounded-circle bg-[#040B11] hover:cursor-pointer">
                  <div className="border border-green-400 rounded-full bg-green-500 w-[284px] h-[284px] flex items-center justify-center text-3xl">
                    {selectedImage ? (
                      <img
                        src={selectedImage}
                        alt="Selected Image"
                        className="w-[285px] h-[285px] rounded-full"
                      />
                    ) : accountInfo?.picture && accountInfo?.picture !== "" ? (
                      <img
                        src={accountInfo?.picture}
                        alt="Stored Image"
                        className="w-[285px] h-[285px] rounded-full"
                      />
                    ) : (
                      `${
                        accountInfo?.givenname ? accountInfo?.givenname[0] : ""
                      }${
                        accountInfo?.familyname
                          ? accountInfo?.familyname[0]
                          : ""
                      }`
                    )}
                  </div>
                </div>
                {delPopShow ? (
                  <div className="absolute inset-0 flex justify-center items-center">
                    {deletePopup}
                  </div>
                ) : (
                  <></>
                )}
                {savePopShow ? (
                  <div className="absolute top-96 flex justify-center items-center">
                    {savePopup}
                  </div>
                ) : (
                  <></>
                )}
                {loading ? <LoadingSpinner /> : <></>}
              </div>
              <div className="relative">
                <div
                  className="flex items-center rounded-full bg-[#040B11] hover:cursor-pointer gap-2 border border-white inline-flex items-center justify-center p-2"
                  onClick={() => setDisable(!disable)}
                >
                  <svg
                    width="22"
                    height="15"
                    viewBox="0 0 22 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11 2C14.79 2 18.17 4.13 19.82 7.5C18.17 10.87 14.8 13 11 13C7.2 13 3.83 10.87 2.18 7.5C3.83 4.13 7.21 2 11 2ZM11 0C6 0 1.73 3.11 0 7.5C1.73 11.89 6 15 11 15C16 15 20.27 11.89 22 7.5C20.27 3.11 16 0 11 0ZM11 5C12.38 5 13.5 6.12 13.5 7.5C13.5 8.88 12.38 10 11 10C9.62 10 8.5 8.88 8.5 7.5C8.5 6.12 9.62 5 11 5ZM11 3C8.52 3 6.5 5.02 6.5 7.5C6.5 9.98 8.52 12 11 12C13.48 12 15.5 9.98 15.5 7.5C15.5 5.02 13.48 3 11 3Z"
                      fill="white"
                    />
                  </svg>

                  <p className="text-sm">{vis}</p>
                </div>
                <div className="absolute top-10 left-0 z-10">
                  {disable == false ? (
                    <div className="w-44 h-45 text-sm font-medium bg-[#0B1620] rounded-lg border border-white rounded-lg">
                      <div className="block w-full py-2 border-b border-[#000000] text-center">
                        <span className="block w-full text-[#979797] text-sm">
                          Who can see your photo?
                        </span>
                      </div>
                      {visLists.map((_item: string, idx: number) => (
                        <div
                          className="block w-full px-4 py-2 cursor-pointer hover:bg-[#040B11] focus:outline-none focus:ring-2 focus:ring-blue-700 text-[#979797] "
                          key={_item}
                          onClick={(_item) => visHandle(idx)}
                        >
                          {_item}
                        </div>
                      ))}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>

              <div
                className={`bg-[#0B1620] w-[100%] h-20 lg:h-28 rounded-xl relative ${
                  savePopShow ? "top-40" : "top-20"
                }  flex justify-between items-end p-5 lg:p-8`}
              >
                <div className="flex items-end justify-between w-[50%]">
                  <div className="grid grid-cols-1 gap-1 place-items-center hover:cursor-pointer">
                    <div className="flex items-center justify-center w-[20px] lg:w-[40px]">
                      <img src={EditIcon} />
                    </div>
                    <p className="text-xs lg:text-sm">Edit</p>
                  </div>
                  <div
                    className="grid grid-cols-1 gap-1 place-items-center hover:cursor-pointer"
                    onClick={handleClick}
                  >
                    <input
                      ref={inputFileRef}
                      type="file"
                      style={{ display: "none" }}
                      onChange={handleChange}
                    />
                    <div className="flex items-center justify-center w-[20px] lg:w-[40px]">
                      <img src={AddPhotoIcon} />
                    </div>
                    <p className="text-xs lg:text-sm">Add photo</p>
                  </div>
                  <div className="grid grid-cols-1 gap-1 place-items-center hover:cursor-pointer">
                    <div className="flex items-center justify-center w-[20px] lg:w-[40px]">
                      <img src={PhotoFrameIcon} />
                    </div>
                    <p className="text-xs lg:text-sm">Frame</p>
                  </div>
                </div>
                <div
                  className="grid grid-cols-1 gap-1 place-items-center hover:cursor-pointer"
                  onClick={deleteClicked}
                >
                  <div className="flex items-center justify-center w-[16px] lg:w-[40px]">
                    <img src={DelIcon} />
                  </div>
                  <p className="text-xs lg:text-sm">Delete</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
