import { useState, useEffect } from "react";

import * as yup from "yup";

export const useValidEmail = (initialValue: string) => {
  const [email, setEmail] = useState(initialValue);
  const [emailIsValid, setEmailIsValid] = useState(true);
  const expression = /^[^\s@]+@[^\s@]+\.[^\s@]{2,3}$/;

  useEffect(() => {
    if (email.length === 0) {
      setEmailIsValid(true);
      return;
    }
    const isValid: boolean = expression.test(email);

    setEmailIsValid(isValid);
  }, [email]);

  return { email, setEmail, emailIsValid };
};

const requirements = [
  { re: /[0-9]/, label: "" },
  { re: /[a-z]/, label: "" },
  { re: /[A-Z]/, label: "" },
  { re: /[$&+,:;=?@#|'<>.^*()%!-]/, label: "" },
];

function getStrength(password: string) {
  let multiplier = password.length >= 8 ? 0 : 1;

  requirements.forEach((requirement) => {
    if (!requirement.re.test(password)) {
      multiplier += 1;
    }
  });

  return Math.max(100 - (100 / (requirements.length + 1)) * multiplier, 0);
}

export const useValidPassword = (initialValue: string) => {
  const [password, setPassword] = useState(initialValue);
  const [passwordIsValid, setPasswordIsValid] = useState(false);

  useEffect(() => {
    const strength = getStrength(password);

    if (password.length === 0) {
      setPasswordIsValid(true);
      return;
    }
    if (strength === 100) {
      setPasswordIsValid(true);
    } else {
      setPasswordIsValid(false);
    }
  }, [password]);

  return { password, setPassword, passwordIsValid };
};

export const useValidUsername = (initialValue: string) => {
  const [username, setUsername] = useState(initialValue);
  const [usernameIsValid, setUsernameIsValid] = useState(true);

  useEffect(() => {
    const usernameSchema = yup.object().shape({
      username: yup.string().min(8).required(),
    });

    if (username.length === 0) {
      setUsernameIsValid(true);
      return;
    }

    const isValid = usernameSchema.isValidSync({ username });

    setUsernameIsValid(isValid);
  }, [username]);

  return { username, setUsername, usernameIsValid };
};

export const useValidFirstname = (initialValue: string) => {
  const [firstName, setFirstName] = useState(initialValue);
  const [fisrtnameIsValid, setFirstnameIsValid] = useState(true);
  const expression = /^[A-Z][a-zA-Z]{3,}$/;

  useEffect(() => {
    if (firstName.length === 0) {
      setFirstnameIsValid(true);
      return;
    }
    const isValid: boolean = expression.test(firstName);
    setFirstnameIsValid(isValid);
  }, [firstName]);

  return { firstName, setFirstName, fisrtnameIsValid };
};

export const useValidLastname = (initialValue: string) => {
  const [lastName, setLastName] = useState(initialValue);
  const [lastnameIsValid, setLastnameIsValid] = useState(true);
  const expression = /^[A-Z][a-zA-Z]{3,}$/;

  useEffect(() => {
    if (lastName.length === 0) {
      setLastnameIsValid(true);
      return;
    }
    const isValid: boolean = expression.test(lastName);
    setLastnameIsValid(isValid);
  }, [lastName]);

  return { lastName, setLastName, lastnameIsValid };
};

export const useValidCode = (initialValue: string) => {
  const [code, setCode] = useState(initialValue);
  const [codeIsValid, setCodeIsValid] = useState(true);

  useEffect(() => {
    const codeSchema = yup.object().shape({
      code: yup.string().min(6).required(),
    });

    if (code.length === 0) {
      setCodeIsValid(true);
      return;
    }

    const isValid = codeSchema.isValidSync({ code });

    setCodeIsValid(isValid);
  }, [code]);

  return { code, setCode, codeIsValid };
};
