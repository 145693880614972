import React, { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
// import { GoogleOAuthProvider } from "@react-oauth/google";



import ForgetPassword from "view/auth/forgetPassword";
import ResetPassword from "view/auth/resetPassword";

import { TermsOfUse } from "view/termsOfUse";
import { PrivacyPolicy } from "view/privacyPolicy";

import ETFSummary from "view/etfSummary";
import StockSummary from "view/stockSummary";

import MenuProfileSettings from "components/MenuProfileSettings";
import MenuBar from "components/MenuBar";
import Footer from "components/Footer";
import SearchBox from "components/SearchBox";

import Logo from "assets/logo.svg";
import { ManageAccount } from "view/manageAccount";
import { EditPhoto } from "view/manageAccount/EditPhoto";
import { Settings } from "view/settings";
import Notification from "view/settings/account/Notification";
import ChangePwd from "view/settings/password/ChangePwd";
import CloseAccount from "view/settings/account/CloseAccount";
import BeforeYouGo from "view/settings/account/BeforeYouGo";
import ConfirmClose from "view/settings/account/ConfirmClose";
import RealConfirmClose from "view/settings/account/RealConfirmClose";
import SuccessClose from "view/settings/account/SuccessClose";
import SuccessPwdChange from "view/settings/password/SuccessPwdChange";
import ChnageEmail from "view/settings/email";
import MainSearch from "view/search";
import Home from "view/home";
import PublicRoute from "contexts/publicRoute";
import ProtectedRoute from "contexts/ProtectedRoute";
import Register from "view/auth/register";
import { LogIn } from "view/auth/login";

// export const SignInRoute: React.FunctionComponent = () => (
//   <BrowserRouter>
//     <GoogleOAuthProvider clientId="843774698876-qrhnm1nrlknmserv2hv1eg38fmspflbc.apps.googleusercontent.com">
//       <Routes>
//         {/* <Route path="/" element={<Home />} /> */}
//         <Route path="/" element={<LogIn />} />
//         <Route path="/register" element={<Register />} />
//         <Route path="/forgetpassword" element={<ForgetPassword />} />
//         <Route path="/resetpassword" element={<ResetPassword />} />
//         <Route path="/termsOfUse" element={<TermsOfUse />} />
//         <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
//       </Routes>
//     </GoogleOAuthProvider>
//   </BrowserRouter>
// );

// export const MainRoute: React.FunctionComponent = () => (
//   <BrowserRouter>
//     <Routes>
//       <Route path="/" element={<MainSearch />} />
//       <Route
//         path="/etfsummary"
//         element={
//           <div className="flex flex-col h-screen">
//             <div className="flex bg-[#0B1620] justify-between items-center p-6">
//               <div>
//                 <img src={Logo} />
//               </div>
//               <SearchBox disabled={false} />
//               <MenuProfileSettings />
//             </div>
//             <div>
//               <MenuBar />
//               <ETFSummary />
//             </div>
//             <Footer />
//           </div>
//         }
//       />
//       <Route
//         path="/stocksummary"
//         element={
//           <div className="flex flex-col h-screen">
//             <div className="flex bg-[#0B1620] justify-between items-center p-6">
//               <div>
//                 <img src={Logo} />
//               </div>
//               <SearchBox disabled={false} />
//               <MenuProfileSettings />
//             </div>
//             <div>
//               <MenuBar />
//               <StockSummary />
//             </div>
//             <Footer />
//           </div>
//         }
//       />
      
//       <Route path="/manageaccount" element={<ManageAccount />} />
//       <Route path="/manageaccount/editphoto" element={<EditPhoto />} />
//       <Route path="/settings" element={<Settings />} />
//       <Route path="/settings/notification" element={<Notification />} />
//       <Route path="/settings/account/closeaccount" element={<CloseAccount />} />
//       <Route path="/settings/account/beforeyougo" element={<BeforeYouGo />} />
//       <Route path="/settings/account/confirmclose" element={<ConfirmClose />} />
//       <Route
//         path="/settings/account/realconfirmclose"
//         element={<RealConfirmClose />}
//       />
//       <Route path="/settings/account/successclose" element={<SuccessClose />} />
//       <Route path="/settings/password/changepassword" element={<ChangePwd />} />
//       <Route path="/settings/email/changeemail" element={<ChnageEmail />} />
//       <Route
//         path="/settings/password/successpasswordchange"
//         element={<SuccessPwdChange />}
//       />
//     </Routes>
//   </BrowserRouter>
// );
function View() {
const [logedIn, setlogedIn] = useState(localStorage.getItem('logedIn') == 'true') 
return (


  <BrowserRouter>
 
    <Routes>
  
       <Route path="/" element={<PublicRoute logedIn = {logedIn }/>}>
       <Route path='' element={<LogIn/>}/>
     
        <Route path="/register" element={<Register />} />

        <Route path="/forgetpassword" element={<ForgetPassword />} />
        <Route path="/resetpassword" element={<ResetPassword />} />
        <Route path="/termsOfUse" element={<TermsOfUse />} />
        <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
       
 </Route>
    
        <Route  path='/' element={<ProtectedRoute logedIn ={logedIn} />} >
        {<Route path='/Home' element={<Home/>}/>}

      <Route
        path="/etfsummary"
        element={
          <div className="flex flex-col h-screen">
            <div className="flex bg-[#0B1620] justify-between items-center p-6">
              <div>
                <img src={Logo} />
              </div>
              <SearchBox disabled={false} />
              <MenuProfileSettings />
            </div>
            <div>
              <MenuBar />
              <ETFSummary />
            </div>
            <Footer />
          </div>
        }
      />
      <Route
        path="/stocksummary"
        element={
          <div className="flex flex-col h-screen">
            <div className="flex bg-[#0B1620] justify-between items-center p-6">
              <div>
                <img src={Logo} />
              </div>
              <SearchBox disabled={false} />
              <MenuProfileSettings />
            </div>
            <div>
              <MenuBar />
              <StockSummary />
            </div>
            <Footer />
          </div>
        }
      />
      
      <Route path="/manageaccount" element={<ManageAccount />} />
      <Route path="/manageaccount/editphoto" element={<EditPhoto />} />
      <Route path="/settings" element={<Settings />} />
      <Route path="/settings/notification" element={<Notification />} />
      <Route path="/settings/account/closeaccount" element={<CloseAccount />} />
      <Route path="/settings/account/beforeyougo" element={<BeforeYouGo />} />
      <Route path="/settings/account/confirmclose" element={<ConfirmClose />} />
      <Route
        path="/settings/account/realconfirmclose"
        element={<RealConfirmClose />}
      />
      <Route path="/settings/account/successclose" element={<SuccessClose />} />
      <Route path="/settings/password/changepassword" element={<ChangePwd />} />
      <Route path="/settings/email/changeemail" element={<ChnageEmail />} />
      <Route
        path="/settings/password/successpasswordchange"
        element={<SuccessPwdChange />}
      />
       
        </Route>
    </Routes>
 
  </BrowserRouter>


// {/* <BrowserRouter>
// <Routes>
// <Route path='/' element={<LogIn/>}/>
// <Route path="/register" element={<Register />} />
// {<Route path='/Home' element={<Home/>}/>}
// </Routes>
// </BrowserRouter> */}
);
      }
      export default View;