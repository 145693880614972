import react, { FC, useState } from "react";

const Toggle: FC = () => {
    const [isToggled, setIsToggled] = useState(true);
  return (
    <div className="flex items-center justify-center w-full ">
      <div className="flex items-center hover:cursor-pointer" onClick={() => setIsToggled(!isToggled)}>
        {isToggled == true ? (
            <div className="relative">
                <div className="w-10 h-4 rounded-full z-0" style={{background: "rgb(27, 107, 80)"}}></div>
                {/* <div className="w-6 h-6 rounded-full bg-green-500 border border-red absolute z-10"></div> */}
                <div className="absolute rounded-full bg-[#2EBD85] z-10 shadow-[0_1px_3px_0px_rgba(0, 0, 0, 0.16)] shadow-[0_1px_3px_0px_rgba(0, 0, 0, 0.09)] shadow-[0_1px_1px_0px_rgba(0, 0, 0, 0.11)]" style={{width: "20px", height: "20px", top: "-1.75px", right: "0"}} ></div>
            </div>
            
        ) : (
            <div className="relative">
                <div className="w-10 h-4 rounded-full bg-[#979797]"></div>
                <div className="absolute rounded-full z-10 shadow-[0_1px_3px_0px_rgba(0, 0, 0, 0.16)] shadow-[0_1px_3px_0px_rgba(0, 0, 0, 0.09)] shadow-[0_1px_1px_0px_rgba(0, 0, 0, 0.11)] " style={{width: "20px", height: "20px", top: "-1.75px", left: "0", background: "rgb(200, 200, 200)"}} ></div>
            </div>
        )}
        
      </div>
    </div>
  );
};

export default Toggle;