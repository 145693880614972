import { FC, useContext } from "react";

import Logo from "assets/logo.svg";
import confirmIcon from "assets/confirm_ico.svg";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "contexts/authContext";

const SuccessClose: FC = () => {
  const authContext = useContext(AuthContext);
  const comletedAccountClose = () => {
    authContext.signOut();
    navigate("/");
  };
  const navigate = useNavigate();
  return (
    <div className="bg-[#23341B] w-full h-screen flex justify-center">
      <div className="w-10/12 lg:w-9/12 p-7">
        <div>
          <img src={Logo} />
        </div>
        <div className="flex flex-col text-center pt-12">
          <div className="flex justify-center flex text-center">
            <img src={confirmIcon} />
          </div>
          <div className="text-white font-bold text-[50px] leading-none">
            <div>Your account is</div>
            <div>now closed</div>
          </div>
          <div className="pt-2 text-sm text-[#A8CFBD]">
            <div>
              If you‘d like to reopen your account in the future you‘d need to
              contact us.{" "}
            </div>
          </div>
          <div className="pt-6">
            <button
              className="bg-[#2EBD85] hover:bg-[#71f1bf] w-[80%] md:w-[50%] lg:w-[40%] text-black py-2 rounded-full text-black font-medium"
              onClick={comletedAccountClose}
            >
              Done
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessClose;
