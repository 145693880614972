import { AttrInfo } from "contexts/authContext";
export const AttrToFind = (attrs: [AttrInfo], attrName: string) => {
  if (attrs.length > 0) {
    const attrToFind: AttrInfo | undefined = attrs.find(
      (attr) => attr.Name === attrName
    );
    return attrToFind?.Value;
  } else {
    return;
  }
};
