import React, { FC } from "react";
import { useNavigate } from "react-router-dom";

import AuthHeader from "components/AuthHeader";

import confLogo from "assets/conf_ico.svg";
import backLogo from "assets/back_ico.svg";

const ConfirmClose: FC = () => {
  const navigate = useNavigate();

  const clickedBack = () => {
    navigate("/settings/account/beforeyougo");
  };

  const clickedKeepOpen = () => {
    navigate("/settings");
  };

  const clickedContinue = () => {
    navigate("/settings/account/realconfirmclose");
  };
  return (
    <div>
      <AuthHeader mode={2} />
      <div
        onClick={clickedBack}
        className="w-1/6 absolute left-[35px] lg:left-[57px] top-[75px] lg:top-[160px] flex hover:cursor-pointer"
      >
        <img className="mr-2" src={backLogo} /> Back
      </div>
      <div className="w-full pt-10 flex justify-center">
        <div className="w-[360px] md:w-[45%] lg:w-[30%] items-center justify-center">
          <div className="items-center text-center flex justify-center">
            <img src={confLogo} className="justify-center text-center" />
          </div>
          <div className="py-7">
            <div className="text-2xl text-center font-bold">
              You won‘t be able to open a new account
            </div>
            <div className="text-sm text-[#979797] py-2">
              For security, we let each customer have only one account, you‘ll
              need to reactivate your account to use us in the future.
            </div>
          </div>
          <div
            onClick={clickedKeepOpen}
            className="text-center text-black w-full rounded-full bg-[#2EBD85] hover:bg-[#71f1bf] p-2 text-sm mb-6 hover:cursor-pointer font-medium"
          >
            Keep account open
          </div>
          <div
            onClick={clickedContinue}
            className="text-center text-white border border-white w-full rounded-full p-2 text-sm hover:cursor-pointer hover:bg-[#0B1620] font-medium"
          >
            Continue with account closure
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmClose;
