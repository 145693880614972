import React, { FC } from "react";
import { useNavigate } from "react-router-dom";

import AuthHeader from "components/AuthHeader";
import toolLogo from "assets/tool_ico.svg";
import backLogo from "assets/back_ico.svg";

const BeforeYouGo: FC = () => {
  const navigate = useNavigate();

  const clickedBack = () => {
    navigate("/settings/account/closeaccount");
  };

  const clickedContinue = () => {
    navigate("/settings/account/confirmclose");
  };
  return (
    <div>
      <AuthHeader mode={2} />
      <div
        onClick={clickedBack}
        className="w-1/6 absolute left-[35px] lg:left-[57px] top-[75px] lg:top-[160px] flex hover:cursor-pointer"
      >
        <img className="mr-2" src={backLogo} /> Back
      </div>
      <div className="w-full pt-10 flex justify-center">
        <div className="w-[360px] md:w-[45%] lg:w-[30%] items-center justify-center">
          <div className="items-center text-center flex justify-center">
            <img src={toolLogo} className="justify-center text-center" />
          </div>
          <div className="py-7">
            <div className="text-2xl text-center font-bold">Before you go</div>
            <div className="text-sm text-[#979797] py-2">
              If you‘re having issues with your account, or want to update your
              account information, you can edit your account details (chat with
              a support agent).<br></br> <br></br> If you still want to leave,
              we need to explain what‘s going to happen after your delete your
              account.
            </div>
          </div>
          <div className="text-center text-black w-full rounded-full bg-[#2EBD85] hover:bg-[#71f1bf] p-2 text-sm mb-6 hover:cursor-pointer font-medium">
            Chat with an agent / Edit your account details
          </div>
          <div
            onClick={clickedContinue}
            className="text-center text-white border border-white w-full rounded-full p-2 text-sm hover:cursor-pointer hover:bg-[#0B1620] font-medium"
          >
            Continue with account closure
          </div>
        </div>
      </div>
    </div>
  );
};

export default BeforeYouGo;
